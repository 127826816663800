<template>
<!-- <div class="shell"> -->
 <div class="sensingDetails">
  <div class="triangle"></div>
      <div class="sensingTitle">
        <div class="sensingText">
          <div class="block1"></div>
           <div class="text1">{{sensorScreenName.label}}</div>
        </div>
        <!-- 取消弹出框 -->
        <i class="el-icon-circle-close" @click="closeClick"></i>
      </div>
      <div class="line"></div>
      <div class="sensingContent" >
        <!-- {{sensorScreenData}} -->
        <div class="list" v-for="(item,index) in sensorScreenData.data" :key="index">
          <div class="listLeft">
            <img :src="item.img" alt="">
          </div>
           <div class="listRight">
            <p>
              {{item.pr_value}}
              <span>{{item.pr_unit}}</span>
            </p>
            <p>{{item.pr_name}}</p>
           </div>
        </div>      
      </div>
    </div>
<!-- </div> -->
 
</template>

<script>
export default {
  props:['sensorScreenData','sensorScreenName'],
  data () {
    return {

    }
  },
  methods: {
    closeClick(){
      this.$emit('popUpNum',0)
    }
  },
}
</script>

<style scoped lang="less">

  .sensingDetails{
        position: absolute;
        top: 0;
        left: 60px;
        z-index: 999;
        width: 400px !important;
        height: 300px !important;
        // border: 1px solid red !important;
        background: rgb(12, 11, 9);
        padding: 10px;
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         .triangle{
          width: 0px;
          height: 0px;
          // background: #1effff;
          position: absolute;
          top: 0;
          left: 0;
          border-top: 5px solid #1effff;
          border-left: 5px solid #1effff;
          border-right: 5px solid rgba(255, 0, 0, 0);
          border-bottom: 5px solid rgba(255, 0, 0, 0);
         }
        .sensingTitle{
          height: 25px;
          width: 100%;
          // border: 1px solid red;
          display: flex;
          align-items: center;
          .sensingText{
            height: 100%;
            // border: 1px solid blue;
            display: flex;
            .block1{
            width: 1px;
            height: 100%;
            background: #1effff;
           }
            .text1{
              height: 100%;
              color: #fff;
              font-size: 15px;
              display: flex;
              align-items: center;
              margin-left: 10px;
            }
          }
          i{
            // border: 1px solid red;
            position: absolute;
            right: 0;
            color: rgba(255, 255, 255, 0.62);
            font-size: 22px;
            user-select: none;
            cursor: pointer;
            padding: 0 10px ;
          }
          i:hover{
             color: rgba(255, 255, 255, 0.8);
          }
        }
         .line{
            height: 2.5px;
            width: 100%;
            background: url("../../public/images/line11.png") no-repeat;
            background-size: 100%;
            margin: 10px 0;
          }
          .sensingContent{
            flex: 1;
            width: 100%;
            // border: 1px solid saddlebrown;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            overflow: auto;
            .list{
              // flex: 1;
              width: 33.3%;
              // border: 1px solid red;
              height: 80px;
              display: flex;
              user-select: none;
              cursor: pointer;
              .listLeft{
                // border: 1px solid red;
                height: 100%;
                display: flex;
                align-items: center;
                img{
                  // display:inline-block;
                  width: 50px;
                  height: 50px;
                }
              }
              .listRight{
                // border: 1px solid red;
                flex: 1;
                height: 100%;
                display: flex;
                flex-direction: column; 
                justify-content: center;
                p{
                  margin: 2px 0;
                  text-align: center;
                }
                p:nth-child(1){
                  font-size: 20px;
                  font-weight: 650;
                  color: #1effff;
                  span{
                    font-size: 12px;
                  }
                }
                 p:nth-child(2){
                  color: rgb(146, 220, 255);
                }
              }
            }
            .list:hover{
              background: rgba(255, 255, 255, 0.2);
              border-radius: 8px;
            }
          }
      }
      .sensingContent::-webkit-scrollbar{      
       display:none    
       }
</style>

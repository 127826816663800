<template>
  <div class="traceabilityShell">
    <!-- 左侧 -->
    <div class="left">
      <!-- 左上 星球-->
      <div class="upleft starBox">
        <!-- 圆球 -->
        <div class="roundShell">
          <div class="totalData">
            <p>{{ counter }}<span>个</span></p>
            <p>赋 码 总 量</p>
          </div>
          <div class="rotaryWindmill">
            <div class="min-round">
              <p style="color: #ffc60e">
                {{ roundData.total_product }}<span> 个</span>
              </p>
              <p>应用品种总数</p>
            </div>
            <div class="min-round">
              <p>{{ roundData.total_area }}<span> 亩</span></p>
              <p>追溯品种面积</p>
            </div>
            <div class="min-round">
              <p>{{ roundData.total_base }}<span> 家</span></p>
              <p>应用主体总数</p>
            </div>
          </div>
        </div>
        <!--查询列表 -->
        <div class="queryList">
          <div class="searchBox">
            <i class="el-icon-search"></i>
            <input
              type="text"
              placeholder="请输入品种查询内容"
              v-model="inputValue"
            />
          </div>
          <div class="searchContent">
            <div class="searchTitle">
              <span>企业名称</span>
              <span>品种</span>
              <span>地块</span>
              <span>是否赋码</span>
            </div>
            <div class="searchView">
              <div
                class="list"
                v-for="(item, index) in companyData"
                :key="index"
                :class="{ active: index % 2 == 0 }"
              >
                <span>{{ item.name }}</span>
                <span style="color: #00e4ff">{{ item.type }}</span>
                <span>{{ item.attributePlanting }}</span>
                <span style="color: #00e4ff">{{ item.assign }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 左下 溯源品种分析-->
      <div class="leftLower analysisVarieties">
        <div class="leftFrame frame">
          <img src="../../public/images/kuangLeft.png" alt="" />
        </div>
        <div class="middleFrame frame">
          <div class="title">
            <div class="textShell">
              <div class="blockBox">
                <div class="yellowBlock"></div>
              </div>
              <div class="text">溯源品种分析</div>
            </div>
            <img src="../../public/images/kuang1.png" alt="" />
          </div>
          <div class="content">
            <vue-seamless-scroll :data="analyseData" :class-option="classOption" class="warp">
              <div
                class="analyseData"
                v-for="(item, index) in analyseData"
                :key="index"
              >
                <div class="dateBox">
                  <div class="triangle"></div>
                  <p>{{ item.time }}</p>
                </div>
                <div class="smallArrow"></div>
                <div class="dataContent">
                  <div class="rightArrows"></div>
                  <div class="dataText" v-html="item.content"></div>
                </div>
              </div>
              <div
                class="analyseData"
                v-for="item in analyseData"
                :key="item.time"
              >
                <div class="dateBox">
                  <div class="triangle"></div>
                  <p>{{ item.time }}</p>
                </div>
                <div class="smallArrow"></div>
                <div class="dataContent">
                  <div class="rightArrows"></div>
                  <div class="dataText" v-html="item.content"></div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
        <div class="rightFrame frame">
          <img src="../../public/images/kuangRight.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="right">
      <!-- 右上 溯源品种面积-->
      <div class="upright areaVariety">
        <div class="areaVarietyTitle">
          <div class="textShell">
            <div class="blockBox">
              <div class="yellowBlock"></div>
            </div>
            <div class="text">溯源品种面积</div>
          </div>
          <img src="../../public/images/kuang2.png" alt="" />
        </div>
        <div class="areaVarietyContent">
          <!-- 总面积 -->
          <div class="totalArea">
            <p>
              总面积 <span>{{ roundData.total_area }}</span
              ><span> 亩</span>
            </p>
          </div>
          <!-- 立体饼状图 -->
          <div class="pieChart">
            <div class="pie">
              <div class="cityGreenLand-charts" id="cityGreenLand-charts"></div>
            </div>

            <div class="tuopan"></div>
          </div>
          <!-- 比率图 -->
          <div class="ratioChart">
            <div
              class="ratioVarieties"
              v-for="(item, index) in areaData"
              :key="index"
            >
              <div class="name">{{ item.name }}</div>
              <div class="varieties">
                <div class="linebox" :style="{ width: item.rate }">
                  <span>{{ item.rate }}</span>
                </div>
              </div>
              <div class="quantity">{{ item.field }}亩</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右下 统计分析-->
      <div class="rightLower statisticAnalysis">
        <div class="statisticAnalysisTitle">
          <div class="textShell">
            <div class="blockBox">
              <div class="yellowBlock"></div>
            </div>
            <div class="text">统计分析</div>
          </div>
          <img src="../../public/images/kuang2.png" alt="" />
        </div>
        <div class="statisticAnalysisContent">
          <div
            ref="chart1"
            id="mychart1"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  traceabilityList,
  traceabilityData,
  traceabilityViews
} from "../api/api";
import "echarts-gl";
export default {
  data() {
    return {
      inputValue: "", //查询列表的value
      counter: 0, //计数器  赋码总量
      roundData: {}, //圆球数据
      companyData: [], //公司数据
      analyseData: [], //溯源品种分析数据
      areaData: [], //溯源品种面积数据
      optionData: [
        {
          name: "西兰花",
          value: 1580,
          itemStyle: {
            color: "#e55555"
          }
        },
        {
          name: "西瓜",
          value: 650,
          itemStyle: {
            color: "#85b727"
          }
        },
        {
          name: "毛豆",
          value: 426,
          itemStyle: {
            color: "#edaa03"
          }
        },
        {
          name: "黄桃",
          value: 390,
          itemStyle: {
            color: "#ab4cd3"
          }
        },
        {
          name: "西兰花",
          value: 285,
          itemStyle: {
            color: "#02a4e2"
          }
        }
      ], //溯源品种面积数据
      classOption: {
        limitMoveNum: 6,
        direction: 2,
        step:1.2
      }
    };
  },
  watch: {
    inputValue(newVal, oldVal) {
      console.log(newVal, oldVal, "newVal,oldVal");
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        //防抖
        this.listData(newVal);
      }, 1000);
    }
  },
  created() {},
  mounted() {
    this.pageData(); //溯源页面数据
    this.listData(); //溯源列表数据
    this.varietyAnalysis(); //溯源品种分析
  },
  methods: {
    //溯源品种分析
    varietyAnalysis() {
      traceabilityViews().then((res) => {
        console.log(res.data.list, 88989);
        let arr = [];
        res.data.list.forEach((item) => {
          item.base_name = item.base_name ? item.base_name : "盐城农业产业园"; //这边担心有的时候没有数据 写了一个默认的
          let obj = {
            time: item.create_time,
            content:
              "<span>" +
              item.base_name +
              "</span>的<span>" +
              item.product +
              "</span>被用户在<span>" +
              item.area +
              "</span>扫码"
          };
          arr.push(obj);
        });
        this.analyseData = arr;
        console.log(this.analyseData,'console.log(this.analyseData);');
      });
    },

    //溯源页面数据
    pageData() {
      let obj = {
        apipost_id: "302b05"
      };
      traceabilityData(obj).then((res) => {
        /////下面是溯源品种面积数据整理👇////////////////////////////////////
        this.roundData = res.data;
        let pieOldData = res.data.product_area; //接口数据
        let pieNewData = []; //农村农产品  数组对象
        for (let key in pieOldData) {
          //遍历下拿到的各种农场品  种植亩数/名称/..  的对象
          let obj = {
            name: "",
            rate: "",
            field: 0,
            ratioNum: 0,
            batch: "",
            views: 0,
            value: 0
          };
          obj.name = pieOldData[key].product_name;
          let num = String(
            (pieOldData[key].area / this.roundData.total_area) * 100
          );
          obj.rate = num.slice(0, num.indexOf(".") + 3) + "%";
          obj.ratioNum = num.slice(0, num.indexOf(".") + 3);
          obj.field = Number(pieOldData[key].area);
          obj.batch = pieOldData[key].batch;
          obj.views = pieOldData[key].views;
          obj.value = parseFloat(num.slice(0, num.indexOf(".") + 3));

          pieNewData.push(obj); //农村农产品push 组成一个数组对象
        }

        let pieNewData1 = pieNewData.sort((a, b) => {
          //处理初始数组对象  按照亩数从大到小排序
          return b.ratioNum - a.ratioNum;
        });

        if (pieNewData1.length > 4) {
          //这边判断下数组长度是否超过4  前四正常显示亩数占比 四个以后统一为其他
          let num4 = 0;
          let number4 = 0;
          let arr = pieNewData1.slice(0, 4); //截取前四个对象 组合为名字为arr的数组
          arr.forEach((item) => {
            //遍历 (我们需要的得到前四的总亩数/总% 下面👇的减)
            num4 = num4 + Number(item.ratioNum); //总亩数
            number4 = number4 + Number(item.field); //总%
          });

          let elseNum = String(100 - num4);
          let elseNum1 = elseNum.slice(0, elseNum.indexOf(".") + 3);

          let obj = {
            //这边组成 其他 对象
            name: "其他",
            rate: elseNum1 + "%",
            field: this.roundData.total_area - number4,
            ratioNum: elseNum1
          };

          arr.push(obj); //将其他  这个对象push到arr中
          this.areaData = arr; //赋值到  溯源品种面积数据
          this.optionData.forEach((item, index) => {
            item.name = this.areaData[index].name;
            item.value = this.areaData[index].field;
          });
          this.init(); //3D饼状图
        } else {
          this.optionData = pieNewData1;
          this.init();
        }

        /////下面是统计分析数据整理👇////////////////////////////////////
        let barOldData = pieNewData; //接口数据 (pieNewData借用的是3D饼图处理好的数组对象)
        let barName = []; //统计分析->产品名
        let barBatch = []; //统计分析->溯源码量
        let barViews = []; //统计分析->扫描次数
        barOldData.forEach((item) => {
          console.log(item, 9999);
          barName.push(item.name);
          barBatch.push(item.batch);
          if (item.views == 0) {
            barViews.push(1);
          } else {
            barViews.push(item.views);
          }
        });
        this.barGraph(barName, barBatch, barViews); //统计分析->柱形图
      });
    },
    //溯源列表数据
    listData(data) {
      let enterpriseArr = [];
      let obj = {
        id: 2,
        key: data
      };
      traceabilityList(obj).then((res) => {
        let listArr = res.data.list.data;
        let counterNum = 0; //计数器  赋码总量
        let arr = [];
        listArr.forEach((item) => {
          let listObj = {
            name: "",
            type: "",
            attributePlanting: "",
            assign: ""
          };
          listObj.name = item.partycheck.plantDetail.base.base_name;
          listObj.type = item.partycheck.plantDetail.product.product_name;
          listObj.attributePlanting =
            item.partycheck.plantDetail.mass.mass_name;
          listObj.assign = item.qrcode !== "" ? "已赋码" : "未赋码";
          if (item.qrcode !== "") {
            counterNum++;
          }
          arr.push(listObj);
        });
        this.companyData = arr;
        this.counter = counterNum;
      });
    },
    //3D饼状图
    init() {
      //构建3d饼状图
      let myChart = echarts.init(
        document.getElementById("cityGreenLand-charts")
      );
      // 传入数据生成 option
      this.option = this.getPie3D(this.optionData, 0.8);

      myChart.setOption(this.option);
      //是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
      this.option.series.push({
        name: "pie2d",
        type: "pie",
        label: {
          normal: {
            show: false
          }
        },
        labelLine: {
          show: false,
          length: 10,
          length2: 10
        },

        startAngle: -20, //起始角度，支持范围[0, 360]。
        clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ["50%", "50%"],
        center: ["50%", "50%"],
        data: this.optionData,
        itemStyle: {
          opacity: 0
        }
      });
      myChart.setOption(this.option);
      // console.log(myChart,"myChart");
      this.bindListen(myChart);
    },

    getPie3D(pieData, internalDiameterRatio) {
      //internalDiameterRatio:透明的空心占比
      let that = this;
      let series = [];
      let sumValue = 0;
      let startValue = 0;
      let endValue = 0;
      let legendData = [];
      let legendBfb = [];
      let k = 1 - internalDiameterRatio;
      pieData.sort((a, b) => {
        return b.value - a.value;
      });

      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value;
        let seriesItem = {
          name:
            typeof pieData[i].name === "undefined"
              ? `series${i}`
              : pieData[i].name,
          type: "surface",
          parametric: true,
          wireframe: {
            show: false
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k: k
          },
          center: ["10%", "50%"]
        };

        if (typeof pieData[i].itemStyle != "undefined") {
          let itemStyle = {};
          typeof pieData[i].itemStyle.color != "undefined"
            ? (itemStyle.color = pieData[i].itemStyle.color)
            : null;
          typeof pieData[i].itemStyle.opacity != "undefined"
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null;
          seriesItem.itemStyle = itemStyle;
        }
        series.push(seriesItem);
      }

      // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
      // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
      legendData = [];
      legendBfb = [];
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value;

        series[i].pieData.startRatio = startValue / sumValue;
        series[i].pieData.endRatio = endValue / sumValue;
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          false,
          false,
          k,
          series[i].pieData.value
        );
        startValue = endValue;
        let bfb = that.fomatFloat(series[i].pieData.value / sumValue, 4);
        legendData.push({
          name: series[i].name,
          value: bfb
        });
        legendBfb.push({
          name: series[i].name,
          value: bfb
        });
      }
      let boxHeight = this.getHeight3D(series, 26); //通过传参设定3d饼/环的高度，26代表26px
      // 准备待返回的配置项，把准备好的 legendData、series 传入。
      let option = {
        legend: {
          data: legendData,
          orient: "horizontal",
          left: 10,
          top: 10,
          itemGap: 10,
          textStyle: {
            color: "#A1E2FF"
          },
          show: false,
          icon: "circle",
          formatter: function (param) {
            let item = legendBfb.filter((item) => item.name == param)[0];
            let bfs = that.fomatFloat(item.value * 100, 2) + "%";
            return `${item.name}  ${bfs}`;
          }
        },
        labelLine: {
          show: true,
          lineStyle: {
            color: "#7BC0CB"
          }
        },
        label: {
          show: true,
          position: "outside",
          rich: {
            b: {
              color: "#7BC0CB",
              fontSize: 12,
              lineHeight: 20
            },
            c: {
              fontSize: 16
            }
          },
          formatter: "{b|{b} \n}{c|{c}}{b|  亩}"
        },
        tooltip: {
          formatter: (params) => {
            if (
              params.seriesName !== "mouseoutSeries" &&
              params.seriesName !== "pie2d"
            ) {
              let bfb = (
                (option.series[params.seriesIndex].pieData.endRatio -
                  option.series[params.seriesIndex].pieData.startRatio) *
                100
              ).toFixed(2);
              return (
                `${params.seriesName}<br/>` +
                `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>` +
                `${bfb}%`
              );
            }
          }
        },
        xAxis3D: {
          min: -1,
          max: 1
        },
        yAxis3D: {
          min: -1,
          max: 1
        },
        zAxis3D: {
          min: -1,
          max: 1
        },
        grid3D: {
          show: false,
          boxHeight: boxHeight, //圆环的高度
          viewControl: {
            //3d效果可以放大、旋转等，请自己去查看官方配置
            alpha: 30, //角度
            distance: 130, //调整视角到主体的距离，类似调整zoom
            rotateSensitivity: 0, //设置为0无法旋转
            zoomSensitivity: 0, //设置为0无法缩放
            panSensitivity: 0, //设置为0无法平移
            autoRotate: false //自动旋转
          }
        },
        series: series
      };
      return option;
    },

    //获取3d丙图的最高扇区的高度
    getHeight3D(series, height) {
      series.sort((a, b) => {
        return b.pieData.value - a.pieData.value;
      });
      return (height * 25) / series[0].pieData.value;
    },

    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
      // 计算
      let midRatio = (startRatio + endRatio) / 2;
      let startRadian = startRatio * Math.PI * 2;
      let endRadian = endRatio * Math.PI * 2;
      let midRadian = midRatio * Math.PI * 2;
      // 如果只有一个扇形，则不实现选中效果。
      if (startRatio === 0 && endRatio === 1) {
        isSelected = false;
      }
      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      k = typeof k !== "undefined" ? k : 1 / 3;
      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
      let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      let hoverRate = isHovered ? 1.05 : 1;
      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32
        },
        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20
        },
        x: function (u, v) {
          if (u < startRadian) {
            return (
              offsetX +
              Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        y: function (u, v) {
          if (u < startRadian) {
            return (
              offsetY +
              Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        z: function (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u);
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u) * h * 0.1;
          }
          return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
        }
      };
    },

    fomatFloat(num, n) {
      var f = parseFloat(num);
      if (isNaN(f)) {
        return false;
      }
      f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); // n 幂
      var s = f.toString();
      var rs = s.indexOf(".");
      //判定如果是整数，增加小数点再补0
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + n) {
        s += "0";
      }
      return s;
    },

    bindListen(myChart) {
      // 监听鼠标事件，实现饼图选中效果（单选），近似实现高亮（放大）效果。
      let that = this;
      let selectedIndex = "";
      let hoveredIndex = "";
      // 监听点击事件，实现选中效果（单选）
      myChart.on("click", function (params) {
        // 从 option.series 中读取重新渲染扇形所需的参数，将是否选中取反。
        let isSelected =
          !that.option.series[params.seriesIndex].pieStatus.selected;
        let isHovered =
          that.option.series[params.seriesIndex].pieStatus.hovered;
        let k = that.option.series[params.seriesIndex].pieStatus.k;
        let startRatio =
          that.option.series[params.seriesIndex].pieData.startRatio;
        let endRatio = that.option.series[params.seriesIndex].pieData.endRatio;
        // 如果之前选中过其他扇形，将其取消选中（对 option 更新）
        if (selectedIndex !== "" && selectedIndex !== params.seriesIndex) {
          that.option.series[selectedIndex].parametricEquation =
            that.getParametricEquation(
              that.option.series[selectedIndex].pieData.startRatio,
              that.option.series[selectedIndex].pieData.endRatio,
              false,
              false,
              k,
              that.option.series[selectedIndex].pieData.value
            );
          that.option.series[selectedIndex].pieStatus.selected = false;
        }
        // 对当前点击的扇形，执行选中/取消选中操作（对 option 更新）
        that.option.series[params.seriesIndex].parametricEquation =
          that.getParametricEquation(
            startRatio,
            endRatio,
            isSelected,
            isHovered,
            k,
            that.option.series[params.seriesIndex].pieData.value
          );
        that.option.series[params.seriesIndex].pieStatus.selected = isSelected;
        // 如果本次是选中操作，记录上次选中的扇形对应的系列号 seriesIndex
        isSelected ? (selectedIndex = params.seriesIndex) : null;
        // 使用更新后的 option，渲染图表
        myChart.setOption(that.option);
      });
      // 监听 mouseover，近似实现高亮（放大）效果
      myChart.on("mouseover", function (params) {
        // 准备重新渲染扇形所需的参数
        let isSelected;
        let isHovered;
        let startRatio;
        let endRatio;
        let k;
        // 如果触发 mouseover 的扇形当前已高亮，则不做操作
        if (hoveredIndex === params.seriesIndex) {
          return;
          // 否则进行高亮及必要的取消高亮操作
        } else {
          // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
          if (hoveredIndex !== "") {
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
            isSelected = that.option.series[hoveredIndex].pieStatus.selected;
            isHovered = false;
            startRatio = that.option.series[hoveredIndex].pieData.startRatio;
            endRatio = that.option.series[hoveredIndex].pieData.endRatio;
            k = that.option.series[hoveredIndex].pieStatus.k;
            // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
            that.option.series[hoveredIndex].parametricEquation =
              that.getParametricEquation(
                startRatio,
                endRatio,
                isSelected,
                isHovered,
                k,
                that.option.series[hoveredIndex].pieData.value
              );
            that.option.series[hoveredIndex].pieStatus.hovered = isHovered;
            // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
            hoveredIndex = "";
          }
          // 如果触发 mouseover 的扇形不是透明圆环，将其高亮（对 option 更新）
          if (
            params.seriesName !== "mouseoutSeries" &&
            params.seriesName !== "pie2d"
          ) {
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
            isSelected =
              that.option.series[params.seriesIndex].pieStatus.selected;
            isHovered = true;
            startRatio =
              that.option.series[params.seriesIndex].pieData.startRatio;
            endRatio = that.option.series[params.seriesIndex].pieData.endRatio;
            k = that.option.series[params.seriesIndex].pieStatus.k;
            // 对当前点击的扇形，执行高亮操作（对 option 更新）
            that.option.series[params.seriesIndex].parametricEquation =
              that.getParametricEquation(
                startRatio,
                endRatio,
                isSelected,
                isHovered,
                k,
                that.option.series[params.seriesIndex].pieData.value + 5
              );
            that.option.series[params.seriesIndex].pieStatus.hovered =
              isHovered;
            // 记录上次高亮的扇形对应的系列号 seriesIndex
            hoveredIndex = params.seriesIndex;
          }
          // 使用更新后的 option，渲染图表
          myChart.setOption(that.option);
        }
      });
      // 修正取消高亮失败的 bug
      myChart.on("globalout", function () {
        // 准备重新渲染扇形所需的参数
        let isSelected;
        let isHovered;
        let startRatio;
        let endRatio;
        let k;
        if (hoveredIndex !== "") {
          // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
          isSelected = that.option.series[hoveredIndex].pieStatus.selected;
          isHovered = false;
          k = that.option.series[hoveredIndex].pieStatus.k;
          startRatio = that.option.series[hoveredIndex].pieData.startRatio;
          endRatio = that.option.series[hoveredIndex].pieData.endRatio;
          // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
          that.option.series[hoveredIndex].parametricEquation =
            that.getParametricEquation(
              startRatio,
              endRatio,
              isSelected,
              isHovered,
              k,
              that.option.series[hoveredIndex].pieData.value
            );
          that.option.series[hoveredIndex].pieStatus.hovered = isHovered;
          // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
          hoveredIndex = "";
        }
        // 使用更新后的 option，渲染图表
        myChart.setOption(that.option);
      });
    },
    //统计分析->柱形图
    barGraph(barName, barBatch, barViews) {
      console.log(
        "🚀 ~ file: traceability.vue:730 ~ barGraph ~ barName, barBatch, barViews",
        barName,
        barBatch,
        barViews
      );

      let myChart1 = echarts.init(document.getElementById("mychart1"));
      let option1 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          top: "0%",
          data: ["溯源码量", "扫描次数"],
          right: 0,
          textStyle: {
            fontSize: 12,
            color: "#fff"
          },
          itemWidth: 15,
          itemHeight: 10
        },
        //改变表格的宽高
        grid: {
          x: 41,
          y: 40,
          x2: 0,
          y2: 18,
          borderWidth: 10
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: barName,
            axisLabel: {
              //修改字体颜色
              color: "#f4f4f6"
            },
            splitLine: {
              //修改线的颜色
              lineStyle: {
                type: "solid",
                color: "#ffffff10",
                width: "1"
              }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "溯源码量", //加上单位
            interval: 20,
            nameTextStyle: {
              //单位相关样式设置
              color: "#fff",
              padding: [0, 0, 10, -25]
            },
            axisLabel: {
              color: "#f4f4f6",
              formatter: "{value}" //自定义Y轴+单位value
            },
            splitLine: {
              lineStyle: {
                type: "solid",
                color: "#ffffff10",
                width: "1"
              }
            }
          }
          // {
          //   type: 'value',
          //   name: '扫描次数',
          //   // min: 0,
          //   // max: 25,
          //   interval: 5,
          //   axisLabel: {
          //     formatter: '{value} °C'
          //   }
          // }
        ],
        series: [
          {
            name: "溯源码量",
            type: "bar",
            barWidth: 15, //柱子宽度
            barGap: "20%", //柱子间隙
            // label: labelOption,
            emphasis: {
              focus: "series"
            },
            data: barBatch,
            itemStyle: {
              normal: {
                barBorderRadius: [15, 15, 0, 0], //圆角设置 左上 右上 右下 左下
                // label: { show: true },
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#fe624a"
                  },
                  {
                    offset: 1,
                    color: "#f3bc2c"
                  }
                ])
              }
            }
          },
          {
            name: "扫描次数",
            type: "bar",
            barWidth: 15, //柱子宽度
            barGap: "20%", //柱子间隙
            emphasis: {
              focus: "series"
            },
            data: barViews,
            itemStyle: {
              normal: {
                barBorderRadius: [15, 15, 0, 0], //圆角设置 左上 右上 右下 左下

                // label: { show: true },
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  //柱子渐变色背景
                  {
                    offset: 0,
                    color: "#2b82fe"
                  },
                  {
                    offset: 1,
                    color: "#08dbfd"
                  }
                ])
              }
            }
          }
        ]
      };
      myChart1.setOption(option1);
      window.addEventListener("resize", () => {
        myChart1.resize();
      });
    }
  }
};
</script>

<style scoped lang="less">
.traceabilityShell {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  background: url("../../public/images/bg4.jpg") no-repeat center center;
  background-size: auto 100%;
  // border: 1px solid red;
  // 左侧
  .left {
    width: 73%;
    height: 100%;
    // border: 1px solid red;
    //  左上 星球
    .starBox {
      width: 100%;
      height: 60%;
      // border: 1px solid yellow;
      display: flex;
      // 圆球
      .roundShell {
        // position: flex;
        // top: 20%;
        width: 50%;
        height: 110%;
        margin-top: -1%;
        // border: 1px solid red;

        background: url("../../public/images/xingqiu.png") no-repeat center
          center;
        background-size: 100% auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .totalData {
          position: absolute;

          // border: 1px solid red;
          margin-top: -1.5vw;
          p:nth-child(1) {
            color: #ffc60e;
            font-size: 3vw;
            font-weight: 550;
            span {
              font-size: 20px;
              font-weight: 500;
            }
          }
          p:nth-child(2) {
            color: #fff;
            font-weight: 550;
            font-size: 1.2vw;
          }
        }
        .rotaryWindmill {
          margin-top: -1.6vw;
          margin-left: 0.7vw;
          width: 21.1vw;
          height: 21.1vw;
          animation: Circle 20s linear infinite;
          // border:1px solid #fff;
          border-radius: 50%;
          position: relative;
          .min-round {
            width: 6.5vw;
            height: 6.5vw;
            // border: 1px solid red;
            animation: minCircle 20s linear infinite;
            position: absolute;
            background: url("../../public/images/min-xingqiu.png") no-repeat
              center center;
            background-size: 100% auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p:nth-child(1) {
              font-size: 1vw;
              font-weight: 550;
              color: #00eaff;
              line-height: 25px;
              span {
                font-size: 0.5vw;
                font-weight: 500;
              }
            }
            p:nth-child(2) {
              color: #fff;
            }
          }
          .min-round:nth-child(1) {
            top: 0;
            left: -1.5vw;
          }
          .min-round:nth-child(2) {
            top: 18vw;
            left: 5vw;
          }
          .min-round:nth-child(3) {
            top: 6vw;
            left: 18.5vw;
          }
        }
      }
      @keyframes Circle {
        /*父元素顺时针旋转*/
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @keyframes minCircle {
        /*子元素顺时针旋转*/
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(-360deg);
        }
      }
      // 查询列表
      .queryList {
        width: 50%;
        height: 100%;
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        padding: 0 1vw;
        .searchBox {
          width: 100%;
          height: 10%;
          // border: 1px solid red;
          margin-bottom: 10px;
          background: url("../../public/images/search4.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            color: #00e4ff;
            font-size: 20px;
            margin: 0 10px;
          }
          input {
            width: 90%;
            height: 80%;
            background: #eafffe00;
            border: 1px solid #eafffe00;
            color: #fff;
          }
          input::-webkit-input-placeholder {
            color: #fff;
          }
          input:-moz-placeholder {
            color: #fff;
          }
          input:-ms-input-placeholder {
            color: #fff;
          }
        }
        .searchContent {
          width: 100%;
          height: 90%;
          flex: 1;
          // border: 1px solid rgb(21, 255, 0);
          // display: flex;
          // flex-direction: column;
          .searchTitle {
            width: 100%;
            height: 2.5vw;
            // border: 1px solid red;
            background: url("../../public/images/title4.png") no-repeat;
            background-size: 100% 100%;
            span {
              // border: 1px solid red;
              display: inline-block;
              width: 20%;
              height: 100%;
              color: #fff;
              font-size: 14px;
              line-height: 2.5vw;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            span:nth-child(1) {
              width: 30%;
              height: 100%;
            }
            span:nth-child(3) {
              width: 30%;
              height: 100%;
            }
          }
          .searchView {
            border: 1px solid rgba(4, 66, 167, 0.4);
            height: 40vh;
            overflow: auto;
            .list {
              width: 100%;
              height: 50px;
              // border: 1px solid red;
              span {
                // border: 1px solid red;
                display: inline-block;
                width: 20%;
                height: 100%;
                color: #fff;
                // font-size: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 50px;
                // display: flex;
              }
              span:nth-child(1) {
                width: 30%;
                height: 100%;
              }
              span:nth-child(3) {
                width: 30%;
                height: 100%;
              }
            }
            .active {
              background: linear-gradient(to right, #022358, #0223584e);
            }
          }
          .searchView::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
    // 左下 溯源品种分析
    .analysisVarieties {
      width: 100%;
      height: 40%;
      // border: 1px solid yellow;
      display: flex;
      .frame {
        // border: 1px solid rgb(0, 162, 255);
      }
      .leftFrame {
        width: 5%;
        height: 100%;
        img {
          height: 100%;
        }
      }
      .middleFrame {
        width: 90%;
        height: 100%;
        .title {
          width: 100%;
          height: 15%;
          // border: 1px solid blueviolet;
          // padding: 10px 0;
          // display: flex;
          position: relative;
          .textShell {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            // border: 1px solid rgb(212, 255, 0);
            display: flex;
            // height: 100%;
            .blockBox {
              width: 20px;
              height: 20px;
              // background: red;
              border: 1px solid #005365;
              margin: 0 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              .yellowBlock {
                width: 8px;
                height: 8px;
                background: #ffc60e;
              }
            }
            .text {
              // color: #fff;
              font-size: 16px;
              font-weight: 550;
              color: transparent;
              background: linear-gradient(to bottom, #eafffe, #8ffdf9);
              background-clip: text;
              -o-background-clip: text;
              -ms-background-clip: text;
              -moz-background-clip: text;
              -webkit-background-clip: text;
              // margin-bottom: 5%;
            }
          }
          img {
            position: absolute;
            display: block;
            bottom: 0;
            // top: 50%;
            // margin-top: 1%;

            width: 100%;
          }
        }
        .content {
          width: 100%;
          height: 80%;
          // border: 1px solid blueviolet;
          overflow: hidden;
          white-space: nowrap;
          padding-top: 2vw;
          margin-bottom: 20px;
          .analyseData {
            // float: left;
            display: inline-block;
            height: 10vw;
            width: 12vw;
            // border: 1px solid red;
            margin-right: 5vw;
            .dateBox {
              width: 100%;
              height: 18%;
              background: #0c1d5d;
              border-left: 2px solid #00ffff;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                color: #fff;
              }
              .triangle {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                width: 0px;
                height: 0px;
                border-left: 5px solid #23b0fc;
                border-right: 5px solid rgba(232, 61, 61, 0);
                border-top: 5px solid rgba(235, 144, 144, 0);
                border-bottom: 5px solid rgba(253, 202, 202, 0);
              }
            }
            .smallArrow {
              width: 100%;
              height: 15%;
              // border: 1px solid red;
              background: url("../../public/images/jiantou4.png") no-repeat
                center center;
            }
            .dataContent {
              width: 100%;
              height: 67%;
              // border: 1px solid red;
              background: url("../../public/images/kuang3.png") no-repeat center
                center;
              background-size: 100% 100%;
              position: relative;
              .rightArrows {
                position: absolute;
                right: -3.5vw;
                top: 50%;
                transform: translateY(-50%);
                width: 2vw;
                height: 2vw;
                // border: 1px solid red;
                background: url("../../public/images/jiantou5.png") no-repeat
                  center center;
              }
              .dataText {
                white-space: normal;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 50%;
                height: 50%;
                // border: 1px solid red;
                color: #cde3ff;
                text-align: left;
                line-height: 20px;
                span {
                  color: #00f1fa;
                }
              }
            }
          }
        }
        /*滚动条样式*/
        .content::-webkit-scrollbar {
          height: 4px;
        }
        .content::-webkit-scrollbar-thumb {
          border-radius: 5px;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: #03e1dc;
        }
        .content::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 2px;
          background: #002969;
        }
      }
      .rightFrame {
        width: 5%;
        height: 100%;
        img {
          height: 100%;
        }
      }
    }
  }
  // 右侧
  .right {
    width: 25%;
    height: 100%;
    margin-left: 2%;
    // border: 1px solid red;

    // 右上 溯源品种面积
    .areaVariety {
      width: 100%;
      height: 60%;
      // border: 1px solid yellow;
      display: flex;
      flex-direction: column;
      .areaVarietyTitle {
        width: 100%;
        height: 40px;
        // border: 1px solid red;
        position: relative;
        .textShell {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          // border: 1px solid rgb(212, 255, 0);
          display: flex;
          // height: 100%;
          .blockBox {
            width: 20px;
            height: 20px;
            // background: red;
            border: 1px solid #005365;
            margin: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            .yellowBlock {
              width: 8px;
              height: 8px;
              background: #ffc60e;
            }
          }
          .text {
            // color: #fff;
            font-size: 16px;
            font-weight: 550;
            color: transparent;
            background: linear-gradient(to bottom, #eafffe, #8ffdf9);
            background-clip: text;
            -o-background-clip: text;
            -ms-background-clip: text;
            -moz-background-clip: text;
            -webkit-background-clip: text;
            // margin-bottom: 5%;
          }
        }
        img {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 100%;
          transform: translateX(-50%);
          display: block;
        }
      }
      .areaVarietyContent {
        flex: 1;
        // border:1px solid #eafffe;
        // 总面积
        .totalArea {
          width: 100%;
          height: 15%;
          // border: 1px solid red;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url("../../public/images/shanguang5.png") no-repeat center
            1px;

          p {
            color: #fff;
            span {
              color: #00e4ff;
            }
            span:nth-child(1) {
              font-size: 30px;
              font-weight: 550;
            }
          }
        }
        // 立体饼状图
        .pieChart {
          width: 100%;
          height: 40%;
          // border: 1px solid red;
          position: relative;

          .tuopan {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 12vw;
            height: 6vw;
            // border: 1px solid red;
            background: url("../../public/images/tupan5.png") no-repeat;
            background-size: 100%;
          }
          .pie {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 1vw;
            width: 15vw;
            height: 9vw;
            // border: 1px solid rgb(0, 255, 136);
            z-index: 999;

            .cityGreenLand-charts {
              // border: 1px solid rgb(0, 255, 136);
              height: 100%;
              width: 100%;
              div {
                width: 100%;
                // canvas{
                //     width: 100%;
                // height: 100%;
                // }
              }
            }
          }
        }
        // 比率图
        .ratioChart {
          width: 100%;
          height: 40%;
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .ratioVarieties {
            width: 100%;
            height: 1.8vw;
            // border: 1px solid red;
            display: flex;
            justify-content: center;
            align-items: center;

            .name {
              width: 12%;
              color: #fff;
              // border: 1px solid red;
            }
            .quantity {
              width: 12%;
              // border: 1px solid red;
            }
            .varieties {
              width: 70%;
              height: 0.8vw;
              // border: 1px solid red;
              background: #0f3270;
              border-radius: 0.5vw;
              overflow: hidden;
              position: relative;
              .linebox {
                width: 90%;
                height: 100%;
                background: red;
                border-radius: 0.5vw;
                span {
                  position: absolute;
                  right: 0.5vw;
                  color: rgba(255, 255, 255, 0.334);
                  line-height: 0.9vw;
                }
              }
            }
            .quantity {
              color: #01ccff;
            }
          }
          .ratioVarieties:nth-child(1) {
            .linebox {
              background: #e55555;
            }
          }
          .ratioVarieties:nth-child(2) {
            .linebox {
              background: #85b727;
            }
          }
          .ratioVarieties:nth-child(3) {
            .linebox {
              background: #edaa03;
            }
          }
          .ratioVarieties:nth-child(4) {
            .linebox {
              background: #ab4cd3;
            }
          }
          .ratioVarieties:nth-child(5) {
            .linebox {
              background: #02a4e2;
            }
          }
        }
      }
    }
    // 右下 统计分析
    .statisticAnalysis {
      width: 100%;
      height: 40%;
      // border: 1px solid yellow;
      display: flex;
      flex-direction: column;
      .statisticAnalysisTitle {
        width: 100%;
        height: 40px;
        // border: 1px solid red;
        position: relative;
        .textShell {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          // border: 1px solid rgb(212, 255, 0);
          display: flex;
          // height: 100%;
          .blockBox {
            width: 20px;
            height: 20px;
            // background: red;
            border: 1px solid #005365;
            margin: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            .yellowBlock {
              width: 8px;
              height: 8px;
              background: #ffc60e;
            }
          }
          .text {
            // color: #fff;
            font-size: 16px;
            font-weight: 550;
            color: transparent;
            background: linear-gradient(to bottom, #eafffe, #8ffdf9);
            background-clip: text;
            -o-background-clip: text;
            -ms-background-clip: text;
            -moz-background-clip: text;
            -webkit-background-clip: text;
            // margin-bottom: 5%;
          }
        }
        img {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 100%;
          transform: translateX(-50%);
          display: block;
        }
      }
      .statisticAnalysisContent {
        flex: 1;
        // border: 1px solid #fff;
        padding: 1vw;
      }
    }
  }
}
</style>

<template>
  <div class="guideShell">
    <div class="minTitle">
      <span v-text="timers.day"></span><span v-text="timers.time"></span><i class="el-icon-location-information" style="color:rgb(37,255,255);font-size:18px;"></i><span>{{weatherData.city}}</span><img v-if="weatherData.img" class="tqIcon" :src="weatherData.img" alt=""><span>{{weatherData.tem+'℃'}}</span><span>{{weatherData.wea}}</span>
    </div>
    <div class="title">
      <div class="guang"></div>
      <div class="guang guang1"></div>
    </div>
    <div class="guideContent">

      <div class="guideRouter">
        <div class="guideList" @click="handleClick('一张图')"></div>
        <div class="guideList" @click="handleClick('专家库')"></div>
        <div class="guideList" @click="handleClick('病虫害预防')"></div>
        <div class="guideList" @click="handleClick('溯源分析')"></div>
        <div class="guideList" @click="handleClick('电商平台')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { weather } from '../api/api'
export default {
  data () {
    return {
      weatherData: {
        tem_night: 0,
        tem_day: 0,
        city: '响水',
        tem: '0'
      },
      timers: {
      }
    }
  },
  created () {
    weather().then(res => {
      this.weatherData = res
      this.weatherData.img = require('../../public/images/cake/' + this.weatherData.wea_img + '.png')
      // this.weatherData.wea_img
    })
    let localTime = new Date()
    let year = localTime.getFullYear()
    let mon = localTime.getMonth() + 1
    let date = localTime.getDate()
    let hour = localTime.getHours()
    let min = localTime.getMinutes()
    let sec = localTime.getSeconds()
    this.timers.day = year + '年' + this.zero(mon) + '月' + this.zero(date) + '日'
    this.timers.time = this.zero(hour) + ':' + this.zero(min) + ':' + this.zero(sec)
  },
  methods: {
    zero (e) {
      if (e < 10) {
        return '0' + e
      } else {
        return e
      }
    },

    handleClick (data) {
      if (data) {

        if (data == '一张图') {
          this.$router.push("/oneMap")
        } else if (data == '专家库') {
          this.$router.push("/dataPlatform")
        } else if (data == '病虫害预防') {
          this.$router.push("/dataPlatform/kap")
        } else if (data == '溯源分析') {
          this.$router.push("/dataPlatform/traceability")
        } else if (data == '电商平台') {
          this.$router.push("/dataPlatform/ec")
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 1400px) {
  .title {
    margin-bottom: 4% !important;
  }
  .guideContent {
    width: 1100px !important;
    .guideList {
      width: 200px !important;
    }
  }
}
@media screen and (max-width: 1150px) {
  .guideContent {
    width: 1000px !important;
    .guideList {
      width: 180px !important;
    }
  }
}
@media screen and (max-width: 1050px) {
  .title {
    width: 950px !important;
  }
  .guideContent {
    width: 950px !important;
    height: 40% !important;
    .guideList {
      width: 160px !important;
    }
  }
}
@media screen and (max-width: 1000px) {
  .title {
    width: 900px !important;
  }
  .guideContent {
    width: 900px !important;
    height: 40% !important;
    .guideList {
      width: 160px !important;
    }
  }
}
.guideShell {
  // border: 1px solid red;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // border: 1px solid red;
  background: url('../../public/images/ydbj.jpg') no-repeat;
  background-size: 100% 100%;
  padding: 20px;
  .minTitle {
    // border: 1px solid red;
    color: #fff;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      margin: 0 5px;
      display: flex;
      line-height: 20px;
    }
    .dwIcon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('../../public/images/paiMinIcon1.png') no-repeat center
        center;
      // border: 1px solid red;
    }
    .tqIcon {
      display: inline-block;
      width: 20px;
      height: 20px;
      //  padding: 0 20px;
      margin: 0 5px;
    }
  }
  .title {
    // border: 1px solid red;
    width: 1200px;
    height: 120px;
    background: url('../../public/images/title2.png') no-repeat;
    background-size: 100%;
    // margin-top:7%;
    margin: 2% auto;
    position: relative;
    .guang {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      // border: 1px solid red;
      background: url('../../public/images/title2-2.png') no-repeat;
      background-size: 100%;
      animation-name: shanshuo;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
    .guang1 {
      animation-name: shanshuo;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
  }
  @keyframes shanshuo {
    // from表示动画的开始位置
    from {
      opacity: 0;
    }
    // to表示动画的结束位置
    to {
      opacity: 100%;
    }
  }
  .guideContent {
    width: 1350px;
    height: 68%;
    // border: 1px solid red;

    margin: auto;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .guideRouter {
      width: 100%;
      height: auto;
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      position: relative;
      .guideList {
        // border: 1px solid red;
        width: 240px;
        height: 345px;
        user-select: none;
        transition: all 0.4s linear;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .guideList:hover {
        // height: 400px;
        margin-bottom: 20px;
        // border: 1px solid red;
      }
      .guideList:nth-child(1) {
        background: url('../../public/images/pai1.png') no-repeat;
        background-size: 100%;
      }
      .guideList:nth-child(1):hover {
        top: -20px;
      }
      .guideList:nth-child(2) {
        left: 20.5%;
        right: 0;
        bottom: 0;
        background: url('../../public/images/pai2.png') no-repeat;
        background-size: 100%;
      }
      .guideList:nth-child(2):hover {
        top: -20px;
      }
      .guideList:nth-child(3) {
        left: 41%;
        background: url('../../public/images/pai3.png') no-repeat;
        background-size: 100%;
      }
      .guideList:nth-child(3):hover {
        top: -20px;
      }
      .guideList:nth-child(4) {
        left: 61.5%;
        background: url('../../public/images/pai4.png') no-repeat;
        background-size: 100%;
      }
      .guideList:nth-child(4):hover {
        top: -20px;
      }
      .guideList:nth-child(5) {
        left: 82%;
        background: url('../../public/images/pai5.png') no-repeat;
        background-size: 100%;
      }
      .guideList:nth-child(5):hover {
        top: -20px;
      }
    }
  }
}
</style>

<template>
  <div class="Camera" v-if="Camera" v-loading="videoLoad" element-loading-text="正在加载中" element-loading-background="rgba(0, 0, 0, 0.8)">
    <video
      :ref="`video${activeIndex}`"
      :id="`video${activeIndex}`"
      class="video-js vjs-default-skin loading"
      preload="auto"
      style="width: 100%; height: 100%; object-fit: fill"
      muted
    >
      <source :src="videoUrl" type="application/x-mpegURL" />
    </video>
  </div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
import "@videojs/http-streaming";
import "video.js/dist/video-js.min.css";
export default {
  props: {
    videoUrl: {
      require: true,
      type: String
    },
    activeIndex: {
      require: true
    }
  },
  data() {
    return {
      Camera: true, // 控制视频显示隐藏
      videoLoad: false
    };
  },
  watch: {
    videoUrl(val) {
      setTimeout(() => {
        this.player = videojs(
          `video${this.activeIndex}`,
          {
            bigPlayButton: false,
            controlBar: false,
            notSupportedMessage: "此视频暂无法播放，请稍后再试"
          },
          function () {
            this.play();
          }
        );

        // 当前要播放的视频地址
        // this.videoUrl = xxxxxx;

        this.player.src(val);
        videojs(`video${this.activeIndex}`).ready(function () {
          this.player = this;
          this.player.play();
        });
      }, 0);
    }
  },
  mounted() {
    this.clickSurveillance();
    this.setLoading();
  },
  beforeDestroy() {
    this.closeVideo();
  },

  methods: {
    // 点击摄像头s
    clickSurveillance(res) {
      this.Camera = true;

      // 注意这里需要加定时器或者在异步请求中才可以
      setTimeout(() => {
        this.player = videojs(
          `video${this.activeIndex}`,
          {
            bigPlayButton: false,
            controlBar: false,
            notSupportedMessage: "此视频暂无法播放，请稍后再试"
          },
          function () {
            this.play();
          }
        );

        // 当前要播放的视频地址
        // this.videoUrl = xxxxxx;

        this.player.src(this.videoUrl);
        let url = this.videoUrl;
        videojs(`video${this.activeIndex}`).ready(function () {
          this.player = this;
          this.player.play();
          this.on("error", function () {
            console.log(
              "load video error: " +
                this.error().code +
                ", src:" +
                this.currentSrc()
            );
            this.error(null);
            this.player.src(url); // 失败重新加载
          });
        });
      }, 500);
    },

    // 加载动画
    setLoading() {
      const videoLoading = document.querySelector(`#video${this.activeIndex}`);
      videoLoading.onloadstart = () => {
        console.log("开始加载");
        this.videoLoad = true;
      };
      videoLoading.oncanplay = () => {
        console.log("开始播放");
        this.videoLoad = false;
      };
      console.log(videoLoading);
    },

    // 关闭摄像头
    closeVideo() {
      this.player.dispose();
      this.Camera = false;
    }
  }
};
</script>

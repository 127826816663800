var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"datav"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"area area4",on:{"click":_vm.handleClick}},[_vm._m(4),_c('img',{attrs:{"src":require("../../public/images/dtText4.png"),"alt":""}})]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)]),_vm._m(10),_c('div',{staticClass:"weixing"}),_c('div',{staticClass:"yun"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"guang"}),_c('div',{staticClass:"guang guang1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area area1"},[_c('div',{staticClass:"area1Img areaImg"},[_c('div',{staticClass:"aureole"})]),_c('img',{attrs:{"src":require("../../public/images/dtText1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area area2"},[_c('div',{staticClass:"area2Img areaImg"},[_c('div',{staticClass:"aureole"})]),_c('img',{attrs:{"src":require("../../public/images/dtText2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area area3"},[_c('div',{staticClass:"area3Img areaImg"},[_c('div',{staticClass:"aureole"})]),_c('img',{attrs:{"src":require("../../public/images/dtText3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area4Img areaImg"},[_c('div',{staticClass:"aureole"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area area5"},[_c('div',{staticClass:"area5Img areaImg"},[_c('div',{staticClass:"aureole"})]),_c('img',{attrs:{"src":require("../../public/images/dtText4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area area6"},[_c('div',{staticClass:"area6Img areaImg"},[_c('div',{staticClass:"aureole"})]),_c('img',{attrs:{"src":require("../../public/images/dtText6.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area area7"},[_c('div',{staticClass:"area7Img areaImg"},[_c('div',{staticClass:"aureole"})]),_c('img',{attrs:{"src":require("../../public/images/dtText2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area area8"},[_c('div',{staticClass:"area8Img areaImg"}),_c('img',{attrs:{"src":require("../../public/images/dtText7.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area area9"},[_c('div',{staticClass:"area9Img areaImg"}),_c('img',{attrs:{"src":require("../../public/images/dtText8.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"industry"},[_c('div',{staticClass:"industryList"},[_c('div',{staticClass:"industry1 industryIndex"},[_c('img',{attrs:{"src":require("../../public/images/cyMin1.png"),"alt":""}}),_c('p',{staticClass:"listText"},[_vm._v("特色蔬菜产业")])]),_c('div',{staticClass:"industry2 industryIndex"},[_c('img',{attrs:{"src":require("../../public/images/cyMin2.png"),"alt":""}}),_c('p',{staticClass:"listText"},[_vm._v("优质稻米产业")])]),_c('div',{staticClass:"industry3 industryIndex"},[_c('img',{attrs:{"src":require("../../public/images/cyMin3.png"),"alt":""}}),_c('p',{staticClass:"listText"},[_vm._v("健康生猪产业")])])])])
}]

export { render, staticRenderFns }
<template>
  <div class="dataShell">
    <div class="platformTitle" >
      <!-- @click="handleClick(6)" -->
      <div class="left option">
        <div class="optionBtn">
          <div class="btn bottom" :class="{active:routeData==1}" @click.stop="handleClick(1)">一张图</div>
        </div>
        <div class="optionBtn">
           <div class="btn bottom" :class="{active:routeData==2}" @click.stop="handleClick(2)">专家库</div>
        </div>
        <div class="optionBtn">
           <div class="btn bottom" :class="{active:routeData==3}" @click.stop="handleClick(3)">病虫害预防</div>
        </div>
      </div>
      <div class="right option">
         <div class="optionBtn">
           <div class="btn bottom" :class="{active:routeData==4}" @click.stop="handleClick(4)">溯源分析</div>
         </div>
        <div class="optionBtn">
          <div class="btn bottom" :class="{active:routeData==5}" @click.stop="handleClick(5)">电商平台</div>
        </div>
        <div class="optionBtn goHome">
           <div class="btn" @click.stop="handleClick(7)">
            <div class="min-line"></div>
            <div class="min-line"></div>
            <div class="min-line"></div>
            <div class="min-line"></div>
            <div class="styleBox">
               返回首页
            </div>
           </div>
        </div>
      </div>
      <div class="homePage" @click.stop="handleClick(6)"></div>
    </div>
    <div class="platformContent">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {

  data () {
    return {
      routeData:1,
      timers:{}
    }                               
  },
    watch:{
    $route(val,oldval){
      // console.log(val,898998);
    if(val.fullPath=='/dataPlatform'){
      this.routeData=2
    }else if(val.fullPath=='/dataPlatform/kap'){
       this.routeData=3
    }else if(val.fullPath=='/dataPlatform/traceability'){
       this.routeData=4
    }else if(val.fullPath=='/dataPlatform/ec'){
       this.routeData=5
    }else{
       this.routeData=1
    }
    }
  },
  created(){
    let localTime=new Date()
    let year=localTime.getFullYear()
    let mon=localTime.getMonth()+1
    let date=localTime.getDate()
    this.timers.day=year+'年'+mon+'月'+date+'日'

  },
  mounted(){
    if(this.$route.path=='/dataPlatform'){
      this.routeData=2
    }else if(this.$route.path=='/dataPlatform/kap'){
       this.routeData=3
    }else if(this.$route.path=='/dataPlatform/traceability'){
       this.routeData=4
    }else if(this.$route.path=='/dataPlatform/ec'){
       this.routeData=5
    }else{
        this.routeData=1
    }
    // console.log(this.$route.path,77887);  
  },
  methods: {
    handleClick(data){
      if(data==1){
        this.$router.push('/oneMap')
      }else if(data==2){
        this.$router.push('/dataPlatform')
      }else if(data==3){
        this.$router.push('/dataPlatform/kap')
      }else if(data==4){
        this.$router.push('/dataPlatform/traceability')
      }else if(data==5){
        this.$router.push('/dataPlatform/ec')
      }else if(data==6){
        this.$router.push('/')
      }else if(data==7){
        this.$router.push('/guide')
      }
    }
  },
}
</script>

<style scoped lang="less">
.dataShell{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // border: 1px solid red;
  background: url("../../public/images/bg2.jpg") no-repeat;
  background-size: 100% 100%;
    box-sizing: border-box;
  .platformTitle{
    height: 8vh;
    // border: 1px solid red;
    background: url('../../public/images/titleBg1.png') no-repeat;
    background-size: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 4%;
    position: relative;
    .homePage{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform:translateX(-50%);
      width: 40%;
      height: 100%;
      // border: 1px solid red;
      user-select: none;
      cursor: pointer;
    }
    .option{
       width: 25%;
      height: 80%;
      // border: 1px solid red;
      display: flex;
      .optionBtn{
        width: 33.3%;
        height: 100%;
        // border: 1px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn{
          color: #5bb3db;
          //  border: 1px solid red;
           font-size: 14px;
           height: 70%;
           width: 80%;
           display: flex;
           justify-content: center;
           align-items: center;
           user-select: none;
           cursor: pointer;
        }
        .active{
           background: url('../../public/images/xuanxiangBtn.png') no-repeat;
          background-size: 103% 110%;
          color: #c5f9ff;
          text-shadow:1px 1px 10px #fff;
        }
        // .bottom:hover{
        //   background: url('../../public/images/xuanxiangBtn.png') no-repeat;
        //   background-size: 103% 110%;
        //   color: #c5f9ff;
        //   text-shadow:1px 1px 10px #fff;
        // }
        
      }
      .goHome{
        // border: 1px solid red;
        // height: 50%;
        .btn{
              border: 1px solid rgb(6, 71, 129);
              position: relative;
              .min-line{
                width: 10px;
                height: 10px;
                position: absolute;
              }
              .min-line:nth-child(1){
                 border-left: 2px solid rgba(3, 235, 252, 0.5);
                border-top: 2px solid rgba(3, 235, 252, 0.5);
                top: 0;
                left: -1px;
              }
               .min-line:nth-child(2){
                 border-right: 2px solid rgba(3, 235, 252, 0.5);
                border-top: 2px solid rgba(3, 235, 252, 0.5);
                top: 0;
                right: -1px;
              }
               .min-line:nth-child(3){
                border-left: 2px solid rgba(3, 235, 252, 0.5);
                border-bottom: 2px solid rgba(3, 235, 252, 0.5);
                bottom: 0;
                left: -1px;
              }
               .min-line:nth-child(4){
                border-right: 2px solid rgba(3, 235, 252, 0.5);
                border-bottom: 2px solid rgba(3, 235, 252, 0.5);
                bottom: 0;
                right: -1px;
              }
        }
      }
      .goHome:hover{
        .styleBox{
          color: rgba(3, 235, 252, 0.8);
        }
             .min-line:nth-child(1){
                border-left: 2px solid rgba(3, 235, 252, 0.8);
                border-top: 2px solid rgba(3, 235, 252, 0.8);
              }
               .min-line:nth-child(2){
                 border-right: 2px solid rgba(3, 235, 252, 0.8);
                border-top: 2px solid rgba(3, 235, 252, 0.8);
              }
               .min-line:nth-child(3){
                border-left: 2px solid rgba(3, 235, 252, 0.8);
                border-bottom: 2px solid rgba(3, 235, 252, 0.8);
              }
               .min-line:nth-child(4){
                border-right: 2px solid rgba(3, 235, 252, 0.8);
                border-bottom: 2px solid rgba(3, 235, 252, 0.8);
              }
      
          }
    }

    .left{
      // border: 1px solid red;
   
    }
     .right{
      // border: 1px solid red;
    }
  }
  .platformContent{
   position: fixed;
   top: 8vh;
   left: 0;
   right: 0;
   bottom: 0;
  //  border: 1px solid green;
   box-sizing: border-box;
  }
}
</style>

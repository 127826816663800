<template>
  <div class="home">
    <div class="title">
        <div class="guang"></div>
        <div class="guang guang1"></div>
    </div>
    <div class="datav">

      <div class="area area1">
        <div class="area1Img areaImg">
          <div class="aureole"></div>
        </div>
        <img src="../../public/images/dtText1.png" alt="">
      </div>

      <div class="area area2">
        <div class="area2Img areaImg">
          <div class="aureole"></div>
        </div>
        <img src="../../public/images/dtText2.png" alt="">
      </div>

      <div class="area area3">
        <div class="area3Img areaImg">
          <div class="aureole"></div>
        </div>
        <img src="../../public/images/dtText3.png" alt="">
      </div>

      <div class="area area4" @click="handleClick">
         <div class="area4Img areaImg">
          <div class="aureole"></div>
        </div>
        <img src="../../public/images/dtText4.png" alt="">
      </div>

      <div class="area area5">
        <div class="area5Img areaImg">
          <div class="aureole"></div>
        </div>
        <img src="../../public/images/dtText4.png" alt="">
      </div>

      <div class="area area6">
        <div class="area6Img areaImg">
          <div class="aureole"></div>
        </div>
        <img src="../../public/images/dtText6.png" alt="">
      </div>

      <div class="area area7">
        <div class="area7Img areaImg">
          <div class="aureole"></div>
        </div>
        <img src="../../public/images/dtText2.png" alt="">
      </div>

      <div class="area area8">
        <div class="area8Img areaImg">
          <!-- <div class="aureole"></div> -->
        </div>
        <img src="../../public/images/dtText7.png" alt="">
      </div>

        <div class="area area9">
        <div class="area9Img areaImg">
          <!-- <div class="aureole"></div> -->
        </div>
        <img src="../../public/images/dtText8.png" alt="">
      </div>

    </div>
    <div class="industry">
      <div class="industryList">
      <div class="industry1 industryIndex">
        <img src="../../public/images/cyMin1.png" alt="">
        <p class="listText">特色蔬菜产业</p>
      </div>
      <div class="industry2 industryIndex">
             <img src="../../public/images/cyMin2.png" alt="">
        <p class="listText">优质稻米产业</p>
      </div>
      <div class="industry3 industryIndex">
             <img src="../../public/images/cyMin3.png" alt="">
        <p class="listText">健康生猪产业</p>
      </div>
      </div>
  
    </div>
    <div class="weixing"></div>
    <div class="yun"></div>
  </div>
</template>

<script>


export default {
data(){
  return{
  }
},
created(){
},
methods:{
  handleClick(){
    this.$router.push("/guide")
  }
}
};
</script>
<style lang="less" scoped>
@media screen and (max-width:106.25rem) {
  .industry{
    right: 12% !important;
  }
  .datav{
    margin-top:9% !important;
  }
}
@media screen and (max-width:96.875rem) {
  .industry{
    right: 10% !important;
  }
  .weixing{
    left: 10% !important;
    width: 60rem !important;
  }
   .datav{
    margin-top:11% !important;
  }
}
@media screen and (max-width:81.25rem) {
  .industry{
    right: 7% !important;
  }
  .weixing{
    left: 0% !important;
    width: 60rem !important;
  }
   .datav{
    margin-top:12% !important;
  }
}
@media screen and (max-width:75rem) {
  .industry{
    right: 5% !important;
  }
  .weixing{
    left: -4% !important;
    width: 60rem !important;
  }
    .datav{
    margin-top:14% !important;
  }
}
@media screen and (max-width:68.75rem) {
  .industry{
    right: 3% !important;
  }
   .datav{
    margin-top:10% !important;
  }
}
@media screen and (max-height:53.125rem) {  
  .industry{
     bottom: 4% !important;
  }
  // .home{
  //   .title{
  //     height: 6.25rem !important;
  //   }
  // }
   .datav{
    margin-top:9% !important;
  }
}
@media screen and (max-height:37.5rem) {  
  // .home{
  //   .title{
  //     height: 8.75rem !important;
  //   }
  // }
   .datav{
    margin-top:9% !important;
  }
}

.home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // border: .0625rem solid red;
  background: url("../../public/images/bg1.jpg") no-repeat center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    position: absolute;
    background: url("../../public/images/title2.png") no-repeat;
    width: 1000px;
    height: 150px;
    background-size: 100% 100%;
    // border: .0625rem solid red;                         
    margin-top: 2%;
    .guang{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 0;
          // border: 1px solid red;
          background: url("../../public/images/title2-2.png") no-repeat;
          background-size: 100% ;
          animation-name:shanshuo;
          animation-duration:1s;
          animation-iteration-count: infinite;
          animation-direction: alternate-reverse;
      }
       .guang1{
          animation-name:shanshuo;
          animation-duration:2s;
          animation-iteration-count: infinite;
          animation-direction: alternate-reverse;
      }
  }
    @keyframes shanshuo{
    // from表示动画的开始位置
    from{
      opacity: 0;
    }
    // to表示动画的结束位置
    to{
       opacity: 100%;
    }
  }
    @keyframes minIcon{
    // from表示动画的开始位置
    from{
      margin-top: 0%;
    }
    // to表示动画的结束位置
    to{
      margin-top: -1%;
    }
  }
  @keyframes minIcon1{
    // from表示动画的开始位置
    from{
      margin-top: -1%;
    }
    // to表示动画的结束位置
    to{
      margin-top: 0%;
    }
  }
  .datav {
    position: relative;
    z-index: 9;
    // border: .0625rem solid rgb(204, 0, 255);
    background: url("../../public/images/dt.png") no-repeat;
    background-size: 100% 100%;
    width:75rem;
    height: 46.875rem;
    margin-top:8%;

    .area{
      width: 9.375rem;
      position: absolute;
      display: inline-block;
      height: auto;
      // border: .0625rem solid red;
      user-select: none;
      cursor:pointer;
      z-index: 999;
            // animation-name: 要对当前元素生效的关键帧的名字;
    // animation-duration animation的执行时间
    // animation-delay动画的延时
    animation-name:minIcon;
    animation-duration:1s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
      .areaImg{
        // border: .0625rem solid red;
        width: 4.375rem;
        height: 4.375rem;
        position: relative;
        margin: auto;
        user-select: none;
        cursor:pointer;
        .aureole{
          // border: .0625rem solid red;
          position: absolute;
          width: 12.5rem;
          height: 9.375rem;
          background: url("../../public/images/dtIcongq.png") no-repeat;
          //  border: .0625rem solid red;
        }
      }
      img{
        width: 7.5rem;
      }
    }
    .area:hover{
      animation-name:none;
      // border: .3125rem solid rgba(255, 0, 0, 0);
    }
    .area1{
      top: 16%;
      left: 40%;
      .area1Img{
        background: url("../../public/images/dtIcon1.png") no-repeat;
        background-size:100% ;
        .aureole{
          top: -1rem;
          left: -2.5rem;
        }
      }
      img{
        width: 6.875rem;
      }
    }
    .area2{
      top: 17%;
      left: 56%;
      .area2Img{
        background: url("../../public/images/dtIcon2.png") no-repeat;
        background-size:100% ;
        .aureole{
          top: -1rem;
          left: -2.5rem;
        }
      }
       img{
        width: 8.125rem;
      }
    }
    .area3{
      // border: .0625rem solid red;
      top: 32%;
      left:63%;
      .area3Img{
        background: url("../../public/images/dtIcon3.png") no-repeat;
        background-size:100% ;
        .aureole{
           top: -1rem;
          left: -2.5rem;
        }
      }
     img{
        width: 5.9375rem;
      }
    }
    .area4{
      top: 33%;
      left:50%;
      .area4Img{
        background: url("../../public/images/dtIcon4.png") no-repeat;
        background-size:100% ;
        .aureole{
          top: -1rem;
          left: -2.5rem;
        }
      }
     img{
        width: 8.125rem;
      }
    }
    .area5{
      top: 47%;
      left:40%;
      .area5Img{
        background: url("../../public/images/dtIcon4.png") no-repeat;
        background-size:100% ;
        .aureole{
          top: -1rem;
          left: -2.5rem; 
        }
      }
    img{
        width: 8.125rem;
      }
    }
    .area6{
      top: 50%;
      left:25%;
      .area6Img{
        background: url("../../public/images/dtIcon5.png") no-repeat;
          background-size:100% ;
        .aureole{
          top: -1rem;
          left: -2.5rem;
        }
      }
     img{
        width: 8.125rem;
      }
    }
    .area7{
      top: 68%;
      left:20%;
      .area7Img{
        background: url("../../public/images/dtIcon2.png") no-repeat;
        background-size:100% ;
        .aureole{
          top: -1rem;
          left: -2.5rem;
        }
      }
      img{
        width: 8.125rem;
      }
    }
      .area8{
      top: 36%;
      left:20%;
      .area8Img{
        background: url("../../public/images/dtIcon6.png") no-repeat;
        background-size:100% ;
      }
      img{
        width: 3.75rem;
      }
    }
    .area9{
      // border: 1px solid red;
      width: auto;
      height: auto;
      top: 36%;
      left:38%;
      // border: .0625rem solid red;
      .area9Img{
        // border: .0625rem solid red;
        width: 4.0625rem;
        height: 3.4375rem;
        background: url("../../public/images/dtIcon7.png") no-repeat;
        background-size:100% ;
      }
      img{
        width: 3.75rem;
      }
    }
  }
  .industry{
    // border: .0625rem solid red;
    position: absolute;
    z-index: 9999;
    width: 13.75rem;
    height: 15.625rem;
    // border: .0625rem solid red;
    background: url("../../public/images/zdcy.png") no-repeat;
    background-size: 100%;
    right: 20%;
    bottom: 10%;
    z-index: 99;
    .industryList{
      // border: .0625rem solid red;
      margin-top:30%;
     .industryIndex{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3.125rem;
        user-select: none;
        cursor:pointer;
      img{
        width: 2.5rem;
        margin-right: .5rem;
      }
  
      .listText{
      color: rgba(255, 255, 255, 0.8);
      font-size: .9375rem;
      // border: .0625rem solid red;
      }
      }
      .industryIndex:hover{
        // border: .0625rem solid #fff;
        background: rgba(13, 112, 203, 0.25);
      }
   
    }
  }

  .weixing{
    position: absolute;
    left: 15%;
    top: 20%;
    width: 60rem;
    height: 47.5rem;
    background: url('../../public/images/weixing.png') no-repeat;
    background-size:100%;
    // border: .0625rem solid red;
    z-index: 8;
    animation:wxIcon;
     animation-duration:2.5s;
    animation-iteration-count: infinite;
     animation-direction: alternate-reverse;
  }


}
  @keyframes wxIcon{
    // from表示动画的开始位置
    from{
     width: 50rem;
     opacity: 0;
    }
    // to表示动画的结束位置
    to{
      width: 60rem;
    }
  }

  .yun{
    position: absolute;
    left: 2%;
    top: 12%;
    width: 100rem;
    height: 47.5rem;
    background: url('../../public/images/yun.png') no-repeat;
    background-size:100%;
    transition: all 0.5s linear; 
    // animation-name: 要对当前元素生效的关键帧的名字;
    // animation-duration animation的执行时间
    // animation-delay动画的延时
    animation-name:yunduo;
    animation-duration:150s;
    animation-iteration-count: infinite;
  

  }

  // .yun:hover{
  //   left:50%;
  //   // transform: translateX(100%);
  // }
  @keyframes yunduo{
    // from表示动画的开始位置
    from{
      margin-left: -100%;
    }
    // to表示动画的结束位置
    to{
      margin-left: 100%;
    }
  }


</style>

<template>
  <div class='shell'>
 <video
    id="myvideo"
    class="video-js vjs-default-skin videoBox"
    controls
    preload="auto"
    width="300px 100%"
     poster="../../../public/ceshi.png"
>
    <source :src='url' type="application/x-mpegURL" />
</video>
<!-- <div class="btn" @click="handleClick(videoIndex)">按钮</div> -->

  
  </div>
</template>

<script>
import "../../assets/aliIcon/iconfont.css"
import videojs from  'video.js/dist/video.min'
import 'video.js/dist/video-js.min.css'
import 'videojs-flash/dist/videojs-flash'
export default {
  props:['videoIndex'],
  data () {
    return {
      url:"https://open.ys7.com/v3/openlive/C06546634_1_1.m3u8?expire=1669602642&id=514749494110191616&t=ac6006e6f43e3f8cd964b17fd28e721f61ebf0a7461e32fb260e7e691d8e9a97&ev=100",
      videoData:[
        {
          label: "https://open.ys7.com/v3/openlive/C06546634_1_1.m3u8?expire=1669602642&id=514749494110191616&t=ac6006e6f43e3f8cd964b17fd28e721f61ebf0a7461e32fb260e7e691d8e9a97&ev=100",
          name: "监控1",
          value: 0
        },{
          label: "https://open.ys7.com/v3/openlive/C06546634_3_1.m3u8?expire=1669602767&id=514750016108105728&t=d67ce58ce5dbaab104373b23318ed24fe45d2fc46fb3b11899d491a83a3095e3&ev=100",
          name: "监控2",
          value: 1
        },{
          label: "https://open.ys7.com/v3/openlive/C06546634_4_1.m3u8?expire=1669602768&id=514750018947657728&t=01af6a20c13b99388c828c3f34c5d7c209391f2ad6e7467d0af3a9f565f1f5e2&ev=100",
          name: "监控3",
          value: 2
        }
      ]
    }
  },
  watch:{
    videoIndex(val,oldVal){
      this.handleClick(val)
    }
  },
  mounted(){
     this.$nextTick(() => {
    this.getVideo();
     });

  },
  methods:{
    handleClick(data){
    if(data==1){
       var myPlayer = videojs("myvideo");
       myPlayer.src([
           {
               type: "application/x-mpegURL",
               src: "https://open.ys7.com/v3/openlive/C06546634_3_1.m3u8?expire=1669602767&id=514750016108105728&t=d67ce58ce5dbaab104373b23318ed24fe45d2fc46fb3b11899d491a83a3095e3&ev=100" //CCTV3频道
           }
       ]);
       myPlayer.play();
    }else if(data==2){
        var myPlayer = videojs("myvideo");
       myPlayer.src([
           {
               type: "application/x-mpegURL",
               src: "https://open.ys7.com/v3/openlive/C06546634_4_1.m3u8?expire=1669602768&id=514750018947657728&t=01af6a20c13b99388c828c3f34c5d7c209391f2ad6e7467d0af3a9f565f1f5e2&ev=100" //CCTV3频道
           }
       ]);
       myPlayer.play();
    }else{
        var myPlayer = videojs("myvideo");
       myPlayer.src([
           {
               type: "application/x-mpegURL",
               src: "https://open.ys7.com/v3/openlive/C06546634_1_1.m3u8?expire=1669602642&id=514749494110191616&t=ac6006e6f43e3f8cd964b17fd28e721f61ebf0a7461e32fb260e7e691d8e9a97&ev=100" //CCTV3频道
           }
       ]);
       myPlayer.play();
    }
       
    },
    getVideo() {
	    this.airPlayer=videojs(
	       "myvideo",
	       {
             autoplay: true, //自动播放
	           bigPlayButton: false,
	           textTrackDisplay: false,
	           posterImage: true,
	           errorDisplay: false,
	           controlBar: true
	       },
	      //  function() {
	      //      this.play();
	      //  }
       
    ); 
     setTimeout(() => {
          this.airPlayer.src({
            src: this.videroUrl,
            type: "application/x-mpegURL",
          });
          this.airPlayer.play();
        }, 200);
	}
  },

   destroyed(){
      	this.airPlayer.dispose()  
    },

}
</script>

<style scoped lang="less">
.shell{
  width: 100%;
  height: 100%;
  position: relative;
  // border: 1px solid red;
  .videoBox{
    width: 100% !important;
    height: 100% !important;
    // border: 1px solid red;
  }
}
</style>

<template>
  <div class="specialist" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" element-loading-text="拼命加载中">
    <!-- 头部标题 -->
    <div class="header">
      <div class="header-shell">
        <div class="headerData">
          <div class="icon"></div>
          <div class="text">注册专家数 <span>{{headerData[0]}}</span> 个</div>
        </div>
        <div class="headerData">
          <div class="icon"></div>
          <div class="text">指导农民总数 <span>{{headerData[1]}}</span> 次</div>
        </div>
        <div class="headerData">
          <div class="icon"></div>
          <div class="text">覆盖基地占比 <span>{{headerData[2]}}</span> </div>
        </div>
        <div class="headerData">
          <div class="icon"></div>
          <div class="text">视频直播数 <span>{{headerData[3]}}</span> 场次</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <!-- 问题类型汇总 -->
        <div class="problemTypes leftBox">
          <div class="title"></div>
          <div class="content1">
            <div class="problemLeft">
              <div ref="chart" id="mychart" style="width:100%;height:100%"></div>
            </div>

            <!-- <div class="right">2121</div> -->
          </div>
        </div>

        <!-- 问题排行榜 -->
        <div class="rankingList leftBox">
          <div class="title"></div>
          <div class="content">
            <vue-seamless-scroll :data="rankingData" :class-option="optionSingleHeightTime" class="seamless-warp">
              <!-- 列表 -->
              <div class="rankingDataList" v-for="(item,index) in rankingData" :key="index" @click="qaClick(item)">
                <div class="rankingDataShell">
                  <div class="ranking">NO.{{index+1}} <div class="triangle"></div>
                  </div>
                  <div class="rankingText">
                    <div class="Qdata rankingData">
                      <p><span>Q</span> {{item.problem}}</p>
                    </div>
                    <div class="Adata rankingData">
                      <p><span>A</span>{{item.answer}}</p>
                    </div>
                  </div>
                  <div class="enquiryDetails">【查看详情】</div>
                </div>
              </div>
            </vue-seamless-scroll>

          </div>
        </div>
      </div>
      <!-- 中间轮播图的点击弹窗 -->
      <div class="popoutShell qaShell" v-show='qaPopoutShow'>
        <div class="expertDetails">
          <!-- 弹窗内容 -->
          <div class="content">
            <!-- {{carouselDetails}} -->
            <div class="title">
              <p>问答详情</p>
              <div class="line"></div>
            </div>
            <div class="expertData">

              <div class="expertText">
                <p><span>{{qaDetails.problem}}</span></p>
                <p v-html="qaDetails.answer"></p>
              </div>
            </div>
          </div>
          <!-- 取消按钮 -->
          <div class="cancel" @click="qaCancelClick">
            <i class="el-icon-circle-close"></i>
          </div>
        </div>
      </div>
      <div class="middle">
        <!-- 轮播图 -->
        <div class="carousel">
          <div class="carouselBox">
            <div class="carousel-img" v-for="(item,index) in carouselData" :key="index" @click="carouselClick(item)">
              <div class="left">
                <img :src="item.photo" alt="" v-if="item.photo!=''">
                <img src='../../public/images/photoMoren.png' alt="" v-else>
              </div>
              <div class="right">
                <div class="right-name minBox">{{item.name}}</div>
                <div class="right-type minBox">
                  <p v-html="item.post"></p>
                </div>
                <div class="right-number minBox">
                  <p>问答次数: <span>{{item.qa}}次</span> </p>
                  <p>视频直播: <span>{{item.live}}次</span> </p>
                </div>
              </div>
            </div>

          </div>
          <div class="dipan"></div>

        </div>
        <!-- 直播 -->
        <div class="liveStreaming">
          <div class="title">
          </div>
          <div class="content">
            <!-- 直播视频live -->
            <div class="liveBox liveData">
              <!-- <div class="btn">
                <img src="../../public/images/liveIcon.png" alt="">
                <p>直播中</p>
              </div>  -->
              <video :src="srcUrl" autoplay loop muted controls preload="auto" width="100%" height="100%"></video>
              <!-- <div id="player"></div> -->
            </div>
            <!-- 直播相关列表 -->
            <div class="formBox liveData">
              <div class="formTitle">
                <div class="formTitleText">姓名</div>
                <div class="formTitleText">擅长领域</div>
                <div class="formTitleText">问答次数</div>
                <div class="formTitleText">视频直播</div>
              </div>
              <div class="formContent">
                <div class="list" v-for="(item,index) in formData" :key="index">
                  <div class="listShell">
                    <div class="formTitleList">{{item.name}}</div>
                    <div class="formTitleList">{{item.type}}</div>
                    <div class="formTitleList">{{item.qaNumber}}</div>
                    <div class="formTitleList">{{item.liveNumber}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 中间轮播图的点击弹窗 -->
      <div class="popoutShell" v-show='popoutShow'>
        <div class="expertDetails">
          <!-- 弹窗内容 -->
          <div class="content">
            <!-- {{carouselDetails}} -->
            <div class="title">
              <p>专家详情</p>
              <div class="line"></div>
            </div>
            <div class="expertData">
              <div class="imgBox">
                <div class="shell">
                  <img :src="carouselDetails.photo" alt="">
                </div>
              </div>

              <div class="expertText">
                <p class="name">姓名：{{carouselDetails.name}}</p>
                <p class="position">职务：{{carouselDetails.post}}</p>
                <p class="qa">问答次数：<span>{{carouselDetails.qa}} 次</span></p>
                <p class="detailsLive">直播次数：<span>{{carouselDetails.live}} 次</span></p>
                <p class="detailsIntroduction" v-html="carouselDetails.introduce"></p>
              </div>
            </div>
          </div>
          <!-- 取消按钮 -->
          <div class="cancel" @click="cancelClick">
            <i class="el-icon-circle-close"></i>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- 本周服务次数及时长 改=======-->
        <div class="weekNumber rightBox">
          <div class="title"></div>
          <div class="content">
            <div ref="chart" id="mychart1" style="width:100%;height:100%"></div>
          </div>
        </div>
        <!-- 年度服务次数统计 改========-->
        <div class="monthNumber rightBox">
          <div class="title"></div>
          <div class="content">
            <div ref="chart" id="mychart2" style="width:100%;height:100%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
import { expertData, expertList, expertApi } from '../api/api'
export default {
  data () {
    return {
      yearnumber: [],//年度服务次数
      carouselDetails: {},//专家详情
      popoutShow: false, //轮播图弹窗显隐
      qaDetails: {},//问答详情
      qaPopoutShow: false, //问答弹窗显隐
      headerData: [],   //头部数据
      loading: true,    //loading 加载
      formData: [],     //直播列表数据
      rankingData: [],  //问题排行榜数据
      carouselData: [
        {
          name: '李占省',
          post: '中国农科院蔬菜花卉研究所, 副研究员',
          qa: "1200",
          live: '260',
          photo: '',
        },
        {
          name: '李占省',
          post: '中国农科院蔬菜花卉研究所, 副研究员',
          qa: "1200",
          live: '260',
          photo: '',
        },
        {
          name: '李占省',
          post: '中国农科院蔬菜花卉研究所, 副研究员',
          qa: "1200",
          live: '260',
          photo: '',
        },
        {
          name: '李占省',
          post: '中国农科院蔬菜花卉研究所, 副研究员',
          qa: "1200",
          live: '260',
          photo: '',
        },
        {
          name: '李占省',
          post: '中国农科院蔬菜花卉研究所, 副研究员',
          qa: "1200",
          live: '260',
          photo: '',
        },
        {
          name: '李占省',
          post: '中国农科院蔬菜花卉研究所, 副研究员',
          qa: "1200",
          live: '260',
          photo: '',
        },

      ], //轮播图数据
      live_url: {}, //专家页面-直播地址设置
      srcUrl: 'https://village.shared-chains.cn/upload/1.mp4'
    }
  },
  computed: {
    optionSingleHeightTime () {
      return {
        // singleHeight: 26,
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        //  singleHeight:90, // 单步运动停止的高度(默认值0是无缝不停止的滚动direction => 0/1
        //  singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动direction => 2/3
        //  waitTime: 1500 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  created () {
    this.onLoad()
  },
  mounted () {
    this.pageData()   //页面数据
  },
  methods: {
    qaClick (data) {
      this.qaDetails = data
      this.qaPopoutShow = true
    },
    qaCancelClick () {
      this.qaPopoutShow = false
    },
    cancelClick () {
      this.popoutShow = false
    },
    carouselClick (data) {
      this.carouselDetails = data
      this.popoutShow = true
    },

    // 清橙云视频组件 视频组件========
    createVideo (e) {
      let urlList = this.live_url.value.map(item => {
        return {
          url: item.val1,
          kitToken: item.key,
        }
      })
      const player = new ImouPlayer('#player');
      player.setup({         // 播放器初始化
        src: [
          ...urlList
        ],                   // 播放地址
        poster: '',          // 封面图url
        width: '100%',
        height: '100%',
        autoplay: true,
        controls: false,     // 是否展示控制栏
      });
      player.play()          // 播放
    },
    onLoad () {
      expertList().then(res => {
        // console.log(res.data.list.data,9999);
        let list = res.data.list.data
        let liveData = []
        list.forEach(item => {
          let obj = {}
          obj.name = item.name
          obj.qaNumber = item.answer_num
          obj.liveNumber = item.video_num
          obj.type = item.product_ids.toString().replace(/,/ig, '/')
          liveData.push(obj)
        })
        // console.log(liveData,111);
        this.formData = liveData
      })
      let apiObj = {
        key: 'special_data'
      }

      expertApi(apiObj).then(res => {
        this.live_url = res.data.data.live_url
        this.headerData = res.data.data.special_data.val1_arr
        // this.createVideo()
      })

    },
    pageData () {
      const myChart = echarts.init(document.getElementById("mychart")) // 图标初始化
      const myChart1 = echarts.init(document.getElementById("mychart1")) // 图标初始化
      const myChart2 = echarts.init(document.getElementById("mychart2")) // 图标初始化 年度服务次数统计
      //让后台控制柱状图数据
      let apiobj2 = {
        key: 'year_number'
      }
      expertApi(apiobj2).then(res => {
        this.yearnumber = res.data.data.year_number.val1_arr
        console.log('this.yearnumber', this.yearnumber);
      })
      let obj = {
        id: 2,
      }
      let that = this
      expertData(obj).then(res => {
        // console.log('res.data.user轮播图数据',res);
        // 问题类型汇总 数据改造
        let departmentGather = res.data.data //问题汇总数据
        let optionLegendData = []    //饼图 option->Legend->Data
        let optionSeriesData = []    //饼图 option->Series->Data
        departmentGather.forEach((item, index) => {
          let obj = {}
          obj.name = item.type_str
          obj.icon = 'roundRect',
            obj.textStyle = {
              color: '#fff'
            }
          optionLegendData.push(obj) //饼图 option->Legend->Data

          let obj1 = {}
          obj1.name = item.type_str
          obj1.value = item.num
          if (index == 0) {              //饼图颜色自定义 之前了解写死5个类型
            obj1.label = { backgroundColor: 'none', color: 'rgb(84,112,198)' }
          } else if (index == 1) {
            obj1.label = { backgroundColor: 'none', color: 'rgb(145,204,117)' }
          } else if (index == 2) {
            obj1.label = { backgroundColor: 'none', color: 'rgb(250,200,88)' }
          } else if (index == 3) {
            obj1.label = { backgroundColor: 'none', color: 'rgb(238,102,102)' }
          } else if (index == 4) {
            obj1.label = { backgroundColor: 'none', color: 'rgb(115,192,222)' }
          }
          optionSeriesData.push(obj1) //饼图 option->Series->Data
        })
        //问题排行榜
        let hitChartsOldData = res.data.list  //问题排行榜数据
        let hitChartsNewData = []
        hitChartsOldData.forEach((item, index) => {
          let obj = {}
          obj.problem = item.question
          obj.answer = item.answer
          obj.id = index
          hitChartsNewData.push(obj)
        })
        this.rankingData = hitChartsNewData
        //轮播图数据
        let carouselOldData = res.data.user  //轮播图接口数据
        let carouselNewData = []
        carouselOldData.forEach((item, index) => {
          let obj = {}
          obj.name = item.name
          obj.post = item.position
          obj.qa = item.answer_num
          obj.live = item.video_num
          obj.photo = item.img
          obj.introduce = item.introduce
          obj.id = item.id
          carouselNewData.push(obj)
        })
        if (carouselNewData.length !== 6 && carouselNewData.length < 6) {
          let arr = this.carouselData.slice(carouselNewData.length)
          let arr1 = carouselNewData.concat(arr)
          this.carouselData = arr1
        } else {
          this.carouselData = carouselNewData
        }
        //本周服务次数及时长;
        let date = []           //日期数组
        let numberServices = [] //服务次数
        let averageDuration = [] //平均时长
        let daysOldData = res.data.days7  //接口时间数据
        daysOldData.forEach(item => {
          date.push(item.create_time.slice(5, 10)) //组合时间数组
          numberServices.push(item.num) //服务次数数组
          averageDuration.push(item.avg) //平均时长数组
        })

        //年度服务次数统计 要改的部分========;
        console.log(res.data.years, '年度服务次数统计======');
        let yearArr = []           //年数组
        let numArr = []            //次数统计数组
        let yearOldData = res.data.years  //接口年数据
        yearOldData.forEach(item => {
          yearArr.push(item.year) //年数组
          numArr.push(item.num) //次数数组
        })
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          legend: {
            orient: 'vertical',
            right: 0,
            y: '2%',
            itemGap: 15,
            itemWidth: 15,
            fontWeight: '400',
            fontSize: 12,
            data: optionLegendData,
          },
          series: [
            {
              name: '相关数据：',
              type: 'pie',
              radius: [20, 100],
              center: ['45%', '50%'],
              roseType: 'area',
              itemStyle: {
                borderRadius: 5
              },
              data: optionSeriesData,
            }
          ]
        }
        let option1 = {
          color: ['#2291e3', '#bca72c'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: ['服务次数', '平均时长（h）'],
            right: 0,
            textStyle: {
              color: "#fff"
            }
          },
          toolbox: {
            //  feature: {
            //    saveAsImage: {}
            //  }
          },
          grid: {
            top: "15%",
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,

              data: date,
              splitLine: {     //修改线的颜色
                show: true,
                lineStyle: {
                  type: 'solid',
                  color: ['#1e294f'],
                  width: '1'
                }
              },
              axisLabel: {      //修改字体颜色
                color: "#fff",
              },

            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '次',         //加上单位
              nameTextStyle: {          //单位相关样式设置
                color: "#fff",
                padding: [0, 0, 10, -25],
              },
              axisLabel: {      //修改字体颜色
                color: "#fff",
              },
              splitLine: {     //修改线的颜色
                show: true,
                lineStyle: {
                  type: 'solid',
                  color: ['#1e294f'],
                  width: '1'
                }
              },
            }
          ],
          series: [
            {
              name: '服务次数',
              type: 'line',
              symbol: 'circle',     //设定为实心点
              //  stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 1,
                color: "#2291e3"
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(33, 137, 217,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(33, 137, 217,0)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: numberServices
            },
            {
              name: '平均时长（h）',
              type: 'line',
              stack: 'Total',
              symbol: 'circle',     //设定为实心点
              smooth: true,
              lineStyle: {
                width: 1,
                color: "#e8c125"
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(228, 189, 43,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(228, 189, 43,0)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: averageDuration
            },
          ]
        }
        //echarts 年度服务次数统计数据
        let option2 = {
          //展示鼠标悬浮在图表上时的提示信息
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: { type: 'none' },
            textStyle: { fontSize: 12, fontWeight: 500 },
            formatter (params) {
              // 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
              // console.log(params[0].name,7777);
              return params.reduce((pre, i) => {
                // console.log(pre,i,7877878);
                if (i.componentSubType === 'bar') {
                  i.marker = i.marker.replace(/\[object Object\]/, i.color.colorStops[1].color);
                  i.value = `<span style="flex: 1; text-align: right; margin-left: 16px;">${i.value}</span>`;
                  const current = `<div style="display: flex; align-items: center; height: 26px;">${i.marker}${params[0].name}月 ${i.value}</div>`;
                  return `${pre}${current}`;
                }
                return pre;
              }, '');
            },
          },
          // legend: {
          //   data: ['2021年'],
          //   right: 10,
          //   top: 12,
          //   type: 'scroll',
          //   icon: 'circle',
          //   itemWidth: 10,
          //   itemHeight: 10,
          //   itemGap: 16,
          //   show: true,
          //   textStyle: {
          //     color: "#fff"
          //   },
          //   // 因为柱子顶部和底部是通过 offset 跟柱子结合起来的，如果取消选中，     柱子数量变化，又要重新计算 offset，为了简单点就直接禁掉了
          //   selectedMode: false,
          // },
          //网格
          grid: { left: '3%', right: '4%', bottom: '5%', containLabel: true },
          //x轴
          xAxis: {
            type: 'category',
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            axisLine: { lineStyle: { color: '#F2F4F7' } },
            axisTick: { show: false },
            axisLabel: { color: '#f4f4f6' },
            splitLine: {     //修改线的颜色
              lineStyle: {
                type: 'solid',
                color: '#ffffff10',
                width: '1'
              }
            },

          },
          //y轴
          yAxis: {
            name: '( 次数 )',         //加上单位
            nameTextStyle: {          //单位相关样式设置
              color: "#fff",
              padding: [0, 0, 10, -25],
            },
            boundaryGap: [0, 0.01],
            axisLine: { lineStyle: { color: '#F2F4F7' } },
            axisTick: { show: false },
            axisLabel: { color: '#f4f4f6' },
            splitLine: {              lineStyle: {
                type: 'solid',
                color: '#ffffff10',
                width: '1'              }            },
          },
          series: [
            {
              name: '2021年',
              type: 'bar',
              barWidth: 12,
              barCategoryGap: 12,
              data: this.yearnumber,//需要响应的数据
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(21,223,254,0.8)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(18,59,160,0.2)',
                  },
                ])
              },
            },
            // {
            //   name: '2021年',
            //   type: 'pictorialBar', //
            //   symbolSize: [12, 6],

            //   // 这个属性很重要，直接决定了顶部跟柱子是否契合
            //   symbolOffset: [0, -4],

            //   z: 12,
            //   itemStyle: { color: '#15defd' },
            //   symbolPosition: 'end',

            //   // 要给成武汉这两个柱子的值，这是圆柱顶部，值不给对高度会对不上
            //   data: this.yearnumber,
            // },
            // {
            //   name: '2021年',
            //   type: 'pictorialBar',
            //   symbolSize: [12, 6],
            //   symbolOffset: [0, 1],
            //   z: 12,
            //   itemStyle: { color: 'rgba(18,59,160,0.8)' },
            //   symbolPosition: 'start',
            //   data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],// 这个给成大于 0 的值就行，设置了 position，一定会在柱子底部展示
            // },
          ],
          // legend 对应的 color，实际上柱状图的颜色都是在 itemStyle 里定义的
          color: ['#15defd'],
        }

        myChart.setOption(option) // 渲染页面
        myChart1.setOption(option1) // 渲染页面
        myChart2.setOption(option2) // 渲染页面
        window.addEventListener("resize", () => {
          myChart.resize()
          myChart1.resize()
          myChart2.resize()
        })

        this.loading = false
      })

    },
  },
}
</script>

<style scoped lang='less'>
@media screen and (max-width: 1700px) {
  .carousel {
    width: 100% !important;
    margin: auto !important;
    perspective: 1500px !important;
    .carouselBox {
      width: 46% !important;
      height: 55% !important;
      .carousel-img {
        top: 10% !important;
      }
    }
  }
  .carousel-img:nth-child(1) {
    transform: translateZ(320px) !important;
  }
  .carousel-img:nth-child(2) {
    transform: rotateY(60deg) translateZ(320px) !important;
  }
  .carousel-img:nth-child(3) {
    transform: rotateY(120deg) translateZ(320px) !important;
  }
  .carousel-img:nth-child(4) {
    transform: rotateY(180deg) translateZ(320px) !important;
  }
  .carousel-img:nth-child(5) {
    transform: rotateY(240deg) translateZ(320px) !important;
  }
  .carousel-img:nth-child(6) {
    transform: rotateY(300deg) translateZ(320px) !important;
  }
}
@media screen and (max-width: 1600px) {
  .rankingText {
    // font-size: 10px !important;
    width: 50% !important;
  }
  .carousel-img:nth-child(1) {
    transform: translateZ(300px) !important;
  }
  .carousel-img:nth-child(2) {
    transform: rotateY(60deg) translateZ(300px) !important;
  }
  .carousel-img:nth-child(3) {
    transform: rotateY(120deg) translateZ(300px) !important;
  }
  .carousel-img:nth-child(4) {
    transform: rotateY(180deg) translateZ(300px) !important;
  }
  .carousel-img:nth-child(5) {
    transform: rotateY(240deg) translateZ(300px) !important;
  }
  .carousel-img:nth-child(6) {
    transform: rotateY(300deg) translateZ(300px) !important;
  }
}
@media screen and (max-width: 1500px) {
  .carousel {
    width: 100% !important;
    margin: auto !important;
    perspective: 1500px !important;
    .carouselBox {
      width: 46% !important;
      height: 50% !important;
      .carousel-img {
        top: 15% !important;
      }
    }
  }
  .carousel-img:nth-child(1) {
    transform: translateZ(280px) !important;
  }
  .carousel-img:nth-child(2) {
    transform: rotateY(60deg) translateZ(280px) !important;
  }
  .carousel-img:nth-child(3) {
    transform: rotateY(120deg) translateZ(280px) !important;
  }
  .carousel-img:nth-child(4) {
    transform: rotateY(180deg) translateZ(280px) !important;
  }
  .carousel-img:nth-child(5) {
    transform: rotateY(240deg) translateZ(280px) !important;
  }
  .carousel-img:nth-child(6) {
    transform: rotateY(300deg) translateZ(280px) !important;
  }
}
@media screen and (max-width: 1430px) {
  .carousel {
    width: 100% !important;
    margin: auto !important;
    perspective: 1500px !important;
    .carouselBox {
      width: 45% !important;
      height: 45% !important;
      .carousel-img {
        top: 26% !important;
      }
    }
  }
  .carousel-img:nth-child(1) {
    transform: translateZ(260px) !important;
  }
  .carousel-img:nth-child(2) {
    transform: rotateY(60deg) translateZ(260px) !important;
  }
  .carousel-img:nth-child(3) {
    transform: rotateY(120deg) translateZ(260px) !important;
  }
  .carousel-img:nth-child(4) {
    transform: rotateY(180deg) translateZ(260px) !important;
  }
  .carousel-img:nth-child(5) {
    transform: rotateY(240deg) translateZ(260px) !important;
  }
  .carousel-img:nth-child(6) {
    transform: rotateY(300deg) translateZ(260px) !important;
  }
}
@media screen and (max-width: 1330px) {
  .carousel {
    width: 100% !important;
    margin: auto !important;
    perspective: 1500px !important;
    .carouselBox {
      width: 45% !important;
      height: 45% !important;
      .carousel-img {
        // top: 33% !important;
        // transform: translateY(-50%) !important;
      }
    }
  }
  .carousel-img:nth-child(1) {
    transform: translateZ(240px) !important;
  }
  .carousel-img:nth-child(2) {
    transform: rotateY(60deg) translateZ(240px) !important;
  }
  .carousel-img:nth-child(3) {
    transform: rotateY(120deg) translateZ(240px) !important;
  }
  .carousel-img:nth-child(4) {
    transform: rotateY(180deg) translateZ(240px) !important;
  }
  .carousel-img:nth-child(5) {
    transform: rotateY(240deg) translateZ(240px) !important;
  }
  .carousel-img:nth-child(6) {
    transform: rotateY(300deg) translateZ(240px) !important;
  }
}
@media screen and (max-width: 1250px) {
  .carousel {
    width: 100% !important;
    margin: auto !important;
    perspective: 1500px !important;
    .carouselBox {
      width: 46% !important;
      height: 45% !important;
      .carousel-img {
        // top: 33% !important;
        // transform: translateY(-50%) !important;
      }
    }
  }
  .carousel-img:nth-child(1) {
    transform: translateZ(230px) !important;
  }
  .carousel-img:nth-child(2) {
    transform: rotateY(60deg) translateZ(230px) !important;
  }
  .carousel-img:nth-child(3) {
    transform: rotateY(120deg) translateZ(230px) !important;
  }
  .carousel-img:nth-child(4) {
    transform: rotateY(180deg) translateZ(230px) !important;
  }
  .carousel-img:nth-child(5) {
    transform: rotateY(240deg) translateZ(230px) !important;
  }
  .carousel-img:nth-child(6) {
    transform: rotateY(300deg) translateZ(230px) !important;
  }
}
@media screen and (max-width: 1200px) {
  .carousel {
    width: 100% !important;
    margin: auto !important;
    perspective: 1500px !important;
    .carouselBox {
      width: 47% !important;
      height: 45% !important;
      .carousel-img {
        // top: 33% !important;
        // transform: translateY(-50%) !important;
      }
    }
  }
  .carousel-img:nth-child(1) {
    transform: translateZ(220px) !important;
  }
  .carousel-img:nth-child(2) {
    transform: rotateY(60deg) translateZ(220px) !important;
  }
  .carousel-img:nth-child(3) {
    transform: rotateY(120deg) translateZ(220px) !important;
  }
  .carousel-img:nth-child(4) {
    transform: rotateY(180deg) translateZ(220px) !important;
  }
  .carousel-img:nth-child(5) {
    transform: rotateY(240deg) translateZ(220px) !important;
  }
  .carousel-img:nth-child(6) {
    transform: rotateY(300deg) translateZ(220px) !important;
  }
}
@media screen and (max-width: 1100px) {
  .carousel {
    width: 100% !important;
    margin: auto !important;
    perspective: 1500px !important;
    .carouselBox {
      width: 47% !important;
      height: 45% !important;
      .carousel-img {
        // top: 33% !important;
        // transform: translateY(-50%) !important;
      }
    }
  }
  .carousel-img:nth-child(1) {
    transform: translateZ(200px) !important;
  }
  .carousel-img:nth-child(2) {
    transform: rotateY(60deg) translateZ(200px) !important;
  }
  .carousel-img:nth-child(3) {
    transform: rotateY(120deg) translateZ(200px) !important;
  }
  .carousel-img:nth-child(4) {
    transform: rotateY(180deg) translateZ(200px) !important;
  }
  .carousel-img:nth-child(5) {
    transform: rotateY(240deg) translateZ(200px) !important;
  }
  .carousel-img:nth-child(6) {
    transform: rotateY(300deg) translateZ(200px) !important;
  }
}
.specialist {
  height: 100%;
  // background: rgba(248, 248, 248, 0.305);
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .header {
    width: 60%;
    height: 6vh;
    // border: 1px solid red;
    background: url('../../public/images/headerbg.png') no-repeat;
    background-size: 100% 100%;
    .header-shell {
      width: 90%;
      height: 100%;
      // border: 1px solid red;
      margin: auto;
      display: flex;
      justify-content: space-between;
      .headerData {
        width: 25%;
        // border: 1px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
        .text {
          color: #fff;
          span {
            font-size: 20px;
            color: #ffc60e;
            font-weight: 550;
          }
        }
      }
      .headerData:nth-child(1) {
        .icon {
          background: url('../../public/images/zjkIcon1.png') no-repeat;
          background-size: 100%;
        }
      }
      .headerData:nth-child(2) {
        .icon {
          background: url('../../public/images/zjkIcon2.png') no-repeat;
          background-size: 100%;
        }
      }
      .headerData:nth-child(3) {
        .icon {
          background: url('../../public/images/zjkIcon3.png') no-repeat;
          background-size: 100%;
        }
      }
      .headerData:nth-child(4) {
        .icon {
          background: url('../../public/images/zjkIcon4.png') no-repeat;
          background-size: 100%;
        }
      }
    }
  }
  .content {
    width: 100%;
    height: 88%;
    // border: 1px solid red;
    display: flex;
    // 左侧
    .left {
      width: 25%;
      height: 100%;
      // border: 1px solid red;
      background: #182f943d;
      padding: 10px;
      box-sizing: border-box;
      .leftBox {
        width: 100%;
        height: 50%;
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        .title {
          width: 100%;
          height: 35px;
        }
        .content {
          flex: 1;
          width: 100%;
          // border: 1px solid red;
        }
      }
      // 问题类型汇总
      .problemTypes {
        display: flex;
        .title {
          background: url('../../public/images/Stitle1-1.png') no-repeat;
          background-size: 100%;
        }
        .content1 {
          flex: 1;
          width: 100%;
          // height:20%  ;
          // border: 1px solid green;
          // background: yellow;
          display: flex;
          .problemLeft {
            width: 100%;
            height: 100%;
            // border: 1px solid yellow;
          }
          // .right{
          //   width: 30%;
          //   height: 100%;
          //   border: 1px solid red;
          // }
        }
      }
      // 问题排行榜
      .rankingList {
        .title {
          background: url('../../public/images/Stitle1-2.png') no-repeat;
          background-size: 100%;
        }
        .content {
          // background: #a5c4ef;
          display: flex;
          flex-direction: column;
          overflow: auto;
          .rankingDataList {
            height: 33.3%;
            width: 100%;
            // border: 1px solid red;
            display: flex;
            align-items: center;
            margin: 2% 0%;
            padding: 1% 1%;
            user-select: none;
            cursor: pointer;
            // margin-bottom: 6%;
            .rankingDataShell {
              width: 100%;
              height: 80px;
              // border: 1px solid red;
              display: flex;
              align-items: center;
              .ranking {
                width: 80px;
                height: 80px;
                border: 1px solid #053e64;
                margin-right: 10px;
                background: #071949;
                color: #04eefe;
                font-weight: 550;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                font-size: 18px;
                .triangle {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 0px;
                  height: 0px;
                  border-left: 10px solid #04eefe;
                  border-right: 10px solid rgba(13, 255, 0, 0);
                  border-top: 10px solid #04eefe;
                  border-bottom: 10px solid rgba(238, 0, 255, 0);
                }
              }
              .rankingText {
                width: 55%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .rankingData {
                  display: flex;
                  color: #fff;
                  p {
                    width: 100%;
                    // border: 1px solid red;
                    height: auto;
                    word-wrap: break-word;
                    word-break: break-all;
                    overflow: hidden;
                    text-align: left;
                    line-height: 20px;
                    span {
                      display: inline-block;
                      width: 20px;
                      height: 20px;
                      background: #3370ff;
                      color: #fff;
                      text-align: center;
                      border-radius: 50%;
                      margin-right: 5px;
                    }
                  }
                }
                .Qdata {
                  p {
                    /* 这里要显示的设置宽度 */
                    overflow: hidden;
                    white-space: nowrap;
                    /* 文字超出宽度则显示ellipsis省略号 */
                    text-overflow: ellipsis;
                    width: 100%;
                  }
                }

                .Adata {
                  p {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical; /* 设置方向 */
                    -webkit-line-clamp: 2; /* 设置超过为省略号的行数 */
                    text-overflow: ellipsis;
                    color: #8190b8;
                    span {
                      background: #ffab47;
                    }
                  }
                }
              }
              .enquiryDetails {
                flex: 1;
                // width: 20%;
                // border: 1px solid red;
                text-align: center;
                color: #04ebfd;
              }
            }
          }
          .rankingDataList:nth-child(1) {
            .ranking {
              color: #de490a;
            }
          }
          .rankingDataList:nth-child(2) {
            .ranking {
              color: #ffab47;
            }
          }
          .rankingDataList:nth-child(3) {
            .ranking {
              color: #1e48aa;
            }
          }
          .rankingDataList:hover {
            background: #042f7a;
          }
        }
        .content::-webkit-scrollbar {
          display: none;
        }
      }
    }
    // 中间
    .middle {
      width: 50%;
      height: 100%;
      // border: 1px solid red;
      padding: 0 20px;
      // 轮播图
      .carousel {
        width: 100%;
        height: 60%;
        border: 1px solid rgba(255, 0, 0, 0);
        // border: 1px solid red;
        perspective: 1500px;
        .carouselBox {
          width: 44%;
          height: 55%;
          position: relative;
          // border: 1px solid red;
          margin: 6% auto;
          transform-style: preserve-3d;
          animation: rotate 30s ease infinite;
          transition-timing-function: cubic-bezier(1, -0.02, 0.98, 0.87);
          z-index: 99;
          .carousel-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // border: 1px solid red;
            background: url('../../public/images/gundong.jpg') no-repeat center
              center;
            background-size: 100% 100%;
            user-select: none;
            cursor: pointer;
            display: flex;
            padding: 8%;
            .left {
              width: 50%;
              // border: 1px solid red;
              background: url('../../public/images/imgbox1.jpg') no-repeat
                center center;
              background-size: auto 100%;
              img {
                width: 80%;
                height: 100%;
                // border: 1px solid red;
              }
            }
            .right {
              width: 50%;
              // border: 1px solid red;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .minBox {
                width: 100%;
                height: 25%;
                // border: 1px solid red;
              }
              .minBox:nth-child(1) {
                background: url('../../public/images/guang.png') no-repeat
                  center center;
                background-size: 150% 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
              }
              .minBox:nth-child(2) {
                height: 45%;
                padding: 5% 0;
              }
              .right-type {
                text-align: left;
                font-size: 12px !important;
                color: #fff;
              }
              .minBox:nth-child(3) {
                height: 30%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                p {
                  color: #fff;
                  span {
                    color: #0ee7ee;
                  }
                }
              }
            }
          }
          .carousel-img:nth-child(1) {
            transform: translateZ(350px);
          }
          .carousel-img:nth-child(2) {
            transform: rotateY(60deg) translateZ(350px);
          }
          .carousel-img:nth-child(3) {
            transform: rotateY(120deg) translateZ(350px);
          }
          .carousel-img:nth-child(4) {
            transform: rotateY(180deg) translateZ(350px);
          }
          .carousel-img:nth-child(5) {
            transform: rotateY(240deg) translateZ(350px);
          }
          .carousel-img:nth-child(6) {
            transform: rotateY(300deg) translateZ(350px);
          }
        }
        // .carouselBox:hover{
        //   animation-play-state: paused;
        // }
        // .carousel-img:hover{
        //   height: 104%;
        //   margin-top: -1%;
        //     // border: 1px solid #04ecfd7a;
        //   }
        .dipan {
          position: absolute;
          width: 70%;
          height: 29%;
          background: url('../../public/images/panzi.png') no-repeat;
          background-size: 100% 100%;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          // margin: auto;
          // margin-top: -5% ;
          // border: 1px solid red;
        }
      }
      // 直播
      .liveStreaming {
        width: 100%;
        height: 40%;
        // border: 1px solid red;
        background: url('../../public/images/zjkmiddle.png') no-repeat;
        background-size: 100% 100%;
        .title {
          width: 300px;
          height: 50px;
          // border: 1px solid red;
          background: url('../../public/images/dataPlatformtitle2.png')
            no-repeat center center;
          background-size: 100%;
          margin: auto;
        }
        .content {
          width: 100%;
          height: 80%;
          // border: 1px solid red;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          .liveData {
            // border: 1px solid red;
            width: 49.5%;
          }
          // 直播视频live
          .liveBox {
            // background: url('../../public/images/liveImg.jpg') no-repeat center
            //   center;
            background-size: 100%;
            // padding: 1%;
            .btn {
              width: 80px;
              height: 30px;
              border-radius: 5px;
              background: rgba(0, 0, 0, 0.3);
              color: #fff;
              padding: 5px;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              user-select: none;
              cursor: pointer;
              p {
                height: 100%;
                display: flex;
                line-height: 22px;
              }
            }
          }
          // 直播相关列表
          .formBox {
            display: flex;
            flex-direction: column;
            .formTitle {
              width: 95%;
              height: 35px;
              background: #022358;
              // padding-right: -20px;
              display: flex;
              .formTitleText {
                // border: 1px solid red;
                height: 100%;
                width: 20%;
                color: #a5c4ef;
                line-height: 35px;
              }
              .formTitleText:nth-child(2) {
                width: 40%;
              }
            }
            .formContent {
              flex: 1;
              width: 100%;
              // border: 1px solid green;
              overflow: auto;
              .list {
                // border: 1px solid red;
                height: 35px;
                width: 100%;
                padding-right: 4%;
                .listShell {
                  width: 100%;
                  height: 100%;
                  user-select: none;
                  cursor: pointer;
                  border-left: 2px solid #ffc70e00;
                  // border: 1px solid yellow;
                  display: flex;
                  .formTitleList {
                    // border: 1px solid red;
                    height: 100%;
                    width: 20%;
                    line-height: 35px;
                    color: #04ebfd;
                  }
                  .formTitleList:nth-child(2) {
                    width: 40%;
                  }
                  .formTitleList:nth-child(1),
                  .formTitleList:nth-child(2) {
                    color: #fff;
                  }
                }
                .listShell:hover {
                  background: #042e79;
                  border-left: 2px solid #ffc60e;
                  box-sizing: border-box;
                }
              }
            }
            /*滚动条样式*/
            .formContent::-webkit-scrollbar {
              width: 5px;
            }
            .formContent::-webkit-scrollbar-thumb {
              border-radius: 10px;
              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              background: #03e1dc;
            }
            .formContent::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              border-radius: 5px;
              background: #002969;
            }
          }
        }
      }
    }
    @keyframes rotate {
      0% {
        transform: rotateY(0);
      }
      16.6% {
        transform: rotateY(60deg);
      }
      33.3% {
        transform: rotateY(120deg);
      }
      49.8% {
        transform: rotateY(180deg);
      }
      66.4% {
        transform: rotateY(240deg);
      }
      83% {
        transform: rotateY(300deg);
      }
      100% {
        transform: rotateY(360deg);
      }
    }
    // 中间轮播图的点击弹窗
    .popoutShell {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // border: 1px solid red;
      background: rgba(0, 0, 0, 0.667);
      z-index: 88;

      .expertDetails {
        width: 800px;
        height: 500px;
        background: url('../../public/images/popupImg.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        .content {
          color: #fff;
          display: flex;
          flex-direction: column;
          .title {
            width: 100%;
            height: 15%;
            font-size: 22px;
            // border:1px solid red;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            .line {
              width: 50%;
              height: 3px;
              background: url('../../public/images/line11.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          .expertData {
            // border:1px solid red;
            display: flex;
            height: 70%;
            padding: 0 10%;
            margin-top: 4%;
            .imgBox {
              width: 38%;
              height: 100%;
              background: url('../../public/images/beijing123.png') no-repeat;
              background-size: 100% 100%;
              padding: 10px;
              .shell {
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  width: 120%;
                }
              }
            }

            .expertText {
              // border: 1px solid green;
              margin-left: 5%;
              width: 70%;
              p {
                font-size: 16px;
                text-align: left;
                margin-bottom: 5%;
                span {
                  color: #03e1dc;
                  font-weight: 650;
                }
              }
            }
          }
        }
        .cancel {
          position: absolute;
          right: -30px;
          top: -30px;
          font-size: 30px;
          width: 30px;
          height: 30px;
          //  border: 1px solid red;
          color: rgba(255, 255, 255, 0.653);
          user-select: none;
          cursor: pointer;
        }
        .cancel:hover {
          color: #fff;
        }
      }
    }
    // 问答的点击弹窗
    .qaShell {
      .title {
        height: 80px !important;
        // border: 1px solid red;
      }
      .expertData {
        // border: 1px solid red;
        width: 90%;
        // height: 90% !important;
        flex: 1;
        margin: auto;
        padding: 0 5% !important;
        .expertText {
          width: 100% !important;
          //  border: 1px solid red;
          display: flex;
          flex-direction: column;
          p {
            margin-bottom: 20px !important;
          }
          p:nth-child(2) {
            display: flex;
            flex: 1;
            line-height: 25px !important;
            // border: 1px solid red;
            overflow: auto;
            span {
              color: #fff !important;
              font-weight: 500 !important;
            }
          }
          /*滚动条样式*/
          p:nth-child(2)::-webkit-scrollbar {
            width: 5px;
          }
          p:nth-child(2)::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #03e1dc;
          }
          p:nth-child(2)::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            background: #002969;
          }
        }
      }
    }
    // 右侧
    .right {
      width: 25%;
      height: 100%;
      // border: 1px solid red;
      background: #182f943d;
      padding: 10px;
      box-sizing: border-box;
      .rightBox {
        width: 100%;
        height: 50%;
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        .title {
          width: 100%;
          height: 35px;
        }
        .content {
          flex: 1;
          width: 100%;
          // border: 1px solid red;
        }
      }
      // 本周服务次数及时长
      .weekNumber {
        // border: 1px solid red;
        .title {
          background: url('../../public/images/Stitle1-3.png') no-repeat;
          background-size: 100%;
        }
        .content {
          padding: 10px 0;
        }
      }
      // 年度服务次数统计
      .monthNumber {
        .title {
          background: url('../../public/images/Stitle1-4.png') no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}
</style>

<template>
  <!-- v-loading="loading" -->
  <div
    class="gisMapShell"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="loading"
    element-loading-text="Loading"
    element-loading-spinner="el-icon-loading"
  >
    <div class="left">
      <!-- 返回按钮 left1 -->
      <div class="left1 leftBox">
        <img src="../../public/images/back-btn.png" alt="" @click="getBack" />
        <div class="returnImg" @click="getBack">></div>
      </div>
      <!-- 大棚简介 left2 -->
      <div class="left2 leftBox">
        <div class="title">
          <img src="../../public/images/desc-title.png" alt="" />
        </div>
        <div class="text">
          <p>
            <span>大棚所有人：</span><span> {{ detailData.username }}</span>
          </p>
          <p>
            <span>基地名称：</span><span>{{ detailData.basename }}</span>
          </p>
          <p>
            <span>所在地址：</span><span>{{ detailData.address }}</span>
          </p>
          <p>
            <span>占地面积：</span><span> {{ detailData.baseArea }}亩</span>
          </p>
        </div>
      </div>
      <!-- 实时监控 left3 -->
      <div class="left3 leftBox">
        <div class="title">
          <img src="../../public/images/live-title.png" alt="" />
          <div class="selsctVideo">
            <input type="text" v-model="value1" @click="videoInputClick" />
            <div class="iconBox" @click="videoInputClick">
              <i class="el-icon-caret-bottom" ref="iBox"></i>
            </div>
            <div class="inputSelect" v-if="videoListShow">
              <!-- 点击列表内容 -->
              <div
                class="list"
                v-for="(item, index) in options1"
                :key="index"
                @click="videoClick(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="liveShell" ref="videoShell">
          <div class="liveBox" id="playerBox" ref="video">
            <div class="cancel" v-show="popupShow" @click="closeClick">
              <i class="el-icon-circle-close"></i>
            </div>

            <!-- 视频组件 -->
            <!-- <videoFZ :videoIndex='videoIndex'/>   -->

            <!-- 清橙云视频组件 -->
            <div id="app">
              <div class="video">
                <div id="player" @click="monitoringClick(curItem)"></div>
                <!-- <new-video
                v-if="curIndex == 0"
                class="newVideo"
                :videoUrl="item.url"
                :activeIndex="item.id"
              ></new-video> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="middle">
      <!-- 设备小组件 在线or不在线 middle1-->
      <div class="middle1 middleBox">
        <!-- 监控相关 -->
        <div class="equipment">
          <div class="equipmentData1">
            <img src="../../public/images/yztIcon1.png" alt="" />
            <div class="text">
              <p>监控</p>
              <p>{{ viewData.monitor.length }}</p>
            </div>
          </div>
          <div class="equipmentData2">
            <div class="onLine line">
              <span class="circle" style="background: #34d160"></span
              ><span>在线</span
              ><span style="color: #34d160">{{ viewData.monitor.length }}</span>
            </div>
            <div class="offLine line">
              <span class="circle"></span><span>离线</span><span>0</span>
            </div>
          </div>
        </div>
        <!-- 气象站相关 -->
        <div class="equipment">
          <div class="equipmentData1">
            <img src="../../public/images/yztIcon2.png" alt="" />
            <div class="text">
              <p>气象站</p>
              <p>{{ viewData.weather.length }}</p>
            </div>
          </div>
          <div class="equipmentData2">
            <div class="onLine line">
              <span class="circle" style="background: #34d160"></span
              ><span>在线</span
              ><span style="color: #34d160">{{ viewData.weather.length }}</span>
            </div>
            <div class="offLine line">
              <span class="circle"></span><span>离线</span><span>0</span>
            </div>
          </div>
        </div>
        <!-- 传感器相关 -->
        <div class="equipment">
          <div class="equipmentData1">
            <img src="../../public/images/yztIcon4.png" alt="" />
            <div class="text">
              <p>传感器</p>
              <p>{{ viewData.vibe.length }}</p>
            </div>
          </div>
          <div class="equipmentData2">
            <div class="onLine line">
              <span class="circle" style="background: #34d160"></span
              ><span>在线</span
              ><span style="color: #34d160">{{ viewData.vibe.length }}</span>
            </div>
            <div class="offLine line">
              <span class="circle"></span><span>离线</span><span>0</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 中间图片or开关 middle2 改定位==========================-->
      <div class="middle2 middleBox">
        <div class="middle2Box">
          <div class="monitoring">
            <div
              v-for="(item, index) in options1"
              :key="index"
              :class="'monitoringIcon' + userId + index"
              class="monitoringIcon"
            >
              <div class="icon" @click="monitoringClick(item)">
                <div class="iconShell">
                  <p>{{ item.name }}</p>
                  <div class="icon-img"></div>
                </div>
              </div>
              <div class="guang"></div>
            </div>
            <!-- 监控1 -->
            <!-- 
            <div class="monitoringIcon monitoringIcon2">
              <div class="icon" @click="monitoringClick(1)">
                <div class="iconShell">
                  <p>监控2</p>
                  <div class="icon-img"></div>
                </div>
              </div>
              <div class="guang"></div>
            </div>
            

            <div class="monitoringIcon monitoringIcon3">
              <div class="icon" @click="monitoringClick(2)">
                <div class="iconShell">
                  <p>监控3</p>
                  <div class="icon-img"></div>
                </div>
              </div>
              <div class="guang"></div>
            </div>
             -->

            <!-- <div class="monitoringIcon monitoringIcon4 sensor">
              <div class="icon" @click="iconClick(1)">
                <div class="iconShell">
                  <p>传感器1</p>
                  <div class="icon-img"></div>
                </div>
              </div>
              <div class="guang"></div>
              <popUpBox :sensorScreenData="sensorScreenData" :sensorScreenName="sensorScreenName" @popUpNum="popUpNum" v-show="popUpBoxshow == 1"></popUpBox>
            </div> -->

            <!-- ********* 要改的地方********************** -->
            <!-- <div class="monitoringIcon monitoringIcon8 weather">    
               <div class="icon" @click="weatherClick(5)">  
                <div class="iconShell">
                <p>气象站</p>
                <div class="icon-img"></div>
                </div>
               </div>
              <div class="guang"> </div>
                
              <popUpBox 
              :sensorScreenData='sensorScreenData'
              :sensorScreenName='sensorScreenName' 
               @popUpNum='popUpNum'
               v-show="popUpBoxshow==5"></popUpBox>
                
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="right">
      <!-- 网关选择 right1 -->
      <div class="right1 rightBox">
        <div class="inputBox">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.index"
              :label="item.label"
              :value="item.index"
            >
            </el-option>
          </el-select>
        </div>
        <div class="dataBox">
          <div
            class="min-data"
            v-for="(item, index) in plantingData"
            :key="index"
          >
            <img :src="item.img" alt="" />
            <div class="text">
              <p>{{ item.pr_name }}</p>
              <p>{{ item.pr_value }}{{ item.pr_unit }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 预警提醒 right2 -->
      <div class="right2 rightBox">
        <div class="title">
          <!-- <img src="../../public/images/waring-title.png" alt=""> -->
          <div class="tab">
            <div
              class="tab1"
              :class="{ active: tabIndex == 0 }"
              @click="warningClick(0)"
            >
              传感器预警提醒
            </div>
            <div class="line"></div>
            <div
              class="tab2"
              :class="{ active: tabIndex == 1 }"
              @click="warningClick(1)"
            >
              天气重要预警
            </div>
          </div>
          <div class="zsLine">
            <div class="min-line"></div>
            <div class="min-line"></div>
          </div>
        </div>
        <!-- 传感器预警提醒内容 -->
        <div class="content" v-if="tabIndex == 0">
          <div class="shell" v-if="earlyWarningData.length > 0">
            <vue-seamless-scroll
              :data="earlyWarningData"
              :class-option="optionSingleHeightTime"
              class="seamless-warp"
            >
              <div
                class="list"
                v-for="(item, index) in earlyWarningData"
                :key="index"
              >
                <div class="icon">
                  <img
                    src="../../public/images/max.png"
                    alt=""
                    v-if="item.type == 'max'"
                  />
                  <img src="../../public/images/min.png" alt="" v-else />
                  <div class="cartoonRound"></div>
                </div>
                <div class="data">
                  <p>{{ item.dname }}:{{ item.mess }}</p>
                  <p>{{ item.actiontime }}</p>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
        <!-- 天气重要预警 数据没了-->
        <div class="content" v-else>
          <div class="shell" v-if="weatherData">
            <div class="test"></div>
            <vue-seamless-scroll
              :data="weatherData"
              :class-option="optionSingleHeightTime"
              class="seamless-warp"
            >
              <div
                class="list"
                v-for="(item, index) in weatherData"
                :key="index"
                @click="weatherClick(item)"
              >
                <div class="icon">
                  <img src="../../public/images/max.png" alt="" />
                  <div class="cartoonRound"></div>
                </div>
                <div class="data">
                  <p>
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>
    <!-- 这个是监控的弹窗 -->
    <div class="pop-up" v-show="popupWeatherShow">
      <div class="popoutBox">
        <div class="cancelBox" @click="closeWeatherClick">
          <i class="el-icon-circle-close"></i>
        </div>
        <div class="monitoringContent">
          <div
            class="title"
            style="color: #fff; font-size: 30px; font-weight: 660"
          >
            {{ popupWeatherData.alarm_title }}
          </div>
          <div class="time" style="color: #fff">
            级别：{{ popupWeatherData.alarm_level }} &nbsp;&nbsp;&nbsp;类型：{{
              popupWeatherData.alarm_type
            }}
          </div>
          <div class="content" style="color: #fff">
            {{ popupWeatherData.alarm_content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 视频播放页面
import videojs from "video.js";
import {
  screenDetail,
  screenSearch,
  wsBind,
  screenDevice,
  getWeather
} from "../api/api";
import videoFZ from "../../src/components/greenhouse/video1.vue";
import videoDP from "../../src/components/greenhouse/video.vue";
import popUpBox from "./popUpBox.vue";
export default {
  inject: ["reload"],
  components: { videoFZ, videoDP, popUpBox },
  watch: {
    value(val, oldval) {
      let firstData = this.options[val].sensordata;
      this.plantingData = [];
      if (!this.options[val].type) {
        for (let index of Object.keys(firstData)) {
          if (index != "addDate" && index != "sensorId") {
            let data = {
              img: "",
              pr_name: "",
              pr_value: firstData[index],
              pr_unit: ""
            };
            let getName = this.transitionName(index);
            data.img = this.imgJudgment(getName.name);
            data.pr_name = getName.name;
            data.pr_unit = getName.unit;
            this.plantingData.push(data);
          }
        }
      } else {
        firstData.forEach((item) => {
          this.attributeTable.map((item1) => {
            if (item.code == item1.type) {
              item.pr_name = item1.name;
              item.pr_unit = item1.unit;
              item.pr_value = item.value;
              item.img = this.imgJudgment(item1.name);
              this.plantingData.push(item);
            }
          });
        });
      }
    },
    videoListShow(val, oldval) {
      //video右上角选择 icon图标的旋转
      if (val) {
        this.$refs.iBox.style.transform = "rotate(-180deg)";
      } else {
        this.$refs.iBox.style.transform = "rotate(0deg)";
      }
    }
  },

  computed: {
    optionSingleHeightTime() {
      return {
        step: 0.5 // 数值越大速度滚动越快
      };
    }
  },
  data() {
    return {
      myplayer: null,
      weatherData: [], //天气数据
      tabIndex: 0, //tab选项卡选择
      popupWeatherShow: false,
      popupWeatherData: {}, //天气弹窗数据
      popupShow: false,
      videoIndex: 0, //视频索引
      popUpBoxshow: 0, //浮动 弹出框相关
      loading: true,
      controllerData1: 0, //1号控制器  卷帘
      controllerData2: 0, //2号控制器  风机
      controllerData3: 0, //3号控制器  正反转
      controllerValue4: true, //4号控制器  电磁阀
      controllerValue5: true, //5号控制器  增氧机
      controllerValue6: true, //6号控制器  抽水泵options
      userId: null, //用户id
      attributeTable: [
        //属性表 (后端那边要这样的了🤦‍♀️)
        {
          type: "a34004",
          name: "PM2.5",
          unit: "ug/m3",
          value: 0
        },
        {
          type: "a34002",
          name: "PM10",
          unit: "ug/m3",
          value: 0
        },
        {
          type: "a01006",
          name: "大气压",
          unit: "KPa",
          value: 0
        },
        {
          type: "a03001",
          name: "降雨量",
          unit: "mm/min",
          value: 0
        },
        {
          type: "a01007",
          name: "风速",
          unit: "米/秒",
          value: 0
        },
        {
          type: "a01008",
          name: "风向",
          unit: "[角]度",
          value: 0
        },
        {
          type: "a01001",
          name: "温度",
          unit: "℃",
          value: 0
        },
        {
          type: "a01002",
          name: "温度",
          unit: "%RH",
          value: 0
        },
        {
          type: "a03003",
          name: "光照度",
          unit: "Lux",
          value: 0
        }
      ],
      options: [], //传感器网关数据
      value: 0,
      singlePlayer: null, //视频流
      flagFive: false,
      options1: [], //这个是select选项 视频的
      value1: "暂无视频", //视频右上角的select的value
      videoListShow: false,
      sensorData: [], //这个是传感器数据
      sensorScreenData: [], //这个是传感器屏幕数据
      sensorScreenName: {}, //这个是传感器信息
      detailData: {}, //用于存放大棚简介数据
      detail: "",
      earlyWarningData: [
        {
          type: "max",
          dname: "传感器03",
          mess: "空气温度已经达到了最高值，请及时处理",
          actiontime: "2022/1/4"
        },
        {
          type: "max",
          dname: "传感器03",
          mess: "空气温度已经达到了最高值，请及时处理",
          actiontime: "2022/1/4"
        },
        {
          type: "max",
          dname: "传感器03",
          mess: "空气温度已经达到了最高值，请及时处理",
          actiontime: "2022/1/4"
        },
        {
          type: "max",
          dname: "传感器03",
          mess: "空气温度已经达到了最高值，请及时处理",
          actiontime: "2022/1/4"
        },
        {
          type: "max",
          dname: "传感器03",
          mess: "空气温度已经达到了最高值，请及时处理",
          actiontime: "2022/1/4"
        }
      ], //预警data
      weatherWarningData: [
        {
          type: "max",
          dname: "天气预警",
          mess: "明天12：00讲出现极端性气候，请注意防范",
          actiontime: "2022/1/4"
        }
      ], //天气预警
      seachData: [], //搜索data
      plantingData: [], //种植数据
      viewData: {},
      //存储当前的item对象
      curItem: {}
    };
  },
  //数据初始化
  created() {
    this.userId = this.$route.query.id;
    this.getScreenDetail(); // 获取当前页面数据
    this.getscreenDevice(); // 获取网关数据
    this.getscreenVideo(); // 获取视频数据
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.createVideo()
    // })

    // 获取天气预警数据
    getWeather().then((res) => {
      console.log("天气报警数据=======", res.data);
      this.weatherData = res.data;
    });
  },
  methods: {
    // 清橙云视频组件 视频组件========
    createVideo(e) {
      const player = new ImouPlayer("#player");
      player.setup({
        // 播放器初始化
        src: [
          {
            url: e.url,
            kitToken: e.token
          }
        ], // 播放地址
        poster: "", // 封面图url
        width: "100%",
        height: "100%",
        autoplay: true,
        controls: false // 是否展示控制栏
      });
      player.play(); // 播放
    },

    // 关闭天气详情的点击事件
    closeWeatherClick() {
      this.popupWeatherShow = false;
    },
    weatherClick(data) {
      this.popupWeatherData = data.alarm;
      this.popupWeatherShow = true;
    },
    //预警click
    warningClick(e) {
      this.tabIndex = e;
    },
    // 传感器数据弹出框的取消事件
    popUpNum(e) {
      this.popUpBoxshow = e;
    },
    // 图片本地路径的转换
    imgJudgment(data) {
      if (data == "土壤温度") {
        return require("../../public/images/sensor1.png");
      } else if (data == "土壤水分") {
        return require("../../public/images/sensor2.png");
      } else if (data == "光照强度") {
        return require("../../public/images/sensor3.png");
      } else if (data == "二氧化碳") {
        return require("../../public/images/sensor4.png");
      } else if (data == "空气湿度") {
        return require("../../public/images/sensor5.png");
      } else if (data == "空气温度") {
        return require("../../public/images/sensor6.png");
      } else if (data == "风向") {
        return require("../../public/images/sensor19.png");
      } else if (data == "风速") {
        return require("../../public/images/sensor20.png");
      } else if (data == "蒸发量") {
        return require("../../public/images/sensor21.png");
      } else if (data == "降雨量") {
        return require("../../public/images/sensor22.png");
      } else if (data == "大气压力") {
        return require("../../public/images/sensor23.png");
      } else if (data == "土壤含钾量") {
        return require("../../public/images/sensor10.png");
      } else if (data == "土壤含磷量") {
        return require("../../public/images/sensor11.png");
      } else if (data == "土壤含氮量") {
        return require("../../public/images/sensor12.png");
      } else if (data == "PH(土)") {
        return require("../../public/images/sensor13.png");
      } else if (data == "液位") {
        return require("../../public/images/sensor14.png");
      } else if (data == "PH(水)") {
        return require("../../public/images/sensor15.png");
      } else if (data == "水溶氧") {
        return require("../../public/images/sensor16.png");
      } else if (data == "水温度") {
        return require("../../public/images/sensor17.png");
      } else if (data == "土壤电导率") {
        return require("../../public/images/sensor18.png");
      } else {
        return require("../../public/images/sensor18.png");
      }
    },
    // 气象站点击事件=========================
    weatherClick(data) {
      this.popUpBoxshow = data;
      let obj = {
        id: this.userId,
        type: "devEnvEqv"
      };
      screenDevice(obj).then((res) => {
        console.log("数据接口", res);
        let data = res.data.detail;
        let meteorologicalStation = [];
        //遍历数据
        data.forEach((item) => {
          this.attributeTable.map((item1) => {
            if (item.code == item1.type) {
              // item.property=item1
              item.pr_name = item1.name;
              item.pr_unit = item1.unit;
              item.pr_value = item.value;
              item.img = this.imgJudgment(item1.name);
              meteorologicalStation.push(item);
            }
          });
        });
        let obj1 = {
          //这边配合小组件需要的格式 创造的对象
          label: "气象站"
        };
        let Obj2 = {
          //这边配合小组件需要的格式 创造的对象
          data: meteorologicalStation
        };

        this.sensorScreenName = obj1;
        this.sensorScreenData = Obj2;
        // console.log(meteorologicalStation,"meteorologicalStation");
      });
    },
    // 传感器点击  传感器1有bug
    iconClick(data) {
      console.log("传感器1传过来1", data);
      this.popUpBoxshow = data;
      this.sensorScreenName = this.options[data - 1];
      // console.log(this.options[data-1],"this.options[data-1]");
      console.log("这个按道理是数组", this.sensorData[data - 1].data);
      this.sensorData[data - 1].data.forEach((item) => {
        item.img = this.imgJudgment(item.pr_name);
      });

      this.sensorScreenData = this.sensorData[data - 1];
      //  console.log(this.sensorScreenData,"this.sensorScreenData");
    },
    //视频弹出框的关闭按钮
    closeClick() {
      this.popupShow = false;
      this.reload();
    },
    //middle2 中的监控视频点击事件
    monitoringClick(data) {
      this.videoIndex = data;
      this.popupShow = true;
      const videoShell = document.querySelector(".liveShell");
      const liveBox = document.querySelector(".liveBox");
      videoShell.style.position = "fixed";
      videoShell.style.top = 0;
      videoShell.style.bottom = 0;
      videoShell.style.left = 0;
      videoShell.style.right = 0;
      //  videoShell.style.border='1px solid red'
      videoShell.style.zIndex = 888;
      videoShell.style.display = "flex";
      videoShell.style.justifyContent = "center";
      videoShell.style.background = "rgba(0, 0, 0, 0.7)";
      videoShell.style.alignItems = "center";
      // videoShell.style.border="1px solid red"
      liveBox.style.width = "900px";
      liveBox.style.height = "650px";
      liveBox.style.padding = "20px";
      //  liveBox.style.border='1px solid red'
      const player = new ImouPlayer("#player");
      player.destroy(); //销毁播放器
      this.createVideo(data);
    },
    //video 右上角的select input点击事件
    videoInputClick() {
      this.videoListShow = !this.videoListShow;
    },
    //video 右上角的select 选择器的点击事件
    videoClick(data) {
      this.curItem = data;
      this.videoIndex = data.value;
      this.value1 = data.name;
      this.videoListShow = false;
      const player = new ImouPlayer("#player");
      player.destroy(); //销毁播放器
      this.createVideo(data);
    },
    // 获取视频数据
    getscreenVideo() {
      let obj = {
        id: this.userId,
        type: "devVdo"
      };
      screenDevice(obj).then((res) => {
        console.log(res.data, "res.data123123123");
        this.options1 = res.data.dev_vdo_info;
        if (this.options1.length) {
          this.videoClick(this.options1[0]);
        }
      });
    },
    // 获取网关数据
    getscreenDevice() {
      let obj = {
        id: this.userId,
        type: "devGateway"
      };
      //调用网关数据接口 暂时没有数据
      screenDevice(obj).then((res) => {
        console.log(res, "res333333333333333333");
        this.sensorData = res.data.dev_env_info;
        //  传感器设备的数量统计
        if (res.data.dev_env_info) {
          let arr = res.data.dev_env_info;
          let nameArr = [];
          arr.forEach((item, index) => {
            let optionObj = {
              index: "",
              label: "",
              sensordata: ""
            };
            optionObj.index = index;
            optionObj.label = item.name;
            optionObj.sensordata = item.data;
            optionObj.type = item.type || "";
            nameArr.push(optionObj);
          });
          console.log(nameArr, "nameArr");
          this.options = nameArr;
          let firstData = this.options[0].sensordata;
          this.plantingData = [];
          for (let index of Object.keys(firstData)) {
            if (index != "addDate" && index != "sensorId") {
              let data = {
                img: "",
                pr_name: "",
                pr_value: firstData[index],
                pr_unit: ""
              };
              let getName = this.transitionName(index);
              data.img = this.imgJudgment(getName.name);
              data.pr_name = getName.name;
              data.pr_unit = getName.unit;
              this.plantingData.push(data);
            }
          }

          console.log(this.plantingData, "this.plantingData");

          // //数据没了
          // this.plantingData.forEach(item => {
          //   item.img = this.imgJudgment(item.pr_name)
          // })
        }
        // 传感器设备的数量统计
      });
    },

    // 网关字段转换成 name
    transitionName(val) {
      let data = {
        name: "",
        unit: ""
      };
      switch (val) {
        case "ec":
          data.name = "EC值";
          data.unit = "mS/cm";
          break;
        case "moisture":
          data.name = "土壤湿度";
          data.unit = "%";
          break;
        case "temperature":
          data.name = "土壤温度";
          data.unit = "℃";
          break;
        case "light":
          data.name = "光照值";
          data.unit = "lux";
          break;
        case "air_moisture":
          data.name = "空气湿度";
          data.unit = "RH";
          break;
        case "air_temperature":
          data.name = "空气温度";
          data.unit = "℃";
          break;
        default:
          console.log("不满足上述所有case时, 执行默认");
      }
      return data;
    },

    // 获取当前页面数据
    getScreenDetail() {
      console.log("测试一下================");
      let obj = {
        id: this.userId
      };
      // 页面数据接口
      screenDetail(obj).then((res) => {
        console.log("大棚简介数据======", res);
        this.viewData = res.data;
        // 用户信息获取
        let userObj = {
          username: "", //大棚所有人
          basename: "", //基地名称
          address: "", //所在地址
          baseArea: "", //占地面积
          onLineMonitoring: 0, //在线监控数量
          offlineMonitoring: 0, //离线监控数量
          allMonitoring: 0, //总监控数量
          onLineSensor: 0, //在线传感器数量
          offLineSensor: 0, //离线传感器数量
          allLineSensor: 0, //传感器总数量
          middleImage: "" //图片地址
        };
        userObj.username = res.data.user.user_name;
        userObj.basename = res.data.base_name;
        userObj.address = res.data.address;
        userObj.baseArea = res.data.base_area;
        this.detailData = userObj;
        // 用户信息获取
        // 预警提醒
        this.earlyWarningData = this.earlyWarningData; //测试使用
        //  预警提醒
        this.loading = false;
      });
    },
    //跳转到上一个页面
    getBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1560px) {
  .left2 {
    padding: 10px 25px !important;
    .text {
      p {
        // border: 1px solid red;
        padding: 5px 0 !important;
        span:nth-child(1) {
          width: 35% !important;
        }
      }
    }
  }
  .left3 {
    padding: 10px 25px !important;
  }
  .right1 {
    padding: 21px 25px !important;
  }
  .right2 {
    padding: 10px 25px !important;
    .content {
      .list {
        padding: 10px 0px !important;
      }
    }
  }
  .middle {
    .equipment {
      width: 49% !important;
    }
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.gisMapShell {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  // border: 1px solid red;
  padding: 20px 30px;
  background: url("../../public/images/map-bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  .left {
    width: 20%;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .leftBox {
      width: 100%;
      // border: 1px solid red;
    }
    .left1 {
      height: 13%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      position: relative;
      img {
        user-select: none;
        cursor: pointer;
        width: 100%;
        height: 80%;
      }
      .returnImg {
        user-select: none;
        cursor: pointer;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 120px;
        height: 70px;
        // border: 1px solid red;
        background: url("../../public/images/returnText.png") no-repeat center
          center;
        background-size: 100%;
      }
    }
    .left2 {
      height: 38%;
      background: url("../../public/images/border1.png") no-repeat;
      background-size: 100% 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      .title {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        img {
          width: 70%;
        }
      }
      .text {
        flex: 1;
        // border: 1px solid red;
        p {
          display: flex;
          padding: 10px 0;
          // border: 1px solid red;
          text-align: left;
          span:nth-child(1) {
            width: 30%;
            box-sizing: border-box;
            color: #7fb4f6;
            line-height: 18px;
          }
          span:nth-child(2) {
            box-sizing: border-box;
            display: inline-block;
            color: #fff;
            width: 70%;
            // border: 1px solid red;
            line-height: 18px;
          }
        }
      }
    }
    .left3 {
      height: 38vh;
      //  border: 1px solid red;
      background: url("../../public/images/border1.png") no-repeat;
      background-size: 100% 100%;
      padding: 3vh;
      display: flex;
      flex-direction: column;
      // border: 1px solid red;
      .title {
        width: 100%;
        height: 5vh;
        // border: 1px solid red;
        display: flex;
        align-items: center;
        .selsctVideo {
          width: 100px;
          height: 30px;
          // border: 1px solid red;
          position: relative;
          input {
            width: 100%;
            height: 100%;
            padding: 0 10px;
            user-select: none;
            cursor: pointer;
            background: rgba(255, 255, 255, 0);
            border: 1px solid rgb(35, 130, 232);
            color: rgba(255, 255, 255, 0.739);
            border-radius: 5px;
          }
          .iconBox {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            // border: 1px solid red;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgb(35, 130, 232);
          }
          .inputSelect {
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 100px;
            // border: 1px solid red;
            background: rgba(255, 255, 255, 0.484);
            border-radius: 0px 0px 5px 5px;
            overflow: auto;

            .list {
              width: 100%;
              height: 33.3%;
              // border: 1px solid red;
              display: flex;
              justify-content: center;
              align-items: center;
              user-select: none;
              cursor: pointer;
            }
            .list:hover {
              background: #08245570;
              color: #fff;
            }
          }
          .inputSelect::-webkit-scrollbar {
            display: none;
          }
        }
        img {
          width: 70%;
        }
      }
      .liveShell {
        width: 100%;
        height: 100%;
        border: 5px solid #082455c8;
        display: flex;
        align-items: center;
        background: #000;
        .liveBox {
          width: 100%;
          height: 100%;
          position: relative;
          background: url("../../public/images/popupImg.png") no-repeat;
          background-size: 100% 100%;
          .cancel {
            position: absolute;
            top: -30px;
            right: -30px;
            font-size: 30px;
            color: #fff;
            width: 30px;
            height: 30px;
            user-select: none;
            cursor: pointer;
          }
          #app {
            height: 100%;
            .video {
              height: 100%;
              #player {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .middle {
    width: 58%;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .middleBox {
      width: 100%;
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      .equipment {
        height: 100%;
        width: 45%;
        // border: 1px solid red;
        background: url(../../public/images/border2.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .middle1 {
      height: 12vh;
      .equipment {
        width: 24%;
        height: 100%;
        // border: 1px solid red;
        background: url("../../public/images/min-bg4-1.png") no-repeat;
        background-size: 100% 100%;
        box-sizing: content-box;
        .equipmentData1 {
          width: 100%;
          height: 50%;
          // border: 1px solid red;
          margin-top: 5%;
          padding: 0 15%;
          display: flex;
          align-items: center;
          img {
            // width: 40px;
            height: 40px;
            // border: 1px solid red;
          }
          .text {
            // border: 1px solid red;
            margin-left: 10px;
            p:nth-child(1) {
              font-size: 12px;
              color: #fff;
            }
            p:nth-child(2) {
              font-size: 18px;
              color: #00eaff;
              font-weight: 550;
            }
          }
        }
        .equipmentData2 {
          margin-top: 5%;
          width: 100%;
          height: 20%;
          // border: 1px solid red;
          padding: 0 15%;
          display: flex;
          justify-content: space-between;
          .line {
            width: 46%;
            // border: 1px solid red;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            .circle {
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              // border: 1px solid red;
              background: red;
            }
          }
        }
      }
      .equipment:first-child {
        margin-left: 5px;
      }
      .equipment:last-child {
        margin-right: 5px;
      }
    }
    .middle2 {
      height: 85%;
      // border: 1px solid red;
      background: url("../../public/images/min-bg4-2.png") no-repeat;
      background-size: 100% 100%;
      padding: 25px;
      display: flex;
      align-items: center;
      .middle2Box {
        position: relative;
        width: 100%;
        height: 100%;
        // border: 1px solid red;
        background: url("../../public/images/g31.png") no-repeat center center;
        background-size: 90%;
        .monitoring {
          // border: 1px solid red;
          height: 65%;
          margin-top: 10%;
          position: relative;
          .monitoringIcon {
            width: 55px;
            height: 130px;
            // border: 1px solid red;
            position: absolute;
            div {
              height: 50%;
              width: 100%;
              border: 1px solid rgba(255, 0, 0, 0);
            }
            .icon {
              .iconShell {
                animation: minIcon 2s linear infinite;
                color: #fff;
                cursor: pointer;
                user-select: none;
              }
              .icon-img {
                height: 60px;
                background: url("../../public/images/jiankonIcon.png") no-repeat;
                background-size: 100% 100%;
              }
            }
            .guang {
              background: url("../../public/images/fanweiIcon.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          .weather {
            .icon {
              // border: 1px solid red;
              .icon-img {
                height: 60px;
                background: url("../../public/images/jiankonIcon1.png")
                  no-repeat;
                background-size: 100% 100%;
              }
            }

            .guang {
              background: url("../../public/images/fanweiIcon1.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          .sensor,
          .weather {
            .sensingDetails {
              position: absolute;
              top: 0;
              left: 60px;
              z-index: 999;
              width: 300px;
              height: 400px;
              // border: 1px solid red;
              background: rgba(16, 24, 64, 0.9);
              padding: 10px;
              display: flex;
              flex-direction: column;
              .sensingTitle {
                height: 25px;
                width: 100%;
                // border: 1px solid red;
                display: flex;
                align-items: center;
                .sensingText {
                  height: 100%;
                  // border: 1px solid blue;
                  display: flex;
                  .block1 {
                    width: 1px;
                    height: 100%;
                    background: #1effff;
                  }
                  .text1 {
                    height: 100%;
                    color: #fff;
                    font-size: 15px;
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                  }
                }
                i {
                  // border: 1px solid red;
                  // position: absolute;
                  // right: 0;
                  color: rgba(255, 255, 255, 0.62);
                  font-size: 22px;
                  user-select: none;
                  cursor: pointer;
                }
                i:hover {
                  color: rgba(255, 255, 255, 0.8);
                }
              }
              .line {
                height: 2.5px;
                width: 100%;
                background: url("../../public/images/line11.png") no-repeat;
                background-size: 100%;
                margin: 10px 0;
              }
              .sensingContent {
                flex: 1;
                width: 100%;
                // border: 1px solid red;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: flex-start;
                overflow: auto;
                .list {
                  width: 50%;
                  // border: 1px solid red;
                  height: 80px;
                  display: flex;
                  .listLeft {
                    // border: 1px solid red;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    img {
                      // display:inline-block;
                      width: 50px;
                      height: 50px;
                    }
                  }
                  .listRight {
                    // border: 1px solid red;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    p {
                      margin: 2px 0;
                    }
                    p:nth-child(1) {
                      font-size: 20px;
                      font-weight: 650;
                      color: #1effff;
                      span {
                        font-size: 12px;
                      }
                    }
                    p:nth-child(2) {
                      color: rgb(146, 220, 255);
                    }
                  }
                }
              }
              .sensingContent::-webkit-scrollbar {
                display: none;
              }
            }
          }
          .sensor {
            .icon {
              // border: 1px solid red;
              .icon-img {
                height: 60px;
                background: url("../../public/images/jiankonIcon2.png")
                  no-repeat;
                background-size: 100% 100%;
              }
            }

            .guang {
              background: url("../../public/images/fanweiIcon2.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          .monitoringIcon120 {
            top: 2%;
            left: 0%;
          }
          .monitoringIcon121 {
            top: 59%;
            left: 11%;
          }
          .monitoringIcon122 {
            top: 5%;
            left: 5%;
          }
          .monitoringIcon123 {
            top: 100%;
            left: 34%;
          }
          // 传感
          .monitoringIcon124 {
            top: 89%;
            left: 2%;
            position: relative;
          }
          .monitoringIcon125 {
            top: 20%;
            left: 49%;
          }
          .monitoringIcon126 {
            top: 0%;
            left: 38%;
          }
          .monitoringIcon127 {
            top: 60%;
            left: 2%;
          }
          // 气象站
          .monitoringIcon128 {
            top: 89%;
            left: 11%;
          }
          .monitoringIcon129 {
            top: 46%;
            left: 63%;
          }
          .monitoringIcon1210 {
            top: 29%;
            left: 87%;
          }
          .monitoringIcon1211 {
            top: 28%;
            left: 53%;
          }
          .monitoringIcon1212 {
            top: 63%;
            left: 33%;
          }
          .monitoringIcon1213 {
            top: 14%;
            left: 74%;
          }
          .monitoringIcon1214 {
            top: 44%;
            left: 23%;
          }
          .monitoringIcon1215 {
            top: 4%;
            left: 69%;
          }
          .monitoringIcon1216 {
            top: -12%;
            left: 59%;
          }
          .monitoringIcon1217 {
            top: 17%;
            left: 10%;
          }
          .monitoringIcon1218 {
            top: 37%;
            left: 18%;
          }
          .monitoringIcon110 {
            top: -13%;
            left: 59%;
          }
          .monitoringIcon111 {
            top: 18%;
            left: 8%;
          }
          .monitoringIcon112 {
            top: 65%;
            left: 34%;
          }
          .monitoringIcon113 {
            top: 30%;
            left: 87%;
          }
          .monitoringIcon130 {
            top: 18%;
            left: 8%;
          }
          .monitoringIcon131 {
            top: -13%;
            left: 59%;
          }
          .monitoringIcon132 {
            top: 65%;
            left: 34%;
          }
          .monitoringIcon133 {
            top: 30%;
            left: 87%;
          }

          .monitoringIcon140 {
            top: 18%;
            left: 8%;
          }
          .monitoringIcon141 {
            top: 30%;
            left: 87%;
          }
          .monitoringIcon142 {
            top: 65%;
            left: 34%;
          }
          .monitoringIcon143 {
            top: -13%;
            left: 59%;
          }

          // 这个是弹窗
          .popupBox {
            position: fixed;
            inset: 0;
            background: rgba(0, 0, 0, 0.665);
            // border: 1px solid red;
            z-index: 999;

            .popupContent {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 900px;
              height: 650px;
              // border: 1px solid rgb(0, 255, 60);
              background: url("../../public/images/popupImg.png") no-repeat;
              background-size: 100% 100%;
              padding: 2% 3%;
              .cancelBox {
                // border: 1px solid red;
                width: 30px;
                height: 30px;
                position: absolute;
                right: -30px;
                top: -30px;
                i {
                  font-size: 30px;
                  color: rgba(255, 255, 255, 0.6);
                  cursor: pointer;
                  user-select: none;
                }
                i:hover {
                  color: rgba(255, 255, 255, 0.9);
                }
              }
              .articleTitle {
                width: 100%;
                height: 20%;
                // border: 1px solid red;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                border-bottom: 1px solid #025f96;
                position: relative;
                p:nth-child(1) {
                  font-size: 20px;
                  color: #fff;
                }
                p:nth-child(2) {
                  font-size: 12px;
                  color: #8190b8;
                }
                .line {
                  position: absolute;
                  bottom: -1px;
                  width: 100px;
                  height: 1px;
                  background: #2fdaff;
                }
                .line1 {
                  left: 0;
                }
                .line2 {
                  right: 0;
                }
              }
              /deep/.articleContent {
                padding: 2% 0;
                width: 100%;
                height: 80%;
                // border: 1px solid red;
                color: #fff;
                line-height: 25px;
                overflow: auto;
                margin-top: 10px;
                img {
                  width: 400px;
                }
                p {
                  text-align: left;
                  margin-bottom: 1%;
                }
              }
              /*滚动条样式*/
              .articleContent::-webkit-scrollbar {
                width: 5px;
              }
              .articleContent::-webkit-scrollbar-thumb {
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #03e1dc;
              }
              .articleContent::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                background: #002969;
              }
            }
          }
        }
        .controller {
          position: absolute;
          bottom: 3%;
          color: #fff;
          width: 100%;
          height: 17%;
          // border: 1px solid red;
          .shell {
            width: 90%;
            height: 100%;
            // border: 1px solid red;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .directionShell {
              width: 100%;
              height: 45%;
              // border: 1px solid red;
              display: flex;
              justify-content: space-between;
              .controlButton {
                width: 30%;
                height: 100%;
                // border: 1px solid red;
                background: url("../../public/images/greenhouse2.png") no-repeat;
                background-size: 100% 100%;
                padding: 0.8% 2%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .name {
                  width: 30%;
                  // height: 100%;
                  // border: 1px solid red;
                }
                .button {
                  width: 48%;
                  height: 100%;
                  //  border: 1px solid red;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .round {
                    width: 30px;
                    height: 30px;
                    //  border: 1px solid red;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    user-select: none;
                    cursor: pointer;
                    .mahogany {
                      width: 25px;
                      height: 25px;
                      // background: #e53d58;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }
                    .active {
                      background: #e53d58;
                    }
                  }
                }
              }
            }
            .directionShell:nth-child(1) {
              .button {
                background: url("../../public/images/greenhouse3.png") no-repeat
                  center center;
                background-size: 100%;
              }
            }
            .directionShell:nth-child(2) {
              .button {
                display: flex;
                justify-content: flex-end;
                /deep/.el-switch__label {
                  color: rgba(255, 255, 255, 0.476);
                }
                /deep/ .is-active {
                  color: #409eff;
                }
              }
            }
          }
        }
      }
    }
  }
  .pop-up {
    position: fixed;
    inset: 0;
    // border: 1px solid red;
    background: rgba(0, 0, 0, 0.516);
    display: flex;
    justify-content: center;
    align-items: center;
    .popoutBox {
      width: 900px;
      height: 650px;
      background: url("../../public/images/popupImg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 2%;
      .cancelBox {
        // border: 1px solid red;
        width: 30px;
        height: 30px;
        position: absolute;
        right: -30px;
        top: -30px;
        i {
          font-size: 30px;
          color: rgba(255, 255, 255, 0.6);
          cursor: pointer;
          user-select: none;
        }
        i:hover {
          color: rgba(255, 255, 255, 0.9);
        }
      }
      .monitoringTitle {
        color: #fff;
        font-size: 20px;
        letter-spacing: 5px;
        font-weight: 550;
      }
      .monitoringContent {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 75%;
        // border: 1px solid red;
        .title {
          margin-bottom: 10px;
        }
        .time {
          margin: 20px 0;
        }
        .content {
          font-size: 18px;
          line-height: 40px;
        }
        .liveBox {
          width: 100%;
          height: 100%;
          // border: 1px solid red;
        }
      }
    }
  }
  @keyframes minIcon {
    // from表示动画的开始位置
    0% {
      margin-top: -4px;
    }
    // to表示动画的结束位置
    50% {
      margin-top: -10px;
    }
    100% {
      margin-top: -4px;
    }
  }
  .right {
    width: 20%;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .rightBox {
      width: 100%;
      // border: 1px solid red;
    }
    .right1 {
      height: 55%;
      background: url(../../public/images/border4.png) no-repeat;
      background-size: 100% 100%;
      padding: 35px 25px;
      display: flex;
      flex-direction: column;
      .inputBox {
        width: 100%;
        height: 50px;
        // border: 1px solid red;
        background: url(../../public/images/picker-back.png) no-repeat;
        background-size: 100% 100%;
        margin-bottom: 10px;
        /deep/.el-select {
          width: 90%;
          margin-top: 5px;
          input {
            background: rgba(255, 255, 255, 0);
            border: 1px solid rgba(255, 255, 255, 0);
            color: #fff;
          }
        }
      }

      .dataBox {
        flex: 1;
        //  border: 1px solid red;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        overflow: scroll;
        .min-data {
          width: 50%;
          height: 100px;
          // border: 1px solid red;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          cursor: pointer;
          border-radius: 5px;
          img {
            //  border: 1px solid red;
            width: 50px;
            height: 50px;
          }
          .text {
            width: 55%;
            margin-left: 5px;
            //  border: 1px solid red;
            p {
              line-height: 20px;
            }
            p:nth-child(1) {
              color: #fff;
              font-size: 14px;
            }
            p:nth-child(2) {
              color: #00fbe8;
              font-size: 14px;
              font-weight: 550;
            }
          }
        }
        .min-data:hover {
          background: rgba(255, 255, 255, 0.16);
        }
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .right2 {
      // border: 1px solid red;
      height: 40%;
      background: url(../../public/images/border1.png) no-repeat;
      background-size: 100% 100%;
      padding: 25px;

      .title {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid red;
        flex-direction: column;

        //  img{
        //   width: 70%;
        // }
        .tab {
          width: 100%;
          height: 100%;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          user-select: none;
          cursor: pointer;
          .active {
            color: #00eaff;
          }
          .line {
            width: 1px;
            height: 20px;
            background: #00eaff;
          }
          .tab1,
          .tab2 {
            width: 49%;
            // border: 1px solid red;
            height: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .zsLine {
          width: 100%;
          height: 1px;
          background: #085573;
          margin: 5px 0;
          position: relative;
          .min-line {
            width: 10%;
            height: 2px;
            background: #00eaff;
            position: absolute;
          }
          .min-line:nth-child(1) {
            left: 0;
          }
          .min-line:nth-child(2) {
            right: 0;
          }
        }
      }
      .content {
        height: 80%;
        overflow: scroll;
        .list {
          height: 30%;
          border-bottom: 1px solid #082455;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          // border: 1px solid red;
          user-select: none;
          cursor: pointer;
          .icon {
            margin-right: 10px;
            // border: 1px solid red;
            position: relative;
            .cartoonRound {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: rgb(251, 193, 193);
              animation-name: yvjing;
              animation-duration: 2s;
              animation-iteration-count: infinite;
              animation-direction: alternate-reverse;
            }
            img {
              width: 40px;
            }
          }
        }
        .list:hover {
          background: rgba(6, 124, 200, 0.3);
        }
        .data {
          margin-left: 5px;
          width: 90%;
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            text-align: left;
            line-height: 17px;
          }
          p:nth-child(1) {
            color: rgb(238, 68, 68);
          }
          p:nth-child(2) {
            color: #7fb4f6;
          }
        }
      }
    }
    @keyframes yvjing {
      // from表示动画的开始位置
      from {
        opacity: 0%;
      }
      // to表示动画的结束位置
      to {
        opacity: 80%;
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>

<template>
  <div class="eCommerce">
    <div class="left">
      <!-- 实时价格 -->
      <div class="left1">
        <div class="title flex1">
          <div class="inner">
            <span>实时价格</span>
            <img src="../../public/images/jiantou6.png" alt="" />
          </div>
          <span class="data">当前日期：{{ currentDay }}</span>
        </div>
        <div class="content">
          <!-- tab选项卡 -->
          <div class="tabVariety">
            <span
              v-for="(item, index) in productType"
              :key="index"
              @click="realTimePriceClick(item, timeHorizon)"
              :class="{ varietyActive: item.id == realTimePriceTab }"
            >
              {{ item.name }}
            </span>
          </div>
          <!-- 小标题最新价格 -->
          <div class="recentQuotation">
            <div>
              近7日最低价（元/斤）<span>{{ minimum }}</span>
            </div>
            <div>
              近7日平均价（元/斤）<span>{{ averagePrice }}</span>
            </div>
          </div>
          <!-- 最新价格展示框 -->
          <div class="priceBox">
            <div class="priceTitle">
              <span>地区</span>
              <span>价格</span>
              <span>升降</span>
            </div>
            <div class="priceShell">
              <div
                class="priceView"
                v-for="(item, index) in regionalPriceList"
                :key="index"
              >
                <span>{{ item.areaName }}</span>
                <span>{{ item.middlePrice }}元/斤</span>
                <div
                  class="trend"
                  :style="{
                    color:
                      item.type == 0
                        ? '#f7c500'
                        : item.type == 1
                        ? '#f96367'
                        : '#57b84e'
                  }"
                >
                  {{ item.range }}元
                  <img
                    v-show="item.type == 0"
                    src="../../public/images/minchiping.png"
                    alt=""
                  />
                  <img
                    v-show="item.type == 1"
                    src="../../public/images/minshangsheng.png"
                    alt=""
                  />
                  <img
                    v-show="item.type == 2"
                    src="../../public/images/minxiajiang.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 线型图 -->
          <div class="linesPlan">
            <div class="selectorButton">
              <div
                class="weekBtn"
                :class="{ linesPlanActive: timeHorizon == 7 }"
                @click="formerlyLast7Days(7)"
              >
                7日
              </div>
              <div
                class="monthBtn"
                :class="{ linesPlanActive: timeHorizon == 30 }"
                @click="formerlyLast7Days(30)"
              >
                30日
              </div>
            </div>
            <div
              ref="chart1"
              id="mychart1"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </div>
      </div>
      <!-- 产品价格走势 -->
      <div class="left2">
        <div class="title">
          <span>产品价格走势</span>
          <img src="../../public/images/jiantou6.png" alt="" />
        </div>
        <div class="content">
          <!-- 价格走势图 -->
          <div class="priceGraph">
            <div
              ref="chart2"
              id="mychart2"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle">
      <!-- 大公鸡 -->
      <div class="middleBox">
        <!-- tab选项卡 -->
        <div class="tab">
          <span
            v-for="(item, index) in regionData"
            :key="index"
            :class="{ active: regionPitchOn == index }"
            @click="tabClick(item, index)"
            >{{ item.province }}</span
          >
        </div>
        <!-- 大公鸡 -->
        <div class="china">
          <div
            class="pitchOn"
            v-for="(item, index) in regionData"
            :key="index"
            @click="tabClick(item, index)"
            :class="{ pitchActive: regionPitchOn == index }"
            :style="{ top: item.top, left: item.left }"
          ></div>
        </div>
        <!-- 表格数据 -->
        <div class="chartBox">
          <div class="selectedRegion">
            <i class="el-icon-location-outline"></i> &nbsp;&nbsp;{{
              regionName
            }}
          </div>
          <div class="productPrice">
            <div
              class="product"
              v-for="(item, index) in vegetablePricesData"
              :key="index"
            >
              <div class="name">{{ item.varietyName }}</div>
              <div class="price">￥{{ item.middlePrice }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <!-- 价格周涨跌排行 -->
      <div class="right1">
        <div class="title">
          <span>价格周涨跌排行</span>
          <img src="../../public/images/jiantou6.png" alt="" />
          <!-- 涨跌按钮 -->
          <div class="changeBtn">
            <div
              class="rise"
              :class="{ changeActive: trendPitchOn == 'rise' }"
              @click="trendClick('rise')"
            >
              涨幅
            </div>
            <div
              class="fall"
              :class="{ changeActive: trendPitchOn == 'fall' }"
              @click="trendClick('fall')"
            >
              跌幅
            </div>
          </div>
        </div>
        <div class="content">
          <div class="formTitle">
            <div class="formTitle1">排名</div>
            <div class="formTitle1">食品名称</div>
            <div class="formTitle1">产品价格</div>
            <div class="formTitle1">涨跌幅</div>
          </div>
          <div class="formContent">
            <div
              class="formContentShell"
              v-for="(item, index) in listOfUpsAndDowns"
              :key="index"
              :class="{ formActive: index % 2 == 1 }"
            >
              <div
                class="formContent1"
                :class="{
                  top1: index == 0,
                  top2: index == 1,
                  top3: index == 2
                }"
              >
                {{ index + 1 > 3 ? index + 1 : "" }}
              </div>
              <div class="formContent1">{{ item.name }}</div>
              <div class="formContent1">{{ item.price }}元/斤</div>
              <div
                class="formContent1"
                :style="{ color: item.type == 'fall' ? '#57b84e' : '#e35a66' }"
              >
                {{ item.range }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 近7日价格行情预测 -->
      <div class="right2">
        <div class="title">
          <span>近7日价格行情预测</span>
          <img src="../../public/images/jiantou6.png" alt="" />
          <!-- 选项 -->
          <span class="optionBox" @click="optionBoxClick()"
            ><span>{{ productTypeValue }}</span>
            <i class="el-icon-caret-bottom"></i
          ></span>
          <div class="optionValue" v-if="productTypeShow">
            <div
              class="productValue"
              v-for="(item, index) in productType"
              :key="index"
              @click="handleClick(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="content">
          <div class="min-title">
            <span>
              <img src="../../public/images/xiaotubiao.png" alt="" />
              预计后期价格</span
            >
            <span
              :style="{
                color:
                  predictOutcome == '上升'
                    ? '#e35a65'
                    : predictOutcome == '下降'
                    ? '#00ff30'
                    : 'yellow'
              }"
              >{{ predictOutcome }}</span
            >
          </div>
          <!-- 折线图 -->
          <div class="echartsBox">
            <div
              ref="chart3"
              id="mychart3"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </div>
      </div>
      <!-- 农产品周均价格 -->
      <div class="right3">
        <div class="title">
          <span>农产品周均价格</span>
          <img src="../../public/images/jiantou6.png" alt="" />
        </div>
        <div class="content">
          <div class="echartsBox">
            <div
              ref="chart4"
              id="mychart4"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  marketConditionAvg,
  marketConditionMarketPrice,
  marketConditionInfo,
  marketConditionRtp
} from "../api/api";
import * as echarts from "echarts";
export default {
  data() {
    return {
      minimum: 0, //实时价格最小数
      averagePrice: 0, //周平均价
      regionalPriceList: [], //地区价格列表
      realTimePriceTab: 0, //实时价格的tab
      regionPitchOn: 0, //主要地区被选中的
      regionData: [
        {
          province: "山东",
          id: 0,
          top: "30%",
          left: "65%",
          provinceName: "山东省",
          type: "p"
        },
        {
          province: "河南",
          id: 1,
          top: "36%",
          left: "60%",
          provinceName: "河南省",
          type: "p"
        },
        {
          province: "广东",
          id: 2,
          top: "58%",
          left: "65%",
          provinceName: "广东省",
          type: "p"
        },
        {
          province: "江苏",
          id: 3,
          top: "36%",
          left: "70%",
          provinceName: "江苏省",
          type: "p"
        },
        {
          province: "福建",
          id: 4,
          top: "52%",
          left: "70%",
          provinceName: "福建省",
          type: "p"
        },
        {
          province: "湖北",
          id: 5,
          top: "43%",
          left: "60%",
          provinceName: "湖北省",
          type: "p"
        },
        {
          province: "河北",
          id: 6,
          top: "25%",
          left: "62%",
          provinceName: "河北省",
          type: "p"
        }
      ], //主要地区
      trendPitchOn: "rise", //涨跌开关
      productOptions: "", //产品选项
      productType: [
        {
          id: 0,
          name: "西兰花"
        },
        {
          id: 1,
          name: "毛豆"
        },
        {
          id: 2,
          name: "西瓜"
        }
      ], //产品选项  小选项
      productTypeShow: false, //小选项开关
      productTypeValue: "", //小选项被选中value
      listOfUpsAndDowns: [],
      timeHorizon: 7, //时间范围选择  两个参数  7天或30天
      last7DaysData: [], //未来 7天时间数据
      formerly7DaysData: [], //过去 7天/30天时间数据
      predictiveInformation: [], //行情预测数据
      predictOutcome: "", //预测接口
      monitorGoodsData: [], //农产品周均价格->被监控的农产品
      dataWeekArr: [], //本周数据Arr
      lastDataWeekArr: [], //上周数据Arr
      vegetablePricesData: [], //地图上的农产品价格
      regionName: "", //地图上的地区名称
      currentDay: "" //当前日期
    };
  },
  created() {},
  mounted() {
    this.priceTrendData(); //产品价格走势
    this.tabClick(this.regionData[3], 3); //中国地图上面的tab 默认选择江苏
    this.futureLast7Days(); //获取最近7日方法 未来7日
    this.formerlyLast7Days(7); //获取最近7日方法 过去7日
    this.handleClick(this.productType[0]); //小选项 首次进来的点击事件
    this.trendClick("rise"); //价格周涨跌排行 首次进来看涨幅
    this.realTimePrice(); //实时价格        ->折线图
    this.priceTrend(); //产品价格走势    ->折线图
    this.forecast(); //近7日价格行情预测->折线图
    this.weeklyAveragePrice(); //农产品周均价格   ->柱状图
  },
  methods: {
    //处理每日价格的方法
    skimmingPrice(data) {
      let realTimeData = data;
      let totalPrice = 0; //总价格
      let totalPriceArr = []; //实时价格数组
      realTimeData.forEach((item) => {
        item.timer = item.reportTime.slice(5);
        totalPrice = totalPrice + Number(item.middlePrice);
        totalPriceArr.push(Number(item.middlePrice));
      });

      this.minimum = Math.min(...totalPriceArr); //获取最小数
      this.averagePrice =
        Math.floor((totalPrice / realTimeData.length) * 100) / 100 || 0; //周平均价

      let arrObj = [];
      console.log(this.formerly7DaysData, "formerly7DaysData");
      this.formerly7DaysData.forEach((item, index) => {
        //整理数据
        let obj = {
          time: item,
          productData: this.averagePrice
        };
        realTimeData.forEach((item1) => {
          if (item == item1.timer) {
            obj.productData = item1.middlePrice;
          }
        });
        arrObj.push(obj);
      });

      let productPriceArr = [];
      arrObj.forEach((item) => {
        //处理数组对象 得实时价格数组
        productPriceArr.push(Number(item.productData));
      });
      console.log(productPriceArr, "arrObj");
      return productPriceArr;
    },
    //实时价格中选择产品点击事件
    realTimePriceClick(data, timer) {
      this.realTimePriceTab = data.id;
      let obj = {
        days: timer,
        varietyName: data.name
      };
      marketConditionMarketPrice(obj).then((res) => {
        //获取实时价格 曾经7日价格 接口
        let productPriceArr = this.skimmingPrice(res.data);
        this.realTimePrice(productPriceArr, this.averagePrice);
      });
      let obj1 = {
        days: "day",
        varietyName: data.name
      };
      marketConditionRtp(obj1).then((res) => {
        //获取各市产品价格list  接口
        let regionalPriceArr = res.data;
        regionalPriceArr.forEach((item) => {
          item.differenceValue = (item.middlePrice - item.ytPrice).toFixed(2);
          if (item.differenceValue < 0) {
            //这边对负数进行处理
            item.range = item.differenceValue.slice(1);
          } else {
            item.range = item.differenceValue;
          }
          // type为0 设置持平 type为1 设置上升 type为0 设置下降
          if (item.differenceValue == 0) {
            //持平
            item.type = 0;
          } else if (item.differenceValue > 0) {
            //上升
            item.type = 1;
          } else if (item.differenceValue < 0) {
            //下降
            item.type = 2;
          }
        });
        this.regionalPriceList = regionalPriceArr; //实时价格列表赋值
      });
    },
    //产品价格走势   ->接口数据
    priceTrendData() {
      let broccoliPrice1 = [];
      let broccoliPrice2 = [];
      let broccoliPrice3 = [];
      let obj1 = {
        days: 7,
        varietyName: "西兰花"
      };
      marketConditionMarketPrice(obj1).then((res) => {
        broccoliPrice1 = this.skimmingPrice(res.data);
      });
      let obj2 = {
        days: 7,
        varietyName: "毛豆"
      };
      marketConditionMarketPrice(obj2).then((res) => {
        broccoliPrice2 = this.skimmingPrice(res.data);
      });
      let obj3 = {
        days: 7,
        varietyName: "西瓜"
      };
      marketConditionMarketPrice(obj3).then((res) => {
        broccoliPrice3 = this.skimmingPrice(res.data);
      });
      setTimeout(() => {
        this.priceTrend(broccoliPrice1, broccoliPrice2, broccoliPrice3);
      }, 1000);
    },
    //实时价格        ->折线图
    realTimePrice(priceArr, averagePrice) {
      let myChart1 = echarts.init(document.getElementById("mychart1"));
      let option1 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999"
            }
          }
        },
        toolbox: {
          feature: {
            dataView: { show: false, readOnly: false },
            magicType: { show: false, type: ["line", "bar"] },
            restore: { show: false },
            saveAsImage: { show: false }
          }
        },
        legend: {
          show: false,
          data: ["Evaporation", "Temperature"]
        },
        //改变表格的宽高
        grid: {
          x: 30,
          y: 30,
          x2: 0,
          y2: 20,
          borderWidth: 10
        },
        xAxis: [
          {
            type: "category",
            data: this.formerly7DaysData,
            axisLabel: {
              //修改字体颜色
              color: "#f4f4f6"
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#0d52b5",
                width: 2
              }
            },
            axisPointer: {
              type: "shadow"
            }
          }
        ],
        yAxis: [
          {
            nameTextStyle: {
              //单位相关样式设置
              color: "#709fd9",
              padding: [0, 0, 0, -25]
            },
            type: "value",
            name: "元/斤",
            min: 0,
            max: averagePrice + 2,
            interval: 2,
            splitLine: {
              lineStyle: {
                type: "solid",
                color: "#ffffff15", //坐标轴线颜色
                width: "2"
              }
            },
            axisLabel: {
              color: "#709fd9",
              formatter: "{value}"
            }
          },
          {
            show: false,
            type: "value",
            name: "Temperature",
            min: 0,
            max: averagePrice + 0.5,
            interval: 2,
            axisLabel: {
              formatter: "{value} °C"
            }
          }
        ],
        series: [
          {
            name: "价钱",
            type: "bar",
            barWidth: 20, //柱子宽度
            tooltip: {
              valueFormatter: function (value) {
                return value + " 元/斤";
              }
            },
            data: priceArr,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  //柱子渐变色背景
                  {
                    offset: 0,
                    color: "#00aefc"
                  },
                  {
                    offset: 1,
                    color: "#00f9ee"
                  }
                ])
              }
            }
          }
          // {
          //   name: 'Temperature',
          //   type: 'line',
          //   yAxisIndex: 1,
          //   smooth: true,
          //   data: priceArr,
          //   lineStyle: {
          //     color: "#00c4fd",
          //     join: "round"
          //   },
          //   itemStyle: {
          //     color: "#00a5e3",
          //   }
          // }
        ]
      };
      myChart1.setOption(option1);
      window.addEventListener("resize", () => {
        myChart1.resize();
      });
    },
    //产品价格走势     ->折线图
    priceTrend(data1, data2, data3) {
      let myChart2 = echarts.init(document.getElementById("mychart2"));

      let option2 = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: [
            { name: "西兰花", icon: "rect" },
            { name: "毛豆", icon: "rect" },
            { name: "西瓜", icon: "rect" }
          ],
          textStyle: {
            fontSize: 12,
            color: "#fff"
          },
          itemWidth: 10,
          itemHeight: 10,
          padding: 10
        },
        grid: {
          left: "0%",
          right: "1%",
          bottom: "0%",
          // top:'10%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false
            }
          }
        },
        xAxis: {
          type: "category",
          data: this.formerly7DaysData,
          axisTick: {
            show: false
          },
          axisLabel: {
            //修改字体颜色
            color: "#f4f4f6",
            rotate: 0 //文字逆时针旋转45°
          },
          splitLine: {
            //修改线的颜色
            lineStyle: {
              type: "solid",
              color: "#ffffff10",
              width: "1"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#0d52b5",
              width: 2
            }
          }
        },
        yAxis: {
          type: "value",
          name: "单位(%)", //加上单位
          nameTextStyle: {
            //单位相关样式设置
            color: "#709fd9",
            padding: [0, -10, 0, -10]
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: "#709fd9"
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color: "#0d52b5",
              width: "1"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color: "#ffffff15", //坐标轴线颜色
              width: "2"
            }
          }
        },
        series: [
          {
            name: "西兰花",
            type: "line",
            // stack: 'Total',
            data: data1
          },
          {
            name: "毛豆",
            type: "line",
            // stack: 'Total',
            data: data2
          },
          {
            name: "西瓜",
            type: "line",
            // stack: 'Total',
            data: data3
          }
        ]
      };
      myChart2.setOption(option2);
      window.addEventListener("resize", () => {
        myChart2.resize();
      });
    },
    // 获取最近7日方法 未来
    futureLast7Days() {
      let seconds = new Date(); //获取当下时间
      let secondsNumber = seconds.getTime(); //获取当下时间的毫秒数
      let secondsArr = [
        secondsNumber,
        secondsNumber + 86400000,
        secondsNumber + 86400000 * 2,
        secondsNumber + 86400000 * 3,
        secondsNumber + 86400000 * 4,
        secondsNumber + 86400000 * 5,
        secondsNumber + 86400000 * 6
      ]; //7日数组毫秒数
      let secondsArr1 = []; //收集处理好的时间的容器
      secondsArr.forEach((item, index) => {
        let date1 = new Date(item);
        let month = date1.getMonth() + 1;
        let day = date1.getDate();
        secondsArr1.push(month + "-" + day);
        if (index == 0) {
          this.currentDay = `${month}月${day}日`;
        }
      });
      this.last7DaysData = secondsArr1;
    },
    // 获取最近7日方法 过去
    formerlyLast7Days(e) {
      this.timeHorizon = e;
      //调用下实时价格用的两个接口 参数1:实时价格tab选中的农产品对象   参数2:当下选中为7日/30日
      this.realTimePriceClick(
        this.productType[this.realTimePriceTab],
        this.timeHorizon
      );
      let seconds = new Date(); //获取当下时间
      let secondsNumber = seconds.getTime(); //获取当下时间的毫秒数
      let numberArr = [];
      let countNumber = 0;
      for (let i = 0; i < e; i++) {
        numberArr.push(countNumber++);
      }
      let secondsArr = []; //过去7日数组毫秒数
      numberArr.forEach((item) => {
        secondsArr.push(secondsNumber - 86400000 * item);
      });
      let secondsArr1 = []; //收集处理好的时间的容器
      secondsArr.forEach((item) => {
        let date1 = new Date(item);
        let month = date1.getMonth() + 1;
        let day = date1.getDate();
        secondsArr1.push(month + "-" + day);
      });
      this.formerly7DaysData = secondsArr1.reverse();
      this.realTimePrice();
    },
    // 行情预测数据
    forecastData(e) {
      if (e.id == 0) {
        this.predictiveInformation = [4.0, 3.85, 4.12, 4.0, 3.75, 3.65, 3.95];
      } else if (e.id == 1) {
        this.predictiveInformation = [6.95, 6.52, 7.0, 7.12, 6.23, 6.18, 6.52];
      } else if (e.id == 2) {
        this.predictiveInformation = [
          9.65, 9.34, 9.12, 9.23, 10.23, 10.31, 10.12
        ];
      }
      if (this.predictiveInformation[6] > this.predictiveInformation[0]) {
        this.predictOutcome = "上升";
      } else if (
        this.predictiveInformation[6] == this.predictiveInformation[0]
      ) {
        this.predictOutcome = "持平";
      } else {
        this.predictOutcome = "下降";
      }
      this.forecast();
    },
    //近7日价格行情预测 ->折线图
    forecast() {
      let myChart3 = echarts.init(document.getElementById("mychart3"));
      let option3 = {
        color: "#80FFA5",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        legend: {
          show: false,
          data: "价钱"
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {}
          }
        },
        //改变表格的宽高
        grid: {
          x: 30,
          y: 30,
          x2: 17,
          y2: 20,
          borderWidth: 10
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.last7DaysData,
            axisTick: {
              show: false
            },
            axisLabel: {
              //修改字体颜色
              color: "#f4f4f6"
            },
            splitLine: {
              //修改线的颜色
              lineStyle: {
                type: "solid",
                color: "#ffffff10",
                width: "1"
              }
            },
            axisLine: {
              lineStyle: {
                color: "#0d52b5",
                width: 2
              }
            }
          }
        ],
        yAxis: [
          {
            name: "元/斤",
            type: "value",
            nameTextStyle: {
              //单位相关样式设置
              color: "#709fd9",
              padding: [0, -10, 0, -25]
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "solid",
                color: "#ffffff15", //坐标轴线颜色
                width: "2"
              }
            }
          }
        ],
        series: [
          {
            name: "价钱",
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(128, 255, 165)"
                },
                {
                  offset: 1,
                  color: "rgb(1, 191, 236)"
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: this.predictiveInformation
          }
        ]
      };
      myChart3.setOption(option3);
      window.addEventListener("resize", () => {
        myChart3.resize();
      });
    },
    //农产品周均价格    ->柱状图
    weeklyAveragePrice() {
      let myChart4 = echarts.init(document.getElementById("mychart4"));
      //周价格接口
      let obj = {
        id: 2
      };
      marketConditionAvg(obj).then((res) => {
        let dataWeek = res.data.list; //本周数据
        let lastDataWeek = res.data.before_list; //上周数据
        let monitorGoods = []; //监控商品
        let dataWeekData = []; //本周数据Arr
        let lastDataWeekData = []; //上周数据Arr
        dataWeek.forEach((item) => {
          monitorGoods.push(item.varietyName);
          dataWeekData.push(item.price);
        });

        if (!lastDataWeek) {
          dataWeek.forEach((item) => {
            lastDataWeekData.push(0);
          }); //因为发现后端数据有时候没有 上周数据 这边做了遍历 默认设置为0
        } else {
          lastDataWeek.forEach((item) => {
            lastDataWeekData.push(item.price);
          });
        }

        this.monitorGoodsData = monitorGoods; //农产品周均价格->被监控的农产品
        this.dataWeekArr = dataWeekData; //农产品周均价格->被监控的农产品本周数据
        this.lastDataWeekArr = lastDataWeekData; //农产品周均价格->被监控的农产品上周数据

        let option4 = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          legend: {
            top: "0%",
            data: ["本周元/斤", "上周元/斤"],
            right: 0,
            textStyle: {
              fontSize: 12,
              color: "#fff"
            },
            itemWidth: 15,
            itemHeight: 10
          },
          //改变表格的宽高
          grid: {
            x: 41,
            y: 40,
            x2: 0,
            y2: 18,
            borderWidth: 10
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              data: this.monitorGoodsData,
              axisLabel: {
                //修改字体颜色
                color: "#f4f4f6"
              },
              splitLine: {
                //修改线的颜色
                lineStyle: {
                  type: "solid",
                  color: "#ffffff10",
                  width: "1"
                }
              }
            }
          ],
          yAxis: [
            {
              type: "value",
              name: "元/斤", //加上单位
              nameTextStyle: {
                //单位相关样式设置
                color: "#fff",
                padding: [0, 0, 10, -25]
              },
              axisLabel: {
                color: "#f4f4f6",
                formatter: "{value}元" //自定义Y轴+单位value
              },
              splitLine: {
                lineStyle: {
                  type: "solid",
                  color: "#ffffff10",
                  width: "1"
                }
              }
            }
          ],
          series: [
            {
              name: "本周元/斤",
              type: "bar",
              barWidth: 15, //柱子宽度
              barGap: "20%", //柱子间隙
              // label: labelOption,
              emphasis: {
                focus: "series"
              },
              data: this.dataWeekArr,
              itemStyle: {
                normal: {
                  color: "#0079ff"
                }
              }
            },
            {
              name: "上周元/斤",
              type: "bar",
              barWidth: 15, //柱子宽度
              barGap: "20%", //柱子间隙
              emphasis: {
                focus: "series"
              },
              data: this.lastDataWeekArr,
              itemStyle: {
                normal: {
                  color: "#19f39d"
                }
              }
            }
          ]
        };
        myChart4.setOption(option4);
        window.addEventListener("resize", () => {
          myChart4.resize();
        });
      });
    },
    // 选中 选项中的信息
    handleClick(e) {
      this.forecastData(e); //行情预测数据
      this.productTypeValue = e.name;
      this.productTypeShow = false;
    },
    //小选项点击事件
    optionBoxClick() {
      this.productTypeShow = !this.productTypeShow;
    },
    // 趋势涨跌
    trendClick(e) {
      this.trendPitchOn = e;
      if (e == "rise") {
        let riseObj = [
          {
            name: "生菜",
            price: "6",
            range: "5.58%",
            type: "rise"
          },
          {
            name: "菠菜",
            price: "3",
            range: "4.66%",
            type: "rise"
          },
          {
            name: "小白菜",
            price: "3",
            range: "3.67%",
            type: "rise"
          },
          {
            name: "香菜",
            price: "6",
            range: "3.56%",
            type: "rise"
          },
          {
            name: "茄子",
            price: "3",
            range: "0.81%",
            type: "rise"
          },
          {
            name: "韭菜",
            price: "6",
            range: "0.7%",
            type: "rise"
          },
          {
            name: "西兰花",
            price: "4",
            range: "0.53%",
            type: "rise"
          },
          {
            name: "胡萝卜",
            price: "2",
            range: "0.51%",
            type: "rise"
          }
        ];
        this.listOfUpsAndDowns = riseObj;
      } else {
        let riseObj = [
          {
            name: "大葱",
            price: "3",
            range: "6.58%",
            type: "fall"
          },
          {
            name: "芹菜",
            price: "2",
            range: "5.68%",
            type: "fall"
          },
          {
            name: "大白菜",
            price: "1.2",
            range: "2.71%",
            type: "fall"
          },
          {
            name: "莴笋",
            price: "3",
            range: "1.31%",
            type: "fall"
          },
          {
            name: "莲藕",
            price: "6",
            range: "1.21%",
            type: "fall"
          },
          {
            name: "辣椒",
            price: "5",
            range: "1.12%",
            type: "fall"
          },
          {
            name: "青椒",
            price: "2",
            range: "0.9%",
            type: "fall"
          },
          {
            name: "黄瓜",
            price: "5",
            range: "0.8%",
            type: "fall"
          }
        ];
        this.listOfUpsAndDowns = riseObj;
      }
    },
    // tab的相关的点击事件
    tabClick(item, index) {
      this.regionPitchOn = index;
      // console.log(item,788787);
      this.regionName = item.province;
      let obj = {
        type: item.type,
        name: item.provinceName
      };
      marketConditionInfo(obj).then((res) => {
        // console.log(res.data);
        let vegetablePrices = res.data; //做下去重
        for (let i = 0; i < vegetablePrices.length; i++) {
          for (let j = i + 1; j < vegetablePrices.length; j++) {
            if (vegetablePrices[i].varietyId == vegetablePrices[j].varietyId) {
              vegetablePrices.splice(j, 1);
              j--;
            }
          }
        }
        this.vegetablePricesData = vegetablePrices.slice(0, 12);
      });
    }
  }
};
</script>

<style scoped lang="less">
.eCommerce {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  background: url("../../public/images/syBg.jpg") no-repeat center center;
  background-size: 100%;
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;

  .title {
    height: 1.2vw;
    // border: 1px solid red;
    border-left: 5px solid #02f0ff;
    color: #fff;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 0.5vw;
    span {
      margin: 0 0.5vw;
    }
  }
  .left {
    width: 24%;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // 实时价格
    .left1 {
      width: 100%;
      height: 59%;
      // border: 1px solid red;
      background: url("../../public/images/kuang6-1.png") no-repeat;
      background-size: 100% 100%;
      padding: 1vw;
      display: flex;
      flex-direction: column;
      .flex1 {
        display: flex;
        justify-content: space-between;
      }
      .content {
        // border: 1px solid red;
        flex: 1;
        // tab选项卡
        .tabVariety {
          // border: 1px solid red;
          height: 1.5vw;
          display: flex;
          align-content: center;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;
          span {
            //  border: 1px solid red;
            background: #032e97;
            color: #00feff;
            padding: 4px 15px;
            margin: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            font-size: 12px;
            line-height: 12px;
            user-select: none;
            cursor: pointer;
          }
          .varietyActive {
            background: #f7c500;
            color: #02054a;
          }
        }
        // 小标题最新价格
        .recentQuotation {
          // border: 1px solid red;
          display: flex;
          align-items: center;
          height: 10%;
          color: #fff;
          // padding: 0 10px;
          div {
            // margin-right: 10%;
            width: 50%;
            // border: 1px solid red;
            span {
              color: #00c6ff;
              font-size: 18px;
            }
          }
        }
        // 最新价格展示框
        .priceBox {
          width: 100%;
          height: 42%;
          border: 1px solid #1f24aa;
          .priceTitle,
          .priceView {
            width: 100%;
            height: 20%;
            background: #010d70;
            border: 1px solid #1f24aa;
            color: #7fb4f6;
            display: flex;
            span {
              // border: 1px solid red;
              display: inline-block;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              line-height: 12px;
            }
            .trend {
              width: 30%;
              // border: 1px solid red;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              line-height: 12px;
              img {
                // border:1px solid red;
                margin-left: 10px;
              }
            }
            span:nth-child(1) {
              width: 40%;
              // border: 1px solid red;
            }
            span:nth-child(2) {
              width: 30%;
              // border: 1px solid red;
            }
            span:nth-child(3) {
              width: 30%;
              // border: 1px solid red;
            }
          }
          .priceView {
            height: 24%;
          }
          .priceShell {
            // border: 1px solid yellow;
            height: 80%;
            overflow: auto;
          }
          .priceShell::-webkit-scrollbar {
            display: none;
          }
          .priceView {
            background: rgba(255, 255, 255, 0);
            color: #fff;
            border: 1px solid #1f24aa00;
          }
        }
        //线型图
        .linesPlan {
          // border: 1px solid red;
          width: 100%;
          height: 42%;
          position: relative;
          padding-top: 0.8vw;
          .selectorButton {
            position: absolute;
            width: 100%;
            height: 20px;
            // border: 1px solid red;
            z-index: 999;
            display: flex;
            justify-content: flex-end;
            color: #00c6ff;
            // margin-bottom: -15px;
            div {
              width: 50px;
              height: 100%;
              border: 1px solid #00c6ff;
              line-height: 18px;
              user-select: none;
              cursor: pointer;
              margin-left: 10px;
            }
            .linesPlanActive {
              background: #00c6ff;
              color: #010332;
            }
          }
        }
      }
    }
    // 产品价格走势
    .left2 {
      width: 100%;
      height: 39%;
      // border: 1px solid red;
      background: url("../../public/images/kuang6-2.png") no-repeat;
      background-size: 100% 100%;
      padding: 1vw;
      display: flex;
      flex-direction: column;
      .content {
        // border: 1px solid red;
        flex: 1;
        padding: 0.5vw;
        .priceGraph {
          width: 100%;
          height: 100%;
          //  border: 1px solid red;
        }
      }
    }
  }
  .middle {
    width: 50%;
    height: 100%;
    position: relative;
    // 大公鸡
    .middleBox {
      width: 100%;
      height: 100%;
      background: url("../../public/images/kuang6-4.png") no-repeat;
      background-size: 100% 100%;
      padding: 2.5vw 4vw;
      // tab选项卡
      .tab {
        width: 100%;
        height: 6%;
        // border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        span {
          display: inline-block;
          width: 80px;
          height: 30px;
          border-radius: 15px;
          background: #0739d8;
          border: 1px solid #02b7fc;
          // display: flex;
          color: #00ffff;
          line-height: 30px;
          text-align: center;
          user-select: none;
          cursor: pointer;
        }
        .active {
          background: #f7c500;
          border: 1px solid #f7c500;
          color: #443b16;
        }
      }
      // 大公鸡
      .china {
        width: 100%;
        height: 75%;
        // border: 1px solid red;
        background: url("../../public/images/ji.png") no-repeat center center;
        background-size: 75% auto;
        position: relative;
        .pitchOn {
          width: 25px;
          height: 30px;
          position: absolute;
          background: url("../../public/images/daohang6.png") no-repeat;
        }
        .pitchActive {
          background: url("../../public/images/daohang6-1.png") no-repeat;
        }
      }
      // 表格数据
      .chartBox {
        width: 90%;
        height: 32%;
        // border: 1px solid red;
        background: url("../../public/images/kuang6-5.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: 1vw;
        left: 50%;
        transform: translateX(-50%);
        // right: 0;
        // margin-top: -9%;
        padding: 4% 6%;
        .selectedRegion {
          // border: 1px solid rgb(153, 255, 0);
          color: #00ffff;
          font-weight: 550;
          height: 20%;
          font-size: 15px;
          display: flex;
          align-items: center;
        }
        .productPrice {
          height: 80%;
          // border: 1px solid rgb(34, 255, 0);
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          justify-content: flex-start;
          .product {
            width: 33.3%;
            height: 25%;
            // border: 1px solid red;
            color: #fff;
            display: flex;
            align-items: center;
            padding: 0 1vw;
            div {
              width: 50%;
              text-align: left;
            }
            .price {
              text-align: right;
              color: #00ffff;
            }
          }
        }
      }
    }
  }
  .right {
    width: 24%;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // 价格周涨跌排行
    .right1 {
      width: 100%;
      height: 32%;
      // border: 1px solid red;
      background: url("../../public/images/kuang6-3.png") no-repeat;
      background-size: 100% 100%;
      padding: 1vw;
      display: flex;
      flex-direction: column;
      .content {
        // border: 1px solid red;
        flex: 1;
        .formTitle {
          background: #058eea60;
          display: flex;
          height: 15%;
          .formTitle1 {
            // border: 1px solid red;
            width: 30%;
            color: #7fb4f6;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 15px;
          }
          .formTitle1:nth-child(1) {
            width: 10%;
          }
        }
        .formContent {
          height: 85%;
          // border: 1px solid yellow;
          overflow: auto;
          .formContentShell {
            // border: 1px solid green;
            display: flex;
            height: 22%;
            .formContent1 {
              // border: 1px solid red;
              width: 30%;
              color: #7fb4f6;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .top1 {
              background: url("../../public/images/top1.png") no-repeat center
                center;
            }
            .top2 {
              background: url("../../public/images/top2.png") no-repeat center
                center;
            }
            .top3 {
              background: url("../../public/images/top3.png") no-repeat center
                center;
            }
            .formContent1:nth-child(1) {
              width: 10%;
            }
            .formContent1:nth-child(4) {
              color: #b44762;
            }
          }
          .formActive {
            background: #058eea31;
          }
        }
        .formContent::-webkit-scrollbar {
          display: none;
        }
      }
      .title {
        position: relative;
        .changeBtn {
          position: absolute;
          right: 0;
          // border: 1px solid red;
          display: flex;
          div {
            border: 1px solid #024c8c;
            margin: 0 5px;
            padding: 2px 10px;
            font-size: 12px;
            display: flex;
            user-select: none;
            line-height: 20px;
            cursor: pointer;
          }
          .changeActive {
            background: #024c8c;
          }
        }
      }
    }
    // 近7日价格行情预测
    .right2 {
      width: 100%;
      height: 32%;
      // border: 1px solid red;
      background: url("../../public/images/kuang6-3.png") no-repeat;
      background-size: 100% 100%;
      padding: 1vw;
      display: flex;
      flex-direction: column;
      .content {
        // border: 1px solid red;
        flex: 1;
        padding: 0.5vw;
        .min-title {
          // border: 1px solid red;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          margin-bottom: 10px;
          span {
            display: inline-block;
          }
          span:nth-child(1) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            line-height: 12px;
            img {
              margin-right: 10px;
            }
          }
          span:nth-child(2) {
            font-size: 18px;
            color: #00ff30;
          }
        }
        .echartsBox {
          height: 85%;
          // border: 1px solid red;
        }
      }
      .title {
        position: relative;
        .optionBox {
          position: absolute;
          right: 0;
          width: 80px;
          height: 24px;
          border: 1px solid #0166ad;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          font-size: 13px;
          line-height: 13px;
          user-select: none;
          cursor: pointer;
          span {
            display: inline-block;
            width: 60px;
            // border: 1px solid red;
            position: absolute;
            left: 30%;
            transform: translateX(-50%);
          }
          i {
            position: absolute;
            right: 5px;
          }
        }
        .optionValue {
          position: absolute;
          right: 9px;
          bottom: -105px;
          width: 80px;
          height: 100px;
          background: rgba(255, 255, 255, 0.354);
          overflow: auto;
          z-index: 999;
          // border: 1px solid red;
          .productValue {
            width: 100%;
            height: 25px;
            // border: 1px solid #fff;
            line-height: 25px;
            font-size: 12px;
            user-select: none;
            cursor: pointer;
          }
          .productValue:hover {
            background: #00c6ff;
          }
        }
        .optionValue::-webkit-scrollbar {
          display: none;
        }
      }
    }
    // 农产品周均价格
    .right3 {
      width: 100%;
      height: 32%;
      // border: 1px solid red;
      background: url("../../public/images/kuang6-3.png") no-repeat;
      background-size: 100% 100%;
      padding: 1vw;
      display: flex;
      flex-direction: column;
      .content {
        // border: 1px solid red;
        flex: 1;
        padding: 0.5vw;
        .echartsBox {
          // border: 1px solid red;
          height: 100%;
        }
      }
    }
  }
}
</style>

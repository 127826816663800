<template>
  <div class="gisMapShell"  v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)"
  element-loading-text="Loading"
  element-loading-spinner="el-icon-loading">
    <div class="left">
      <div class="left1 leftBox">
        <img src="../../public/images/back-btn.png" alt="" @click="getBack">
        <div class="returnImg" @click="getBack">></div>
      </div>
      <div class="left2 leftBox">
        <div class="title">
          <img src="../../public/images/desc-title.png" alt="">

        </div>
        <div class="text">
          <p><span>大棚所有人：</span><span> {{detailData.username}}</span></p>
          <p><span>基地名称：</span><span>{{detailData.basename}}</span></p>
          <p><span>所在地址：</span><span>{{detailData.address}}</span></p>
          <p><span>占地面积：</span><span> {{detailData.baseArea}}亩</span></p>
        </div>
      </div>
      <div class="left3 leftBox">
          <div class="title">
             <img src="../../public/images/live-title.png" alt="">
              <div class="selsctVideo">
               <input type="text" v-model="value1" @click="videoInputClick" >
               <div class="iconBox" @click="videoInputClick">
                  <i class="el-icon-caret-bottom" ref="iBox"></i>
               </div>
               <div class="inputSelect" v-if="videoListShow">
                <div class="list" v-for="(item,index) in options1" :key="index" @click="videoClick(item)">
                  {{item.name}}
                </div>
               </div>
              </div>
          </div>
          <div class="liveBox" id='player' v-for="(item,index) in options1" :key="index" v-show="item.name==value1">
            <!-- 视频组件 -->
           <videoFZ :videroUrl='videroUrl'/>  
          </div>
      </div>
    </div>
    <div class="middle">
      <div class="middle1 middleBox">
        <div class="sensor equipment">
          <div class="equipmentNumber minBox">
            <img src="../../public/images/sensor-icon.png" alt="">
            <div class="text">
              <p>传感器</p>
              <p>{{detailData.allLineSensor}}</p>
            </div>
          </div>
          <div class="line"></div>
          <div class="equipmentState minBox">
            <p><span class="dot"></span> &nbsp;在线&nbsp; <span class="quantity">{{detailData.onLineSensor}}</span></p>
            <p><span class="dot"></span> &nbsp;离线&nbsp; <span class="quantity">{{detailData.offLineSensor}}</span></p>
          </div>
        </div>
        <div class="monitoring equipment">
           <div class="equipmentNumber minBox">
            <img src="../../public/images/monitor-icon.png" alt="">
            <div class="text">
              <p>监控</p>
              <p>{{detailData.allMonitoring}}</p>
            </div>
          </div>
          <div class="line"></div>
          <div class="equipmentState minBox">
            <p><span class="dot"></span> &nbsp;在线&nbsp; <span class="quantity">{{detailData.onLineMonitoring}}</span></p>
            <p><span class="dot"></span> &nbsp;离线&nbsp; <span class="quantity">{{detailData.offlineMonitoring}}</span></p>
          </div>
        </div>
      </div>
      <div class="middle2 middleBox">
        <div class="middle2Box">
          <img :src="detailData.middleImage" alt="">
        </div>
      </div>
    </div>
    <div class="right">

      <div class="right1 rightBox">
        <div class="inputBox">
           <el-select v-model="value" placeholder="请选择">
             <el-option
               v-for="item in options"
               :key="item.index"
               :label="item.label"
               :value="item.index"
               >
             </el-option>
           </el-select>
        </div>
        <div class="dataBox">
          <div class="min-data" v-for="(item,index) in plantingData" :key="index">
            <img src="../../public/images/sensor1.png" alt="" v-if="item.pr_name=='土壤温度'">
            <img src="../../public/images/sensor2.png" alt="" v-else-if="item.pr_name=='土壤水分'">
            <img src="../../public/images/sensor3.png" alt="" v-else-if="item.pr_name=='光照强度'">
            <img src="../../public/images/sensor4.png" alt="" v-else-if="item.pr_name=='二氧化碳'">
            <img src="../../public/images/sensor5.png" alt="" v-else-if="item.pr_name=='空气湿度'"> 
            <img src="../../public/images/sensor6.png" alt="" v-else-if="item.pr_name=='空气温度'"> 
            <img src="../../public/images/sensor19.png" alt="" v-else-if="item.pr_name=='风向'"> 
            <img src="../../public/images/sensor20.png" alt="" v-else-if="item.pr_name=='风速'"> 
            <img src="../../public/images/sensor21.png" alt="" v-else-if="item.pr_name=='蒸发量'"> 
            <img src="../../public/images/sensor22.png" alt="" v-else-if="item.pr_name=='降雨量'"> 
            <img src="../../public/images/sensor23.png" alt="" v-else-if="item.pr_name=='大气压力'"> 
            <img src="../../public/images/sensor10.png" alt="" v-else-if="item.pr_name=='土壤含钾量'"> 
            <img src="../../public/images/sensor11.png" alt="" v-else-if="item.pr_name=='土壤含磷量'"> 
            <img src="../../public/images/sensor12.png" alt="" v-else-if="item.pr_name=='土壤含氮量'"> 
            <img src="../../public/images/sensor13.png" alt="" v-else-if="item.pr_name=='PH(土)'"> 
            <img src="../../public/images/sensor14.png" alt="" v-else-if="item.pr_name=='液位'"> 
            <img src="../../public/images/sensor15.png" alt="" v-else-if="item.pr_name=='PH(水)'"> 
            <img src="../../public/images/sensor16.png" alt="" v-else-if="item.pr_name=='水溶氧'"> 
            <img src="../../public/images/sensor17.png" alt="" v-else-if="item.pr_name=='水温度'"> 
            <img src="../../public/images/sensor18.png" alt="" v-else-if="item.pr_name=='土壤电导率'"> 
            <div class="text">
              <p>{{item.pr_name}}</p>
              <p>{{item.pr_value}}{{item.pr_unit}}</p>
            </div>
          </div>
        </div>
      </div>


      <div class="right2 rightBox">
        <div class="title">
          <img src="../../public/images/waring-title.png" alt="">
        </div>
        <div class="content">
          <div class="shell" v-if="earlyWarningData.length>0">
             <vue-seamless-scroll :data="earlyWarningData" :class-option="optionSingleHeightTime" class="seamless-warp">
              <div class="list" v-for="(item,index) in earlyWarningData" :key="index">
              <div class="icon">
                <img src="../../public/images/max.png" alt="" v-if="item.type=='max'">
                 <img src="../../public/images/min.png" alt="" v-else>
              </div>
              <div class="data">
                <p>{{item.dname}}:{{item.mess}}</p>
                <p>{{item.actiontime}}</p>
              </div>
            </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import { screenDetail,screenSearch } from '../api/api'
import videoFZ from '../../src/components/greenhouse/video.vue'
export default {
  components: { videoFZ },
  computed: {
   optionSingleHeightTime () {
      return {
          step: 0.5,     // 数值越大速度滚动越快
        }
    }
   },

  data () {
    return {
        loading: true,      //loading 相关
        videroUrl:'',       //video视频地址
        singlePlayer: null, //视频流
        flagFive:false,
        options1: [],       //这个是select选项 视频的
        value1: '',         //视频右上角的select的value
        videoListShow:false,
        sensorData:[],      //这个是传感器数据
        options: [],        //这个是select选项  网关的
        value: 0,
        detailData: {},
        detail: '',
        earlyWarningData:[
          {
          type:'max',
          dname:"传感器03",
          mess:'空气温度已经达到了最高值，请及时处理',
          actiontime: '2022/1/4'
          },
          {
          type:'max',
          dname:"传感器03",
          mess:'空气温度已经达到了最高值，请及时处理',
          actiontime: '2022/1/4'
           },
            {
            type:'max',
            dname:"传感器03",
            mess:'空气温度已经达到了最高值，请及时处理',
            actiontime: '2022/1/4'
          },
          {
            type:'max',
            dname:"传感器03",
            mess:'空气温度已经达到了最高值，请及时处理',
            actiontime: '2022/1/4'
          },
           {
            type:'max',
            dname:"传感器03",
            mess:'空气温度已经达到了最高值，请及时处理',
            actiontime: '2022/1/4'
          },
        ],  //预警data
        seachData:[],         //搜索data
        plantingData:[],      //种植数据
    }
  },
  watch:{
    value(val,oldval){
     this.plantingData=this.sensorData[val]
     console.log(this.plantingData,'this.plantingData');
    },
    videoListShow(val,oldval){    //video右上角选择 icon图标的旋转
    if(val){
      this.$refs.iBox.style.transform='rotate(-180deg)';
     }else{
      this.$refs.iBox.style.transform='rotate(0deg)';
     }
    }
    
  },
  created(){
    this.getScreenDetail()
    this.inputsearch()
    // this.handleVideo()
    // this.value=0  //传感器初始值
  },
  methods: {
    videoInputClick(){    //video 右上角的select input点击事件
     this.videoListShow= !this.videoListShow
     
    },
    videoClick(data){    //video 右上角的select 选择器的点击事件
      //  console.log(data,12212);
       this.videroUrl=data.label
       console.log(this.videroUrl,"this.videroUrl");
       this.value1=data.name
       this.videoListShow=false
    },
   
    inputsearch(){
      screenSearch().then(res=>{
      // this.options=res.data
       this.options= this.options   //暂时写死
     })
    },


      
    getScreenDetail(){
       let obj ={
       id:1
      }
       screenDetail(obj).then((res) => {
        // 用户信息获取
        let userObj={
          username:'',  //大棚所有人
          basename:'',  //基地名称
          address:'',   //所在地址
          baseArea:'',  //占地面积
          onLineMonitoring:0,//在线监控数量
          offlineMonitoring:0,//离线监控数量
          allMonitoring:0,//总监控数量
          onLineSensor:0,//在线传感器数量
          offLineSensor:0,//离线传感器数量
          allLineSensor:0,//传感器总数量
          middleImage:'',//图片地址
        }
        userObj.username=res.data.user.user_name
        userObj.basename=res.data.address_detail_str
        userObj.address=res.data.address
        userObj.baseArea=res.data.base_area
        //  监控设备的数量统计
        userObj.onLineMonitoring=res.data.dev_vdo_info.cnt_all
        userObj.offlineMonitoring=res.data.dev_vdo_info.cnt_all_offline
        userObj.allMonitoring=res.data.dev_vdo_info.cnt_all+res.data.dev_vdo_info.cnt_all_offline
        //  监控设备的数量统计

        //  传感器设备的数量统计
        let sensor=res.data.dev_env_info
        let cntAll=0
        let cntAllOffline=0
        sensor.forEach(item=>{
          cntAll=cntAll+item.cnt_all
          cntAllOffline=cntAllOffline+item.cnt_all_offline
        })
        userObj.onLineSensor=cntAll
        userObj.offLineSensor=cntAllOffline
        userObj.allLineSensor=cntAll+cntAllOffline
        //  传感器设备的数量统计

        // 中间大图
        userObj.middleImage=res.data.base_img
        // 中间大图
       
        this.detailData=userObj  
         // 用户信息获取

        // 传感器数据获取
        let selectArr=res.data.vibe
        let selectSliceArr=[]
        selectArr.forEach((item,index1)=>{
          let obj={
            value:'',
            label:'',
            index:'',
          }
          obj.value=item.slice(item.indexOf("_")+3)
          obj.label=item.slice(item.indexOf("_")+1)
          obj.index=index1
          selectSliceArr.push(obj)
          })
          this.options=selectSliceArr
          this.sensorData=res.data.dev_env_info[3].data
          // console.log(res.data.dev_env_info,"=res.data.dev_env_info");
          this.plantingData=this.sensorData[0]
          console.log( this.plantingData," this.plantingData");
         // 传感器数据获取
        
        // debugger
         //监控视频地址
         let videoList=res.data.dev_vdo_info.data
         let videoArr=[]
         videoList.forEach((item,index)=>{
           let videoObj={
            value:"",
            label:"",
            name:"",
           }
           videoObj.value=index
           videoObj.label=item
           videoObj.name="监控"+(index+1)
           videoArr.push(videoObj)
         })
         this.options1=videoArr
         this.videoClick(this.options1[0])
         //监控视频地址

        //  预警提醒
          this.earlyWarningData = this.earlyWarningData; //测试使用
        //  预警提醒
        this.loading=false

      })
    },


    getBack(){
      this.$router.go(-1)
    }
  },
}
</script>

<style lang="less" scoped>
@media screen and (max-width:1560px) {
  .left2{
    padding: 10px 25px !important;
    .text{
      p{
        // border: 1px solid red;
        padding: 5px 0 !important;
        span:nth-child(1){
          width: 35% !important;
        }
      }
    }
  }
  .left3{
    padding: 10px 25px !important;
  }
  .right1{
    padding: 21px 25px !important;
  }
  .right2{
    padding: 10px 25px !important;
    .content{
      .list{
        padding: 10px 0px !important;
      }
    }
  }
  .middle{
    .equipment{
      width: 49% !important;
    }
  }
}
.gisMapShell{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  // border: 1px solid red;
  padding: 20px 30px;
  background: url("../../public/images/map-bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  .left{
    width: 20%;
    height: 100%;
    // border: 1px solid red;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    .leftBox{
      width: 100%;
      // border: 1px solid red;

    }
    .left1{
      height: 13%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      position: relative;
      img{
        user-select: none;
        width: 100%;
        height: 80%;
        cursor: pointer;
      }
      .returnImg{
        user-select: none;
        cursor: pointer;
        position: absolute;
        left: 50%;
        transform:translateX(-50%);
        width: 120px;
        height: 70px;
        // border: 1px solid red;
        background: url("../../public/images/returnText.png") no-repeat center center;
        background-size:100% ;
      }
    }
    .left2{
      height: 38%;
      background: url("../../public/images/border1.png") no-repeat;
      background-size:100% 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      .title{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        img{
          width: 70%;
        }
      }
      .text{
        flex: 1;
        // border: 1px solid red;
        p{
          display: flex;
          padding: 10px 0;
          // border: 1px solid red;
          text-align: left;
          span:nth-child(1){
             width: 30%;
             box-sizing: border-box;
             color: #7fb4f6;
              line-height: 18px;
          }
           span:nth-child(2){
            box-sizing: border-box;
            display: inline-block;
            color: #fff;
            width: 70%;
            // border: 1px solid red;
            line-height: 18px;
          }
        }
      }
    }
    .left3{
     height: 38vh;
     //  border: 1px solid red;
     background: url("../../public/images/border1.png") no-repeat;
     background-size:100% 100%;
      padding: 3vh;
      display: flex;
      flex-direction: column;
      // border: 1px solid red;
      .title{
        width: 100%;
        height: 5vh;
        // border: 1px solid red;
         display: flex;
        align-items: center;
        .selsctVideo{
          width: 100px;
          height: 30px;
          // border: 1px solid red;
          position: relative;
          input{
            width: 100%;
            height: 100%;
            padding: 0 10px;
            user-select: none;
            cursor: pointer;
            background: rgba(255, 255, 255, 0);
            border: 1px solid rgb(35, 130, 232);
            color: rgba(255, 255, 255, 0.739);
            border-radius: 5px;
          }
          .iconBox{
            position: absolute;
            right: 0;
            top: 50%;
            transform:translateY(-50%);
            width: 20px;
            height: 20px;
            // border: 1px solid red;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgb(35, 130, 232);
          }
          .inputSelect{
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 100px;
            // border: 1px solid red;
            background: rgba(255, 255, 255, 0.484);
            border-radius: 0px 0px 5px 5px;
            overflow: auto;
      
            .list{
              width: 100%;
              height: 33.3%;
              // border: 1px solid red;
              display: flex;
              justify-content: center;
              align-items: center;
              user-select: none;
              cursor: pointer;
            }
            .list:hover{
              background: #08245570;
              color:  #fff;
            }
          }
          .inputSelect::-webkit-scrollbar{      
            display:none    
          }
        
        }
         img{
          width: 70%;
        }
      }
      .liveBox{
        flex: 1;
        // border: 1px solid red;
        .videoBox{
          // width: 100px;
          // height: 100px;
          // border: 1px solid green;
        }
      }
 
    }
  }
  .middle{
    width: 58%;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .middleBox{
      width: 100%;
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      .equipment{
        height: 100%;
        width: 45%;
        // border: 1px solid red;
         background: url(../../public/images/border2.png) no-repeat;
         background-size:100% 100%;
      }
    }
    .middle1{
      height: 13%;
      .equipment{
        display: flex;
        justify-content: space-between;
        padding: 20px;
        .minBox{
          width: 45%;
          height: 100%;
          // border: 1px solid red;
          display: flex;
          align-items: center;
          img{
              width: 35px;
              // height: 35px;
              // border: 1px solid red;
              margin: 0 20px;
            }
          .text{
            // border: 1px solid red;
            p{
              text-align: left;
            }
            p:nth-child(1){
              font-size: 16px;
              margin-bottom: 4px;
              color: #fff;
            }
             p:nth-child(2){
              font-size: 18px;
              font-weight: 550;
              color: rgb(0, 234, 255);
            }
          }
        }
        .line{
          height: 100%;
          width: 1px;
          background: rgb(13, 112, 203);
        }
        .equipmentState{
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
         p{
            padding-left: 20px;
            color: #fff;
            font-size: 15px;
          .dot{
            display: inline-block;
            width: 10px;
            height: 10px;
            // border: 1px solid red;
             background: rgb(52, 209, 96);
             border-radius: 50%;
            }
            .quantity{
              font-weight: 550;
            }
          }
           p:nth-child(1){
            .quantity{
              color: rgb(52, 209, 96);
            }
          }
          p:nth-child(2){
            .dot{
              background: rgb(235, 42, 42);
            }
          }
        }
      }
    }
     .middle2{
      height: 82%;
      background: url(../../public/images/border3.png) no-repeat;
      background-size:100% 100%;
      padding: 25px;
      display: flex;
      align-items: center;
      .middle2Box{
        width: 100%;
        height: 80%;
        // // border: 1px solid red;
        // background: url("../../public/images/ceshi1.jpg") no-repeat center center;
        // background-size: 100%;
        display: flex;
        img{
          width: 100%;
          // height: 80%;
        }
      }
    }
  }
  .right{
    width: 20%;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .rightBox{
      width: 100%;
      // border: 1px solid red;
    }
    .right1{
      height: 55%;
      background: url(../../public/images/border4.png) no-repeat;
      background-size:100% 100%;
      padding:  35px 25px;
      display: flex;
      flex-direction: column;
      .inputBox{
        width: 100%;
        height: 50px;
        // border: 1px solid red;
        background: url(../../public/images/picker-back.png) no-repeat;
        background-size:100% 100%;
        margin-bottom: 10px;
        /deep/.el-select{
          width: 90%;
          margin-top: 5px;
          input{
            background: rgba(255, 255, 255, 0);
            border: 1px solid rgba(255, 255, 255, 0);
            color: #fff;
          }
        
        }
      }
         
      

      .dataBox{
       flex: 1;
      //  border: 1px solid red;
       display: flex;
       flex-wrap: wrap;
       align-content: flex-start;
       overflow: scroll;
       .min-data{
        width: 50%;
        height: 100px;
        // border: 1px solid red;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        border-radius: 5px;
        img{
          //  border: 1px solid red;
          width: 50px;
          height: 50px;
        }
        .text{
          width: 55%;
          margin-left: 5px;
          //  border: 1px solid red;
           p{
            line-height: 20px;
           }
           p:nth-child(1){
            color: #fff;
            font-size: 14px;
           }
             p:nth-child(2){
            color: #00fbe8;
            font-size: 14px;
            font-weight: 550;
           }
        }
       }
       .min-data:hover{
        background: rgba(255, 255, 255, 0.16);
       }
      }
      ::-webkit-scrollbar {
         display: none;
      }
    }
    .right2{
      // border: 1px solid red;
      height: 40%;
      background: url(../../public/images/border1.png) no-repeat;
      background-size:100% 100%;
      padding: 25px;
    
      .title{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        // border: 1px solid red;
         img{
          width: 70%;
        }
      }
      .content{
        height: 80%;
         overflow: scroll;
        .list{
          height: 30%;
          border-bottom: 1px solid #082455;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          // border: 1px solid red;
          user-select: none;
          cursor: pointer;
          .icon{
            margin-right: 10px;
            img{
              width: 40px;
            }
          }
        }
        .list:hover{
          background: rgba(6, 124, 200, 0.3);
        }
        .data{
          margin-left: 5px;
          width: 90%;
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p{
            text-align: left;
            line-height: 17px;
          }
          p:nth-child(1){
            color: rgb(238, 68, 68);
          }
           p:nth-child(2){
            color: #7fb4f6;
          }
        }
      }
    }
        ::-webkit-scrollbar {
         display: none;
      }
  }
}

</style>

import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import guide from "../views/guide.vue";
import oneMap from "../views/oneMap.vue";
import gisMap from "../views/gisMap.vue";
import greenhouse from "../views/greenhouse.vue";
import ceshi from "../views/ceshi.vue";
import dataPlatform from "../views/dataPlatform.vue";
import specialist from "../views/specialist.vue";
import kap from "../views/kap.vue";
import traceability from "../views/traceability.vue";
import ec from "../views/ec.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/guide",
    name: "Guide",
    component: guide,
  },
  {
    path: "/oneMap",
    name: "OneMap",
    component: oneMap,
  },
  {
    path: "/gisMap",
    name: "GisMap",
    component: gisMap,
  }, 
  {
    path: "/greenhouse",
    name: "Greenhouse",
    component:greenhouse,
  },
    {
    path: "/ceshi",
    name: "Ceshi",
    component:ceshi,
  },
  {
    path: "/dataPlatform",
    name: "dataPlatform",
    component: dataPlatform,
    children: [
      {
        path: '/',
        name:"专家库",
        component:specialist
      },
      {
        path: 'kap',
        name:"病毒知识库",
        component:kap
      },
      {
        path: 'traceability',
        name:"溯源分析",
        component:traceability
      },
      {
        path: 'ec',
        name:"电商平台",
        component:ec
      },
    ]
  },    //数据平台相关
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },

];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
<!-- v-loading="loading"  -->
  <div class="kap" 
  v-loading="loading"
  element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    element-loading-text="拼命加载中">
    <div class="header">
    </div>
    <div class="min-header"></div>
    <div class="kapContent">
      <div class="left">
        <!-- 知识数据 -->
        <div class="quantity">
          <div class="min-icon">
            <div class="text"><span class="number">{{knowledgeData.total}}</span> 个</div>     
          </div>
          <div class="min-icon">
             <div class="text"><span class="number">{{knowledgeData.cate}}</span> 个</div>    
          </div>
          <div class="min-icon">
             <div class="text"><span class="number">{{knowledgeData.views}}</span> 个</div>    
          </div>
        </div>
        <!-- 滚轮 -->
        <div class="scrollBar">
          <div class="list" v-for="(item,index) in knowledgeList" :key="index" @click="popupClick(item)">
            <div class="valuetext">{{item.name}}</div>
            <div class="typeText">{{item.cateName}}</div>
          </div>
        </div>
        <!-- 这个是弹窗 -->
        <div class="popupBox" v-show="popupShow">
          <div class="popupContent">
             
            <div class="cancelBox" @click="cancelClick">
                <i class="el-icon-circle-close"></i>
            </div>
            <div class="articleTitle">
              <p>{{detailsData.name}}</p>
              <p> <span>{{detailsData.time}}</span> &nbsp;&nbsp;&nbsp;<span>{{detailsData.cateName}}</span></p>
              <div class="line line1"></div>
              <div class="line line2"></div>
            </div>
            <div class="articleContent" v-html="detailsData.desc">
            </div>
          </div>
        </div>
      </div>
      <!-- 生命树 -->
      <div class="right">
        <div class="rightContent" @click="listData()">
          <div class="ferrisWheel">
            <div class="spin" v-for="(item,index) in categoricalData" :key="index" @click.stop="listData(item.id)">
            <img :src="item.img" alt="">
            <p>{{item.name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { agricultureCate,agricultureArticle,agricultureData} from '../api/api'
export default {
  data () {
    return {
      categoricalData:[], //分类数据
      detailsData:{},     //详情数据
      popupShow:false,
      loading:true,
      knowledgeList:[],
      knowledgeData:{
        total:0,
        cate:0,
        views:0,
      }, //知识数据
    }
  },
  created(){
    this.onLoad()
    this.listData()
  },
  methods: {
    cancelClick(){
      this.popupShow=false
    },
    popupClick(data){
      this.detailsData=data
      // console.log(data,99999);
      this.popupShow=true
    },
    onLoad(){
      let obj ={
        parent_id:'1'
      }
      agricultureCate(obj).then(res=>{
        console.log(res.data.list,8888);
        this.categoricalData=res.data.list
      })


      agricultureData().then(res=>{
        console.log(res.data,4545454);
        this.knowledgeData=res.data
       
      })
    },
    listData(data){
      let obj1 ={
       page:1,
       list_rows:10,
       cate_id:data
      }
      agricultureArticle(obj1).then(res=>{
        let list=[]
        let oldList = res.data.list.data
        console.log(oldList,"89898");
        oldList.forEach(element => {
          let obj={}
          obj.name=element.name,
          obj.cateName=element.cate_name.toString().replace(/,/ig,'/'),
          obj.value=element.introduce,
          obj.time=element.create_time,
          obj.desc=element.desc,
          list.push(obj)
        });
        this.knowledgeList=list
         this.loading=false
      })
    }
  },
}
</script>

<style scoped lang="less">
@media screen and (max-width:1600px){
.ferrisWheel{
   top: 11% !important;
}
}
@media screen and (max-width:1500px){
.ferrisWheel{
   top: 12% !important;
}
}
@media screen and (max-height:900px){
.ferrisWheel{
   top: 11.5% !important;
}
}
@media screen and (max-height:830px){
.ferrisWheel{
   top: 13% !important;
}
}
.kap{
  width: 100%;
  height: 100%;
  // border: 1px solid red;
  padding:0px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header{
    width: 95%;
    height: 5vh;
    // border: 1px solid red;
    background: url("../../public/images/SYtitle1.png") no-repeat;
    background-size: 100%;
  }
  .min-header{
    //  border: 1px solid red;
    width: 200px;
    height: 4vh;
    background: url("../../public/images/SYtitle2.png") no-repeat;
    background-size: 100%;
  }
  .kapContent{
    width:95%;
    height: 75vh;
    // border: 1px solid red;
    display: flex;
    margin-top: 1vh;
    .left{
      width: 60%;
      height: 100%;
      // border: 1px solid red;
      display: flex;
      margin-right: 2%;
      justify-content: space-between;
      //  知识数据 
      .quantity{
        width: 18%;
        height: 100%;
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .min-icon{
          height: 30%;
          user-select: none;
          cursor: pointer;
          .text{
            margin-top: 15%;
             color: transparent;
              background: linear-gradient(to bottom,#cff4fb, #2dcaea);
              background-clip: text;
              -o-background-clip: text;
              -ms-background-clip: text;
              -moz-background-clip: text;
              -webkit-background-clip: text;
            .number{
              font-size: 30px;
              font-weight: 550;
             
            }
          }
        }
        .min-icon:nth-child(1){
         background: url('../../public/images/syicon2.png') no-repeat center center;
         background-size: 100%;
        }
         .min-icon:nth-child(2){
         background: url('../../public/images/syicon3.png') no-repeat center center;
         background-size: 100%;
        }
          .min-icon:nth-child(3){
         background: url('../../public/images/syicon1.png') no-repeat center center;
         background-size: 100%;
        }
      }
      // 滚轮
      .scrollBar{
        width: 75%;
        height: 92%;
        // border: 1px solid red;
        margin-top: 2vh;
        box-sizing: border-box;
        overflow: auto;
        .list{
          // border: 1px solid red;
          width: 100%;
          height: 5vh;
          margin-bottom: 2vh;
          background: url("../../public/images/listBG.png") no-repeat;
          background-size: 98% 100%;
          color: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          cursor: pointer;
          .valuetext{
            margin-left: 8%;
          }
          .typeText{
              margin-right: 8%;
              color: #03e1dc;
          }
        }
        .list:hover{
          background: url("../../public/images/listBG1.png") no-repeat;
          background-size: 98% 100%;
          // height: 6vh;
        }
      }
      // 这个是弹窗
      .popupBox{
        position: fixed;
        inset: 0;
        background: rgba(0, 0, 0, 0.665);
        // border: 1px solid red;
        z-index: 999;
        
        .popupContent{
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          width: 900px;
          height: 650px;
          // border: 1px solid rgb(0, 255, 60);
          background: url('../../public/images/popupImg.png') no-repeat;
          background-size: 100% 100%;
          padding: 2% 3%;
          .cancelBox{
            // border: 1px solid red;
            width: 30px;
            height: 30px;
            position: absolute;
            right: -30px;
            top: -30px;
            i{
              font-size: 30px;
              color: rgba(255, 255, 255, 0.6);
              cursor: pointer;
              user-select: none;
            }
            i:hover{
              color: rgba(255, 255, 255, 0.9);
            }
          }
          .articleTitle{
            width: 100%;
            height: 20%;
            // border: 1px solid red;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            border-bottom:1px solid #025f96;
            position: relative;
            p:nth-child(1){
              font-size: 20px;
              color: #fff;
            }
            p:nth-child(2){
              font-size: 12px;
              color: #8190b8;
            }
            .line{
              position: absolute;
              bottom: -1px;
              width: 100px;
              height: 1px;
              background: #2fdaff;
            }
            .line1{
              left: 0;
            }
            .line2{
              right: 0;
            }
          }
          /deep/.articleContent{
           padding: 2% 0;
            width: 100%;
            height: 80%;
            // border: 1px solid red;
            color: #fff;
            line-height: 25px;
            overflow: auto;
            margin-top: 10px;
            img{
              width: 400px;
            }
            p{
            text-align: left;
            margin-bottom: 1%;
            }
          }
            /*滚动条样式*/
       .articleContent::-webkit-scrollbar {
            width: 5px;    
        }
        .articleContent::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: #03e1dc;
        }
        .articleContent::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 5px;
            background: #002969;

        }
        }
      }
         /*滚动条样式*/
       .scrollBar::-webkit-scrollbar {
            width: 5px;    
        }
        .scrollBar::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: #03e1dc;
        }
        .scrollBar::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 5px;
            background: #002969;

        }

    }
    .right{
      width: 680px;
      height: 100%;
      // border: 1px solid red;
      .rightContent{
        width: 100%;
        height: 100%;
        // border: 1px solid rgb(0, 255, 85);
        background: url("../../public/images/rightKapBg.png") no-repeat;
        background-size: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        .ferrisWheel{
          position: absolute;
          // left: 50%;
          top: 10%;
          //  transform: translateX(-50%);
          //  border: 1px solid red;
          // margin-top: 10%;
          width: 20vw;
          height: 20vw;
          border-radius: 10vw;
          border: 2px dashed #048fc67b;
          animation: Circle 20s linear infinite;
          //  transform: translateX(-50%);
            /deep/.spin{
              width: 7vw;
              height: 7vw;
              // border: 1px solid red;
              border-radius: 50%;
              position: absolute;
              color: #fff ;
              animation: minCircle 20s linear infinite;
              background: url("../../public/images/xuanzhuan.png") no-repeat center center;
              // center center
              background-size: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              user-select: none;
              cursor: pointer;
              img{
                 width: 50px;
                }
                p{
                 color: #fff;
                 margin-top: 10px;
                }
            }
            .spin:hover{
              width: 7.5vw;
              height: 7.5vw;
              // animation-name: none;
            }
            .spin:nth-child(1){
              top:-1.5vw;
              left: -1.5vw;
            }
            .spin:nth-child(2){
              top: -1.5vw;
              right: -1.5vw;
            }
            .spin:nth-child(3){
              bottom: -1.5vw;
              left: -1.5vw;
            }
            .spin:nth-child(4){
              bottom: -1.5vw;
              right: -1.5vw;
            }
        }
      }
      
    }
     @keyframes Circle { /*父元素顺时针旋转*/
      from { transform: rotate(0deg);}
      to { transform: rotate(360deg);}
    }
      @keyframes minCircle { /*子元素顺时针旋转*/
      from { transform: rotate(0deg);}
      to { transform: rotate(-360deg);}
    }
  }
}
</style>

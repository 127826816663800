
// index文件我们是辅助API进行统一的管理的
import requests from "./request";
 
// //发请求：axios发请求返回结果promise对象
export const screenIndex = () => requests({
  url:"/screen/xs.index/index",
  method: 'post'
});

export const screenData = () => requests({
url:"/screen/xs.index/data",
method: 'post'
});


// 大棚数据//////////////////////////////////////
// 一张图详情页  详情信息
export const screenDetail = (data) => requests({
  url:"/screen/xs.index/detail",
  method: 'post',
  data:data
});
// 一张图详情页  视频和网关信息
export const screenDevice = (data) => requests({
  url:"/screen/xs.index/device",
  method: 'post',
  data:data
});
export const screenSearch = (data) => requests({
  url:"/screen/xs.index/search",
  method: 'post',
  data:data
});


// 病虫害预防/////////////////////////////////////
//分类
export const agricultureCate = (data) => requests({
  url:"/screen/xs.Specialist/cate",
  method: 'post',
  data:data
});
//列表
export const agricultureArticle = (data) => requests({
  url: "/screen/xs.Specialist/article",
  method: 'post',
  data: data
});
// 基础信息
export const agricultureData = (data) => requests({
    url:"/screen/xs.Specialist/data",
    method: 'post',
    data:data
});
    
// 专家库/////////////////////////////////////////////
// 基础信息
export const expertData = (data) => requests({
  url:"/screen/xs.Specialist/index",
  method: 'post',
  data:data
});
// 专家列表
export const expertList = () => requests({
  url:"/screen/xs.Specialist/user",
  method: 'post',
});
// 专家列表
export const expertApi = (data) => requests({
  url:"/screen/panelPage/api",
  method: 'post',
  data:data
});
// 天气接口
export const weather = () => requests({
  // dataType: "json",
  // async: false,
  //线上
  url: "https://v0.yiketianqi.com/api",  
  // url:"/free/day",
  method: 'get',
  params: {
    appid:82456198,
    appsecret:'GEuiCx1q',
    version:'v62',
    city:'响水'
  }
});
// 警报天气接口?
export const jbWeather = () => requests({
  // dataType: "json",
  // async: false,
  baseURL: "https://v0.yiketianqi.com",  //线上
  url:"/api",
  method: 'get',
  params: {
    unescape:1,
    version:'v9',
    appid:59677946,
    appsecret:'Myh67TTS',
    city:'盐城'
  }
});
// 警报天气接口
export const getWeather = () => requests({
  url:"/screen/xs.index/waring",
  method: 'get'
});

// WebSocket 接收预警信息 和 天气预警信息
export const wsBind = (data) => requests({
  url:"/workerman/user/screenBind",
  method: 'post',
  data:data
});

// 电商平台////////////////////////////////////
// 周均价格
export const marketConditionAvg = (data) => requests({
  url:"/screen/xs.market/avg",
  method: 'post',
  data:data
});

// 市场价格
export const marketConditionMarketPrice = (data) => requests({
  url:"/screen/xs.market/marketPrice",
  method: 'post',
  data:data
});

// 地区价格
export const marketConditionInfo = (data) => requests({
  url:"/screen/xs.market/info",
  method: 'post',
  data:data
});

// 实时数据（价格涨跌)
export const marketConditionRtp = (data) => requests({
  url:"/screen/xs.market/rtp",
  method: 'post',
  data:data
});

// 溯源分析////////////////////////////////////
   
// 溯源列表
export const traceabilityList = (data) => requests({
  url:"/screen/xs.Batch/list",
  method: 'post',
  data:data
});

// 溯源数据
export const traceabilityData = (data) => requests({
  url:"/screen/xs.Batch/data",
  method: 'post',
  data:data
});

// 溯源品种分析
export const traceabilityViews = (data) => requests({
  url:"/screen/xs.Batch/views",
  method: 'post',
  data:data
});


// 一张图 监控列表
export const vedioList = (data) => requests({
  url:"/screen/xs.batch/vedio",  
  method: 'post',
  data:data
});

//摄像头放大缩小
export const move = (data) => requests({
  url:"/device/api/Camera/move",  
  method: 'post',
  data:data
});
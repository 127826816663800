<template>
  <div class="oneMapShell" id="container">
    <div class="layoutTop"></div>
    <div class="layoutleft"></div>
    <div class="layoutbottom"></div>
    <div class="layoutright"></div>
    <div class="top">
      <p>江苏响水西兰花产业数字化一张图</p>
      <!-- <div class="ceshi" @click="ceshi">大棚数据</div> -->
      <!-- <div class="homePage" @click.stop="homeClick(6)"><i class="el-icon-arrow-left"></i>返回首页</div> -->
    </div>
    <div class="middle">
      <div class="amount amountBlue">
        <p>{{ homeData.total }}</p>
        <p>种植用户数量</p>
      </div>
      <div class="amount amountYellow">
        <p>{{ homeData.mass }}</p>
        <p>地块数量</p>
      </div>
      <div class="amount amountBlue">
        <p>{{ devData + devEnvData + equipment.devGateway }}</p>
        <p>物联网设备数量</p>
      </div>
      <div class="amount amountYellow">
        <p>{{ homeData.base_area }}</p>
        <p>基地面积(亩)</p>
      </div>
    </div>
    <div class="bottom">
      <div class="optionsBox">
        <div
          class="option"
          :class="showSoilSensor3 ? 'option-select' : ''"
          @click="wacthClick3"
        >
          <p>监 控</p>
        </div>
        <div
          class="option"
          :class="showSoilSensor2 ? 'option-select' : ''"
          @click="wacthClick2"
        >
          <p>气象站</p>
        </div>
        <div
          class="option"
          :class="showSoilSensor ? 'option-select' : ''"
          @click="watchClick"
        >
          <p>传感器</p>
        </div>
        <!-- <div class="option">
          <p>智能控制柜</p>
        </div> -->
      </div>
      <div class="bottomDecorate"></div>
    </div>

    <div class="left">
      <!-- 原功能:点击搜索框 -->

      <div class="leftSousuo">
        <!-- <div class="sousuoIcon"></div> -->
        <!-- <div class="sousuoInput">
          <input type="text" placeholder="搜索产地名称" @focus="originFocus" v-model="inputValue">
          <span class="sousuoText" @click="originFocus">搜索</span>
        </div> -->
        <div class="co">产地</div>
        <!-- 搜索弹窗 -->

        <div class="inputBox">
          <!-- <vue-seamless-scroll :data="searchData" :class-option="optionSingleHeightTime2" class="seamless-warp"> -->
          <div
            class="inputList"
            :class="item.select ? 'select' : ''"
            v-for="(item, index) in searchData"
            :key="index"
            @click="handClick(item)"
          >
            {{ item.base_name }}
          </div>
          <!-- </vue-seamless-scroll> -->
        </div>
      </div>

      <div class="leftIntroduce">
        <div class="introduceTitle">西兰花产业介绍</div>
        <div class="introduceContent">
          <!-- <img src="../../public/images/xlh.jpg" alt=""> -->
          <div v-if="carouselData" class="slideshow">
            <div class="block">
              <el-carousel height="200px">
                <el-carousel-item
                  v-for="(item, index) in carouselData"
                  :key="index"
                >
                  <!-- <h3 class="small">{{ item }}</h3> -->
                  <img :src="item" alt="" />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <!-- v-html="textIntroduction" -->
          <div class="introduceDetails" v-html="textIntroduction">
            <!-- <p>
            西兰花是南河镇的主导产业，南河镇位于“中国西兰花之乡”响水县腹地，是国家地理标志农产品“响水西兰花”的核心产区，近年来先后获得了全国一村一品（西兰花）特色村镇、全国西兰花特色产业强镇、江苏省蔬菜（毛豆）特色镇等荣誉称号。
          </p>
          <p>一是种植规模大。南河镇西兰花年种植规模近4万亩，其中春季西兰花种植面积15870亩，秋季西兰花24120亩，其种植模式主要有：西兰花+毛豆-西兰花、西兰花-辣椒、西瓜-西兰花、西兰花-稻（麦）等种植模式，形成了以西兰花为主、毛豆、西瓜、辣椒等为辅的“西兰花+”产业格局。西兰花订单销售达60%以上、种苗订单销售达100%。西兰花大道东延至海七线，在我镇境内正线长达10公里，我镇将致力打造以西兰花为特色的农旅景观带，并融入盐城市全域旅游线路。</p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="right">
      <!-- 返回按钮 -->
      <div class="skipContainer">
        <div class="skipShell skipShell2" @click.stop="houseClick()">
          <div class="skipShellText">实时监控</div>
        </div>
        <div class="skipShell" @click.stop="homeClick()">
          <div class="skipShellText">返回首页</div>
        </div>
      </div>

      <!-- 三个 要改的地方   -->
      <div class="smallModule">
        <!-- 监控 -->
        <div class="moduleList" @click="getVedioList">
          <div class="moduleListTop">
            <div class="icon"></div>
            <div class="data">
              <p>监控</p>
              <p>{{ equipment.devVdo }}</p>
            </div>
          </div>
          <div class="moduleListBottom">
            <div class="onLine">
              <span class="dot"></span> <span class="text">在线</span
              ><span class="variable">{{ equipment.devVdo }}</span>
            </div>
            <div class="offline">
              <span class="dot"></span><span class="text">离线</span
              ><span class="variable">0</span>
            </div>
          </div>
        </div>

        <div class="moduleList">
          <div @click="onSkip" class="moduleListTop">
            <div class="icon"></div>
            <div class="data">
              <p>气象站</p>
              <p>{{ equipment.devEnvEqv }}</p>
            </div>
          </div>
          <div class="moduleListBottom">
            <div class="onLine">
              <span class="dot"></span> <span class="text">在线</span
              ><span class="variable">{{ equipment.devEnvEqv }}</span>
            </div>
            <div class="offline">
              <span class="dot"></span><span class="text">离线</span
              ><span class="variable">0</span>
            </div>
          </div>
        </div>

        <div class="moduleList">
          <div @click="onSkip" class="moduleListTop">
            <div class="icon"></div>
            <div class="data">
              <p>传感器</p>
              <p>{{ equipment.devGateway }}</p>
            </div>
          </div>
          <div class="moduleListBottom">
            <div class="onLine">
              <span class="dot"></span> <span class="text">在线</span
              ><span class="variable">{{ equipment.devGateway }}</span>
            </div>
            <div class="offline">
              <span class="dot"></span><span class="text">离线</span
              ><span class="variable">0</span>
            </div>
          </div>
        </div>
        <!-- <div class="moduleList">
               <div class="moduleListTop">
              <div class="icon"></div>
              <div class="data">
                <p>智能控制柜</p>
                <p>10</p>
              </div>
            </div>
            <div class="moduleListBottom">
              <div class="onLine"><span class="dot"></span> <span class="text">在线</span><span class="variable">8</span></div>
               <div class="offline"><span class="dot"></span><span class="text">离线</span><span class="variable">2</span></div>
            </div>
          </div> -->
      </div>
      <!-- 改  -->
      <div class="information">
        <div class="title">基地数据信息</div>
        <div class="content">
          <div class="table_th">
            <div class="th1 th_style">基地编号</div>
            <div class="th2 th_style">基地名称</div>
            <div class="th3 th_style">所属人</div>
            <div class="th4 th_style">基地规模</div>
          </div>
          <div class="tr_content">
            <vue-seamless-scroll
              :data="tableData"
              :class-option="optionSingleHeightTime"
              class="seamless-warp"
            >
              <ul class="item">
                <li
                  v-for="(item, index) in tableData"
                  :key="index"
                  :class="{ evenNumber: index % 2 == 0 }"
                >
                  <div class="tr1 tr_style">{{ item.base_identifier }}</div>
                  <div class="tr2 tr_style">{{ item.base_name }}</div>
                  <div class="tr3 tr_style">{{ item.name }}</div>
                  <div class="tr4 tr_style">{{ item.base_area }}亩</div>
                </li>
                <li
                  v-for="(item, index) in tableData"
                  :key="item.base_identifier"
                  :class="{ evenNumber: index % 2 == 0 }"
                >
                  <div class="tr1 tr_style">{{ item.base_identifier }}</div>
                  <div class="tr2 tr_style">{{ item.base_name }}</div>
                  <div class="tr3 tr_style">{{ item.name }}</div>
                  <div class="tr4 tr_style">{{ item.base_area }}亩</div>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>

    <!-- 监控的弹出框 -->
    <div class="popUpBox" v-if="monitoringShow">
      <div class="content">
        <div class="cancel" @click="monitoringEndClick">
          <i class="el-icon-circle-close"></i>
        </div>
      </div>
    </div>
    <!-- 气象弹出框 -->
    <popUpBox
      class="pop"
      v-show="visible"
      :sensorScreenData="sensorScreenData"
      :sensorScreenName="sensorScreenName"
      @popUpNum="popUpNum"
    ></popUpBox>
    <div class="liveShell" v-show="popupShow" ref="videoShell">
      <div class="liveBox" id="playerBox" ref="video">
        <div class="cancel" @click="closeClick">
          <i class="el-icon-circle-close"></i>
        </div>
        <!-- 清橙云视频组件 -->
        <div id="app">
          <div class="video" v-loading="moveLoading" element-loading-text="正在操作中" element-loading-background="rgba(0, 0, 0, 0.8)">
            <!-- <div id="player"></div> -->
            <template v-for="item in visibleVideoList">
              <new-video
                v-if="curIndex == 0"
                class="newVideo"
                :videoUrl="item.url"
                :activeIndex="item.id"
                :class="{
                  newVideo1:
                    selectValue == '11' ||
                    selectValue == '13' ||
                    selectValue == '14'
                }"
                @click.native="monitoringClick2(item)"
              ></new-video>
              <new-video
                v-else-if="curIndex == 1"
                class="newVideo"
                :videoUrl="item.url"
                :activeIndex="item.id"
                @click.native="monitoringClick2(item)"
              ></new-video>
              <new-video
                v-else-if="curIndex == 2"
                class="newVideo"
                :videoUrl="item.url"
                :activeIndex="item.id"
                @click.native="monitoringClick2(item)"
              ></new-video>
              <new-video
                v-else
                class="newVideo"
                :videoUrl="item.url"
                :activeIndex="item.id"
                :class="{
                  newVideo1: selectValue == ''
                }"
                @click.native="monitoringClick2(item)"
              ></new-video>
            </template>
          </div>
          <div class="btn-groups" v-if="btnCtrl">
            <div class="inputBox selectBox" v-if="!curIndex" >
              <el-select v-model="selectValue" placeholder="请选择">
                <el-option value="" label="全部"> </el-option>
                <el-option value="12" label="昌盛示范园"> </el-option>
                <el-option value="11" label="薛荡"> </el-option>
                <el-option value="13" label="头甲"> </el-option>
                <el-option value="14" label="王集"> </el-option>
              </el-select>
            </div>
            <el-button
              type="primary"
              class="videoBtn"
              @click="changeVideoList(0)"
              >第一页</el-button
            >
            <el-button
              type="primary"
              class="videoBtn"
              v-if="selectValue == '12' || selectValue == ''"
              @click="changeVideoList(1)"
              >第二页</el-button
            >
            <el-button
              type="primary"
              class="videoBtn"
              v-if="selectValue == '12' || selectValue == ''"
              @click="changeVideoList(2)"
              >第三页</el-button
            >
            <el-button
              type="primary"
              class="videoBtn"
              v-if="selectValue == ''"
              @click="changeVideoList(3)"
              >第四页</el-button
            >
          </div>
          <div v-else class="return">
            <div style="margin-right: 50px" v-if="mointorType == 1">
              <el-button type="primary" @click="moveBtn(8)">放大</el-button>
              <el-button type="primary" @click="moveBtn(9)">缩小</el-button>
            </div>
            <el-button type="primary" @click="reset()">返回</el-button>
            <div class="move" style="margin-left: 50px" v-if="mointorType == 1">
              <el-button type="success" @click="moveBtn(0)">↑</el-button>
              <el-button type="success" @click="moveBtn(1)">↓</el-button>
              <el-button type="success" @click="moveBtn(2)">←</el-button>
              <el-button type="success" @click="moveBtn(3)">→</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 监控弹窗2 -->
    <div class="liveShell liveShell2" v-show="popupShow2" ref="videoShell">
      <div class="liveBox liveBox2" id="playerBox" ref="video">
        <div class="cancel" @click="closeClick">
          <i class="el-icon-circle-close"></i>
        </div>
        <!-- 清橙云视频组件 -->
        <div id="app">
          <div class="video video2">
            <!-- <div id="player"></div> -->
            <template v-for="item in visibleVideoList2">
              <new-video
                class="newVideo2"
                :videoUrl="item.url"
                :activeIndex="item.id"
              ></new-video>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  screenIndex,
  screenData,
  screenSearch,
  screenDevice,
  vedioList,
  move
} from "../api/api";
import AMapLoader from "@amap/amap-jsapi-loader";
import img1 from "@/assets/img/jiufeng.png";
import xuedang from "@/assets/img/xuedang.png";
import changsheng from "@/assets/img/changsheng.png";
import wangji from "@/assets/img/wangji.png";
import toujia from "@/assets/img/toujia.png";
//引入弹出框
import popUpBox from "./popUpBox.vue";
import { log } from "video.js/dist/video.min";
window._AMapSecurityConfig = {
  securityJsCode: "1ba9dd487190da12ef06d6d024e501bb"
};
export default {
  inject: ["reload"],
  components: { popUpBox },
  data() {
    return {
      showSoilSensor: false, //传感器显示隐藏
      markers: [], //保存传感器标记的数组
      showSoilSensor2: false, //气象站显示隐藏
      markers2: [], //保存气象站标记的数组
      showSoilSensor3: true, //监控显示隐藏
      markers3: [], //保存监控标记的数组
      mointorId: 0, //摄像头id
      mointorType: 0, //摄像头type
      moveLoading:false,
      isShow: false,
      videoList: [
        {
          id: 1,
          url: "http://cmgw-vpc.lechange.com:8888/LCO/8F0ACAFPAZ60FB3/3/0/20230417T010558/f4a2dd6c88825e27b590773f115c9407.m3u8"
        },
        {
          id: 1,
          url: "http://cmgw-vpc.lechange.com:8888/LCO/8F0ACAFPAZ60FB3/3/0/20230417T010558/f4a2dd6c88825e27b590773f115c9407.m3u8"
        },
        {
          id: 1,
          url: "http://cmgw-vpc.lechange.com:8888/LCO/8F0ACAFPAZ60FB3/3/0/20230417T010558/f4a2dd6c88825e27b590773f115c9407.m3u8"
        },
        {
          id: 1,
          url: "http://cmgw-vpc.lechange.com:8888/LCO/8F0ACAFPAZ60FB3/3/0/20230417T010558/f4a2dd6c88825e27b590773f115c9407.m3u8"
        },
        {
          id: 1,
          url: "http://cmgw-vpc.lechange.com:8888/LCO/8F0ACAFPAZ60FB3/3/0/20230417T010558/f4a2dd6c88825e27b590773f115c9407.m3u8"
        },
        {
          id: 1,
          url: "http://cmgw-vpc.lechange.com:8888/LCO/8F0ACAFPAZ60FB3/3/0/20230417T010558/f4a2dd6c88825e27b590773f115c9407.m3u8"
        },
        {
          id: 1,
          url: "http://cmgw-vpc.lechange.com:8888/LCO/8F0ACAFPAZ60FB3/3/0/20230417T010558/f4a2dd6c88825e27b590773f115c9407.m3u8"
        },
        {
          id: 1,
          url: "http://cmgw-vpc.lechange.com:8888/LCO/8F0ACAFPAZ60FB3/3/0/20230417T010558/f4a2dd6c88825e27b590773f115c9407.m3u8"
        },
        {
          id: 1,
          url: "http://cmgw-vpc.lechange.com:8888/LCO/8F0ACAFPAZ60FB3/3/0/20230417T010558/f4a2dd6c88825e27b590773f115c9407.m3u8"
        }
      ],
      equipment: {}, //三个设备
      sensorScreenData: [], //这个是传感器屏幕数据
      sensorScreenName: {}, //这个是传感器信息
      userId: 4, //用户id
      attributeTable: [
        //属性表 (后端那边要这样的了🤦‍♀️)
        {
          type: "a34004",
          name: "PM2.5",
          unit: "ug/m3",
          value: 0
        },
        {
          type: "a34002",
          name: "PM10",
          unit: "ug/m3",
          value: 0
        },
        {
          type: "a01006",
          name: "大气压",
          unit: "KPa",
          value: 0
        },
        {
          type: "a03001",
          name: "降雨量",
          unit: "mm/min",
          value: 0
        },
        {
          type: "a01007",
          name: "风速",
          unit: "米/秒",
          value: 0
        },
        {
          type: "a01008",
          name: "风向",
          unit: "[角]度",
          value: 0
        },
        {
          type: "a01001",
          name: "温度",
          unit: "℃",
          value: 0
        },
        {
          type: "a01002",
          name: "湿度",
          unit: "%RH",
          value: 0
        },
        {
          type: "a03003",
          name: "光照度",
          unit: "Lux",
          value: 0
        }
      ],

      monitoringShow: false, //监控的显隐
      popupShow: false,
      popupShow2: false,
      // inputShow:false, //搜索框内容显隐
      inputValue: "", //搜索框输入内容
      searchData: [], //搜索内容
      textIntroduction: "加载中...",
      carouselData: [], //轮播图Data
      visible: false, //弹出框显示与隐藏
      map: null,
      marker: null,
      tableData: [
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
        // {
        //   id: 'jd00001',
        //   baseName: "云生西蓝花大棚",
        //   userName: "李文丽",
        //   baseSize: "4.6亩",
        // },
      ],
      lnglats: [
        [113.922282, 35.332887],
        [113.963101, 35.318516],
        [113.960801, 35.306263],
        [113.926809, 35.301255]
      ],
      homeData: {}, //首页大屏数据
      devData: 0,
      devEnvData: 0,
      vedioListData: [],
      vedioListData2: [],
      plotId: 12,
      visibleVideoList: [],
      visibleVideoList2: [],
      curIndex: 0,
      curId: -1,
      //控制底部按钮的切换
      btnCtrl: true,
      selectValue: "",
      greenHouseId: 12
    };
  },
  watch: {
    inputValue(val) {
      console.log(val, 8778787);
      let obj = {
        key: val
      };
      this.getScreenSearch(obj);
      // screenSearch(obj).then(res => {
      //   console.log(res.data, 89898);
      //   this.searchData = res.data
      // })
    },
    selectValue(val) {
      this.$nextTick(function () {
        vedioList({ base_id: val }).then((res) => {
          this.vedioListData = res.data.reduce((newArr, { id, url }) => {
            return newArr.concat({
              id,
              url
            });
          }, []);
          this.visibleVideoList = this.vedioListData.slice(0, 9);
          this.selectValue = val;
        });
      });
    }
  },
  computed: {
    optionSingleHeightTime() {
      return {
        // singleHeight: 26,
        // waitTime: 2500,
        step: 1.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 21, // 单步运动停止的高度(默认值0是无缝不停止的滚动direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动direction => 2/3
        waitTime: 1200 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  created() {
    screenData().then((res) => {
      //监控 气象站 传感器
      console.log("数据接口======", res);
      this.equipment = res.data.device;
      this.homeData = res.data;
      this.devData = this.homeData.device.devVdo;
      this.devEnvData = this.homeData.device.devEnvEqv;
      this.textIntroduction = this.homeData.map_des.desc;
      this.carouselData = this.homeData.map_des.imgs;
      // console.log(this.carouselData,999999)

      res.data.user.forEach((item, index) => {
        res.data.user[index].name = item.user.user_name;
        // item.userName=item.user.user_name
      });
      this.tableData = res.data.user;
    });
    screenIndex().then((res) => {
      console.log("screenindex====", res.data);
    });
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
    this.getSearchData();

    // this.$nextTick(() => {
    //   this.createVideo()
    // })
  },
  methods: {
    popUpNum() {
      this.visible = 0;
    },
    monitoringClick2(val) {
      console.log(val, "val22222222");
      this.mointorId = val.id;
      this.mointorType = val.type;
      console.log(this.mointorId, "this.mointorId");
      this.$nextTick(function () {
        this.visibleVideoList = this.visibleVideoList.filter(
          (item) => item.id == val.id
        );
        // console.log(this.visibleVideoList, "this.visibleVideoList123123");
        const videoItem = document.querySelector(".newVideo");

        // const videoItem1 = document.querySelector(".newVideo1");
        videoItem.style.width = "100%";
        videoItem.style.height = "100%";
        // videoItem1.style.width = "100%";
        // videoItem1.style.height = "100%";
        this.btnCtrl = false;
      });
    },
    reset() {
      this.$nextTick(function () {
        this.changeVideoList(this.curIndex);
        const videoItem = document.querySelector(".newVideo");
        videoItem.style.width = "33.33%";
        videoItem.style.height = "33.33%";
        // const videoItem1 = document.querySelector(".newVideo1");
        // videoItem1.style.width = "33.33%";
        // videoItem1.style.height = "50.33%";
        this.btnCtrl = true;
      });
    },
    changeVideoList(index) {
      this.visibleVideoList = [];
      this.$nextTick(function () {
        this.curIndex = index;
        this.visibleVideoList = this.vedioListData.slice(
          index * 9,
          index * 9 + 9
        );
        console.log(this.visibleVideoList, "this.visibleVideoList");
        const video1 = document.querySelectorAll(".vjs-tech");
        console.log(video1, "video122222222222222222");
      });
    },
    onSkip() {
      this.$router.push({ path: "/greenhouse", query: { id: 12 } });
    },

    // 获取监控列表
    getVedioList() {
      this.vedioListData = [];
      vedioList({ base_id: "" }).then((res) => {
        console.log(res.data, "res.data3333333333333");
        let data = res.data;
        data.filter((item) => {
          this.vedioListData.push({
            url: item.url,
            kitToken: item.token,
            id: item.id,
            type: item.type
          });
        });
        this.visibleVideoList = this.vedioListData.slice(0, 9);
        this.popupShow = true;
        // this.createVideo();
      });
    },
    // 获取监控列表
    getVedioList2(index) {
      this.vedioListData2 = [];
      // this.visibleVideoList2 = [];
      vedioList({ base_id: "" }).then((res) => {
        console.log(res.data, "res.data3333333333333");
        let data = res.data;
        data.filter((item) => {
          this.vedioListData2.push({
            url: item.url,
            kitToken: item.token,
            id: item.id,
            type: item.type
          });
        });
        this.visibleVideoList2 = this.vedioListData2.filter((item) => {
          return item.id == index;
        });
        console.log(this.visibleVideoList2, "this.visibleVideoList2");
        // this.visibleVideoList2.push(this.vedioListData2[index]);
        this.popupShow2 = true;
        // this.createVideo();
      });
    },

    // 清橙云视频组件 视频组件========
    createVideo() {
      const player = new ImouPlayer("#player");
      player.setup({
        // 播放器初始化
        src: [...this.vedioListData], // 播放地址
        poster: "", // 封面图url
        width: "100%",
        height: "100%",
        autoplay: true,
        controls: true // 是否展示控制栏
      });
      player.play(); // 播放
      player.setMultiScreen(4);
      player.setVolume(0);
      player.exitFullScreen();
    },

    //视频弹出框的关闭按钮
    closeClick() {
      this.popupShow = false;
      this.reload();
    },

    //图片本地路径的转换
    imgJudgment(data) {
      if (data == "土壤温度") {
        return require("../../public/images/sensor1.png");
      } else if (data == "土壤水分") {
        return require("../../public/images/sensor2.png");
      } else if (data == "光照强度") {
        return require("../../public/images/sensor3.png");
      } else if (data == "二氧化碳") {
        return require("../../public/images/sensor4.png");
      } else if (data == "空气湿度") {
        return require("../../public/images/sensor5.png");
      } else if (data == "空气温度") {
        return require("../../public/images/sensor6.png");
      } else if (data == "风向") {
        return require("../../public/images/sensor19.png");
      } else if (data == "风速") {
        return require("../../public/images/sensor20.png");
      } else if (data == "蒸发量") {
        return require("../../public/images/sensor21.png");
      } else if (data == "降雨量") {
        return require("../../public/images/sensor22.png");
      } else if (data == "大气压力") {
        return require("../../public/images/sensor23.png");
      } else if (data == "土壤含钾量") {
        return require("../../public/images/sensor10.png");
      } else if (data == "土壤含磷量") {
        return require("../../public/images/sensor11.png");
      } else if (data == "土壤含氮量") {
        return require("../../public/images/sensor12.png");
      } else if (data == "PH(土)") {
        return require("../../public/images/sensor13.png");
      } else if (data == "液位") {
        return require("../../public/images/sensor14.png");
      } else if (data == "PH(水)") {
        return require("../../public/images/sensor15.png");
      } else if (data == "水溶氧") {
        return require("../../public/images/sensor16.png");
      } else if (data == "水温度") {
        return require("../../public/images/sensor17.png");
      } else if (data == "土壤电导率") {
        return require("../../public/images/sensor18.png");
      } else {
        return require("../../public/images/sensor18.png");
      }
    },
    //点击产地处理函数
    handClick(val) {
      let item = val.base_name;
      this.searchData.filter((i) => {
        i.select = false;
      });
      this.plotId = val.id;
      val.select = true;
      console.log("item====", item, val);
      //点击移动到对应位置  暂时 可能会改
      if (item === "昌盛示范园") {
        this.greenHouseId = 12;
        this.map.setCenter(new AMap.LngLat(119.7285, 34.235), false, 1500);
      } else if (item === "薛荡") {
        this.greenHouseId = 11;
        //  this.map.setCenter(new AMap.LngLat(119.726,34.2281))
        this.map.panTo(new AMap.LngLat(119.726, 34.2278), 2000, () => {
          console.log("移动完成");
        });
      } else if (item === "头甲") {
        this.greenHouseId = 13;
        //119.797,34.272
        // this.map.setCenter(new AMap.LngLat(119.798127,34.279202))
        this.map.panTo(new AMap.LngLat(119.797057, 34.279422), 2000, () => {
          console.log("移动完成");
        });
      } else if (item === "王集") {
        this.greenHouseId = 14;
        // this.map.setCenter(new AMap.LngLat(119.758,34.2489))
        this.map.panTo(new AMap.LngLat(119.758, 34.24975), 2000, () => {
          console.log("移动完成");
        });
      }
    },
    getSearchData(val) {
      let obj = {
        key: val
      };
      this.getScreenSearch(obj);
      // screenSearch(obj).then(res => {
      //   this.searchData = res.data
      //   console.log('this.searchData', this.searchData);
      // })
    },
    homeClick() {
      this.$router.push("/guide");
    },
    houseClick() {
      this.$router.push({
        path: "/greenhouse",
        query: { id: this.greenHouseId }
      });
    },
    monitoringClick() {
      // this.monitoringShow = true
    },
    monitoringEndClick() {
      this.monitoringShow = false;
    },
    // ceshi(){
    //   this.$router.push('/greenhouse')
    // },
    inputClick(data) {
      this.inputValue = data;
      this.inputShow = false;
    },
    //获取产地
    getScreenSearch(obj) {
      screenSearch(obj).then((res) => {
        console.log(res.data, 89898);
        let data = res.data;
        data.filter((item, index) => {
          if (index == 1) {
            item.select = true;
          } else {
            item.select = false;
          }
        });
        this.searchData = data;
      });
    },

    originFocus() {
      let obj = {
        key: this.inputValue
      };
      this.getScreenSearch(obj);
      this.inputShow = true;
    },

    //下 传感器点击事件
    watchClick() {
      this.showSoilSensor = !this.showSoilSensor;
      if (!this.showSoilSensor) {
        this.map.remove(this.markers);
      } else {
        this.map.add(this.markers);
      }
    },

    //下 气象站点击事件
    wacthClick2() {
      this.showSoilSensor2 = !this.showSoilSensor2;
      if (!this.showSoilSensor2) {
        this.map.remove(this.markers2);
      } else {
        this.map.add(this.markers2);
      }
    },

    //下 监控点击事件
    wacthClick3() {
      this.showSoilSensor3 = !this.showSoilSensor3;
      if (!this.showSoilSensor3) {
        this.map.remove(this.markers3);
      } else {
        this.map.add(this.markers3);
      }
    },
    moveBtn(val) {
      let obj = {
        id: this.mointorId,
        operation: val
      };
      this.moveLoading = true
      move(obj)
        .then((result) => {
          //没数据
          console.log("move接口===", result);
          // uni.hideLoading()
        })
        .catch((err) => {})
        .finally(()=>{
          setTimeout(()=>{
            this.moveLoading = false
          },2000)
          
        })
    },

    initMap() {
      // id 薛荡11 昌盛示范园12 头甲13 王集14

      AMapLoader.load({
        key: "b8758db269635001f50a7a5b76262c86", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.PolylineEditor"] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          // 九丰
          var imageLayer1 = new AMap.ImageLayer({
            url: img1,
            opacity: 1,
            bounds: new AMap.Bounds(
              [119.722399, 34.248558],
              [119.733946, 34.256993]
            ),
            zooms: [15, 20]
          });

          // 薛荡
          var imageLayer2 = new AMap.ImageLayer({
            url: xuedang,
            opacity: 1,
            bounds: new AMap.Bounds(
              [119.720088, 34.223527],
              [119.729201, 34.231301]
            ),
            zooms: [15, 20]
          });

          // 昌盛示范园
          var imageLayer3 = new AMap.ImageLayer({
            url: changsheng,
            opacity: 1,
            bounds: new AMap.Bounds(
              [119.725955, 34.232073],
              [119.732184, 34.237637]
            ),
            zooms: [15, 20]
          });

          // 头甲
          var imageLayer4 = new AMap.ImageLayer({
            url: toujia,
            opacity: 1,
            bounds: new AMap.Bounds(
              [119.792397, 34.276802],
              [119.800677, 34.282392]
            ),
            zooms: [15, 20]
          });

          // 王集
          var imageLayer5 = new AMap.ImageLayer({
            url: wangji,
            opacity: 1,
            bounds: new AMap.Bounds(
              [119.753319, 34.242138],
              [119.763792, 34.253308]
            ),
            zooms: [15, 20]
          });

          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 18, //初始化地图级别
            zooms: [15, 20], //规定地图的展示大小
            center: [119.7285, 34.235], //初始化地图中心点位置
            // center: [119.798127, 34.279202], //初始化地图中心点位置
            layers: [
              //使用多个图层
              new AMap.TileLayer.Satellite(),
              new AMap.TileLayer.RoadNet(),
              imageLayer1,
              imageLayer2,
              imageLayer3,
              imageLayer4,
              imageLayer5
            ]
          });

          let that = this;
          let content1 =
            '<div class="marker-route marker-marker-bus-from nby1" ></div>';
          let marker = new AMap.Marker({
            //响水九丰农博园1
            position: new AMap.LngLat(119.729, 34.236), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            content: content1, // 自定义点标记覆盖物内容
            title: "响水九丰农博园",
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 12 }
          });
          this.markers3.push(marker);
          // let markerList = [marker, marker];
          // this.map.add(markerList)
          marker.on("click", function (e) {
            console.log(e.target, "1111111=======");
            // that.popupShow = true
            that.getVedioList2(8);
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
          });

          // 薛荡  《《
          let content2 =
            '<div class="marker-route marker-marker-bus-from nby2" ></div>';
          let marker1 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.72531, 34.229014), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "响水九丰农博园2",
            content: content2, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 11 }
          });
          marker1.on("click", function (e) {
            // console.log(e.extData,2222);
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
          });
          this.markers.push(marker1);
          let markerList = [marker1, marker];
          // this.map.add(markerList)

          //薛荡枪机1
          let contentX1 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 xd" ></div>';
          let markerX1 = new AMap.Marker({
            position: new AMap.LngLat(119.72571, 34.228514),
            title: "枪机1",
            content: contentX1, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 11 }
          });
          this.markers3.push(markerX1);
          let markerListX1 = [markerX1, marker];
          this.map.add(markerListX1);

          //薛荡枪机2
          let contentX2 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 xd" ></div>';
          let markerX2 = new AMap.Marker({
            position: new AMap.LngLat(119.72521, 34.228514),
            title: "枪机2",
            content: contentX2, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 11 }
          });
          this.markers3.push(markerX2);
          let markerListX2 = [markerX2, marker];
          this.map.add(markerListX2);

          //薛荡球机1
          let contentX31 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let markerX31 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.72531, 34.227014), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机1",
            content: contentX31, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 11 }
          });
          this.markers3.push(markerX31);
          let markerListX31 = [markerX31, marker];
          this.map.add(markerListX31);

          //薛荡球机2
          let contentX32 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let markerX32 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.72571, 34.228214), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机2",
            content: contentX32, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 11 }
          });
          this.markers3.push(markerX32);
          let markerListX32 = [markerX32, marker];
          this.map.add(markerListX32);

          markerX1.on("click", function (e) {
            console.log(e.target.getExtData().id);
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(17);
          });
          markerX2.on("click", function (e) {
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(15);
          });
          markerX31.on("click", function (e) {
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(16);
          });
          markerX32.on("click", function (e) {
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(1);
          });

          // 薛荡  》》

          //userId
          let userId = this.userId;
          //attributeTable
          let attributeTable = this.attributeTable;
          //imgJudgment
          let imgJudgment = this.imgJudgment;

          let content3 =
            '<div class="marker-route marker-marker-bus-from nby3 " ><div class="bg"></div></div>';
          let marker2 = new AMap.Marker({
            //气象站
            position: new AMap.LngLat(119.72925, 34.23448), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "气象站",
            content: content3, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers2.push(marker2);
          //气象站点击事件
          marker2.on("click", () => {
            this.visible = !this.visible;
            console.log(this.visible);
            let obj = {
              id: this.plotId,
              type: "devEnvEqv"
            };
            console.log("obj==", obj);
            // 调用接口
            screenDevice(obj).then((res) => {
              console.log("数据接口", res);
              let data = res.data.detail;
              let meteorologicalStation = [];
              //遍历数据
              data.forEach((item) => {
                attributeTable.map((item1) => {
                  if (item.code == item1.type) {
                    // item.property=item1
                    item.pr_name = item1.name;
                    item.pr_unit = item1.unit;
                    item.pr_value = item.value;
                    item.img = imgJudgment(item1.name);
                    meteorologicalStation.push(item);
                  }
                });
              });
              let obj1 = {
                //这边配合小组件需要的格式 创造的对象
                label: "气象站"
              };
              let Obj2 = {
                //这边配合小组件需要的格式 创造的对象
                data: meteorologicalStation
              };

              this.sensorScreenName = obj1;
              this.sensorScreenData = Obj2;
              // console.log(meteorologicalStation,"meteorologicalStation");
            });
          });
          let markerList2 = [marker1, marker, marker2];
          // this.map.add(markerList2)

          //土壤传感器
          let content41 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 tubj" ></div>';
          let marker41 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.72969, 34.2354), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "土壤传感器",
            content: content41, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers.push(marker41);
          let markerList41 = [marker41, marker];
          // this.map.add(markerList41)

          //空气传感器
          let content42 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 tubj" ></div>';
          let marker42 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.72965, 34.23529), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "空气传感器",
            content: content42, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers.push(marker42);
          let markerList42 = [marker42, marker];
          // this.map.add(markerList42)

          let content43 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 tubj" ></div>';
          let marker43 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7294, 34.23438), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "空气传感器",
            content: content43, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers.push(marker43);
          let markerList43 = [marker43, marker];
          // this.map.add(markerList43)
          marker41.on("click", function (e) {
            that.$router.push({ path: "/greenhouse", query: { id: 12 } });
          });
          marker42.on("click", function (e) {
            that.$router.push({ path: "/greenhouse", query: { id: 12 } });
          });
          marker43.on("click", function (e) {
            that.$router.push({ path: "/greenhouse", query: { id: 12 } });
          });

          //昌盛枪机0
          let content0 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11"></div>';
          let marker0 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7282, 34.2361), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "枪机0",
            content: content0, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker0);
          let markerList0 = [marker0, marker];
          this.map.add(markerList0);

          //在这里添加点位 8个枪机(蓝) 10球机(黄) 1土壤传感器(绿) 1空气传感器(黑)
          //昌盛枪机1
          let content21 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11"></div>';
          let marker11 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7279, 34.2364), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "枪机1",
            content: content21, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker11);
          let markerList11 = [marker11, marker];
          this.map.add(markerList11);

          //枪机2
          let content22 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11" ></div>';
          let marker12 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7271, 34.236), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "枪机2",
            content: content22, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker12);
          let markerList12 = [marker12, marker];
          this.map.add(markerList12);

          //枪机3
          let content23 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11" ></div>';
          let marker13 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7282, 34.2337), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "枪机3",
            content: content23, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker13);
          let markerList13 = [marker13, marker];
          this.map.add(markerList13);

          //枪机4
          let content24 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11" ></div>';
          let marker14 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7289, 34.2341), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "枪机4",
            content: content24, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker14);
          let markerList14 = [marker14, marker];
          this.map.add(markerList14);

          //枪机5
          let content25 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11" ></div>';
          let marker15 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7292, 34.2355), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "枪机5",
            content: content25, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker15);
          let markerList15 = [marker15, marker];
          this.map.add(markerList15);

          //枪机7
          let content27 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11" ></div>';
          let marker17 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7294, 34.2354), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "枪机7",
            content: content27, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker17);
          let markerList17 = [marker17, marker];
          this.map.add(markerList17);

          //枪机8
          let content28 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11" ></div>';
          let marker18 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7295, 34.2347), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "枪机8",
            content: content28, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker18);
          let markerList18 = [marker18, marker];
          this.map.add(markerList18);

          marker11.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(3);
          });
          marker0.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(2);
          });
          marker12.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(9);
          });
          marker13.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(6);
          });
          marker14.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(10);
          });
          marker15.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(7);
          });
          marker17.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(13);
          });
          marker18.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(11);
          });

          //球机1
          let content31 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let marker31 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7291, 34.2342), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机1",
            content: content31, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker31);
          let markerList31 = [marker31, marker];
          this.map.add(markerList31);

          //球机2
          let content32 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let marker32 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7283, 34.2359), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机2",
            content: content32, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker32);
          let markerList32 = [marker32, marker];
          this.map.add(markerList32);

          //球机3
          let content33 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let marker33 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.72845, 34.2358), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机3",
            content: content33, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker33);
          let markerList33 = [marker33, marker];
          this.map.add(markerList33);

          //球机4
          let content34 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let marker34 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7287, 34.2353), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机4",
            content: content34, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker34);
          let markerList34 = [marker34, marker];
          this.map.add(markerList34);

          //球机5
          let content35 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let marker35 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7297, 34.2356), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机5",
            content: content35, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker35);
          let markerList35 = [marker35, marker];
          this.map.add(markerList35);

          //球机6
          let content36 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let marker36 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7287, 34.2351), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机6",
            content: content36, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker36);
          let markerList36 = [marker36, marker];
          this.map.add(markerList36);

          //球机7
          let content37 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let marker37 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7298, 34.2355), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机7",
            content: content37, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker37);
          let markerList37 = [marker37, marker];
          this.map.add(markerList37);

          //球机8
          let content38 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let marker38 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.729, 34.2346), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机8",
            content: content38, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker38);
          let markerList38 = [marker38, marker];
          this.map.add(markerList38);

          //球机9
          let content39 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let marker39 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.73, 34.2349), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机9",
            content: content39, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker39);
          let markerList39 = [marker39, marker];
          this.map.add(markerList39);

          //球机10
          let content310 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let marker310 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7295, 34.2361), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机10",
            content: content310, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers3.push(marker310);
          let markerList310 = [marker310, marker];
          this.map.add(markerList310);
          marker31.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(5);
          });
          marker32.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(4);
          });
          marker33.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(30);
          });
          marker34.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(31);
          });
          marker35.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(28);
          });
          marker36.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(27);
          });
          marker37.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(26);
          });
          marker38.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(14);
          });
          marker39.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(12);
          });
          marker310.on("click", function (e) {
            // that.$router.push({ path: "/greenhouse", query: { id: 12 } });
            that.getVedioList2(29);
          });

          //头甲位置
          let content4 =
            '<div class="marker-route marker-marker-bus-from nby2" ></div>';
          let marker3 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.79612, 34.2799), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "头甲",
            content: content4, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 13 }
          });
          marker3.on("click", function (e) {
            console.log(e.extData, 2222);
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
          });
          this.markers.push(marker3);
          let markerList3 = [marker3, marker];
          // this.map.add(markerList3)

          let contentT3 =
            '<div class="marker-route marker-marker-bus-from nby3 " ><div class="bg"></div><div class="bg2"></div></div>';
          let markerT2 = new AMap.Marker({
            //气象站
            position: new AMap.LngLat(119.79652, 34.2806), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "气象站",
            content: contentT3, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers2.push(markerT2);
          //气象站点击事件
          markerT2.on("click", () => {
            this.visible = !this.visible;
            console.log(this.visible);
            let obj = {
              id: this.plotId,
              type: "devEnvEqv"
            };
            console.log("obj==", obj);
            // 调用接口
            screenDevice(obj).then((res) => {
              console.log("数据接口", res);
              let data = res.data.detail;
              let meteorologicalStation = [];
              //遍历数据
              data.forEach((item) => {
                attributeTable.map((item1) => {
                  if (item.code == item1.type) {
                    // item.property=item1
                    item.pr_name = item1.name;
                    item.pr_unit = item1.unit;
                    item.pr_value = item.value;
                    item.img = imgJudgment(item1.name);
                    meteorologicalStation.push(item);
                  }
                });
              });
              let obj1 = {
                //这边配合小组件需要的格式 创造的对象
                label: "气象站"
              };
              let Obj2 = {
                //这边配合小组件需要的格式 创造的对象
                data: meteorologicalStation
              };

              this.sensorScreenName = obj1;
              this.sensorScreenData = Obj2;
              // console.log(meteorologicalStation,"meteorologicalStation");
            });
          });
          let markerListT2 = [marker1, marker, markerT2];
          // this.map.add(markerListT2)

          //头甲枪机1
          let contentT1 =
            '<div class="marker-route marker-marker-bus-from nby1 nby1 tj" ></div>';
          let markerT1 = new AMap.Marker({
            position: new AMap.LngLat(119.79652, 34.28),
            title: "枪机1",
            content: contentT1, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 13 }
          });
          this.markers3.push(markerT1);
          let markerListT1 = [markerT1, marker];
          this.map.add(markerListT1);

          //头甲枪机2
          let contentT22 =
            '<div class="marker-route marker-marker-bus-from nby1 nby1 tj" ></div>';
          let markerT22 = new AMap.Marker({
            position: new AMap.LngLat(119.79702, 34.279),
            title: "枪机2",
            content: contentT22, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 13 }
          });
          this.markers3.push(markerT22);
          let markerListT22 = [markerT22, marker];
          this.map.add(markerListT22);

          //头甲球机1
          let contentT31 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let markerT31 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.79702, 34.28), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机1",
            content: contentT31, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 13 }
          });
          this.markers3.push(markerT31);
          let markerListT31 = [markerT31, marker];
          this.map.add(markerListT31);

          //头甲球机2
          let contentT32 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj" ></div>';
          let markerT32 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.79652, 34.279), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机2",
            content: contentT32, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 13 }
          });
          this.markers3.push(markerT32);
          let markerListT32 = [markerT32, marker];
          this.map.add(markerListT32);

          markerT1.on("click", function (e) {
            console.log(e.target.getExtData().id, "e.target.getExtData().id");
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(22);
          });

          markerT22.on("click", function (e) {
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(23);
          });
          markerT31.on("click", function (e) {
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(25);
          });

          markerT32.on("click", function (e) {
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(24);
          });

          //王集位置
          let content5 =
            '<div class="marker-route marker-marker-bus-from nby2" ></div>';
          let marker4 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.758, 34.2505), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "王集",
            content: content5, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 14 }
          });
          marker4.on("click", function (e) {
            console.log(e.extData, 2222);
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
          });
          let markerList4 = [marker4, marker];
          // this.map.add(markerList4)
          let contentW3 =
            '<div class="marker-route marker-marker-bus-from nby3 " ><div class="bg"></div><div class="bg2"></div></div>';
          let markerW2 = new AMap.Marker({
            //气象站
            position: new AMap.LngLat(119.7572, 34.2499), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "气象站",
            content: contentW3, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10)
          });
          this.markers2.push(markerW2);
          //气象站点击事件
          markerW2.on("click", () => {
            this.visible = !this.visible;
            console.log(this.visible);
            let obj = {
              id: this.plotId,
              type: "devEnvEqv"
            };
            console.log("obj==", obj);
            // 调用接口
            screenDevice(obj).then((res) => {
              console.log("数据接口", res);
              let data = res.data.detail;
              let meteorologicalStation = [];
              //遍历数据
              data.forEach((item) => {
                attributeTable.map((item1) => {
                  if (item.code == item1.type) {
                    // item.property=item1
                    item.pr_name = item1.name;
                    item.pr_unit = item1.unit;
                    item.pr_value = item.value;
                    item.img = imgJudgment(item1.name);
                    meteorologicalStation.push(item);
                  }
                });
              });
              let obj1 = {
                //这边配合小组件需要的格式 创造的对象
                label: "气象站"
              };
              let Obj2 = {
                //这边配合小组件需要的格式 创造的对象
                data: meteorologicalStation
              };

              this.sensorScreenName = obj1;
              this.sensorScreenData = Obj2;
              // console.log(meteorologicalStation,"meteorologicalStation");
            });
          });
          let markerListW2 = [marker1, marker, markerW2];
          // this.map.add(markerListW2)

          //王集枪机1
          let contentW1 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11" ></div>';
          let markerW1 = new AMap.Marker({
            position: new AMap.LngLat(119.75845, 34.2499),
            title: "枪机1",
            content: contentW1, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 14 }
          });
          this.markers3.push(markerW1);
          let markerListW1 = [markerW1, marker];
          this.map.add(markerListW1);

          //王集枪机2
          let contentW22 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11" ></div>';
          let markerW22 = new AMap.Marker({
            position: new AMap.LngLat(119.7579, 34.2505),
            title: "枪机2",
            content: contentW22, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 14 }
          });
          this.markers3.push(markerW22);
          let markerListW22 = [markerW22, marker];
          this.map.add(markerListW22);

          //王集球机1
          let contentW31 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj wj" ></div>';
          let markerW31 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.757, 34.2499), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机1",
            content: contentW31, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 14 }
          });
          this.markers3.push(markerW31);
          let markerListW31 = [markerW31, marker];
          this.map.add(markerListW31);

          //王集球机2
          let contentW32 =
            '<div class="marker-route marker-marker-bus-from nby1 nby11 qbj wj" ></div>';
          let markerW32 = new AMap.Marker({
            //响水九丰农博园2
            position: new AMap.LngLat(119.7575, 34.2491), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "球机2",
            content: contentW32, // 自定义点标记覆盖物内容
            offset: new AMap.Pixel(-10, -10),
            extData: { id: 14 }
          });
          this.markers3.push(markerW32);
          let markerListW32 = [markerW32, marker];
          this.map.add(markerListW32);

          markerW1.on("click", function (e) {
            console.log(e.target.getExtData().id, "e.target.getExtData().id");
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(20);
          });
          markerW22.on("click", function (e) {
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(21);
          });
          markerW31.on("click", function (e) {
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(18);
          });

          markerW32.on("click", function (e) {
            // that.$router.push({
            //   path: "/greenhouse",
            //   query: { id: e.target.getExtData().id }
            // });
            that.getVedioList2(19);
          });

          // //设置填充物 颜色 大小等
          // let polygonArr = [
          //   [119.7271, 34.23580],
          //   [119.728241, 34.233395],
          //   [119.729131, 34.233892],
          //   [119.729206, 34.233706],
          //   [119.730526, 34.234407],
          //   [119.729056, 34.237626],
          //   [119.727683, 34.236979],
          //   [119.727972, 34.236243],
          //   [119.7271, 34.23580],
          // ];
          // let polygon = new AMap.Polygon({
          //   map: this.map,
          //   path: polygonArr,//设置多边形边界路径
          //   strokeColor: "#00fd59", //线颜色
          //   strokeOpacity: 0.5, //线透明度
          //   strokeWeight: 2,    //线宽
          //   fillColor: "#32dd6e", //填充色
          //   fillOpacity: 0.5//填充透明度
          // });
          // this.map.add(polygon);

          // //设置填充物 颜色 大小等
          // let polygonArr1 = [
          //   [119.725505, 34.231098],
          //   [119.722329, 34.229014],
          //   [119.725912, 34.224347],
          //   [119.729431, 34.226698],
          //   [119.725505, 34.231098],
          // ];
          // var polygon1 = new AMap.Polygon({
          //   map: this.map,
          //   path: polygonArr1,//设置多边形边界路径
          //   strokeColor: "#00fd59", //线颜色
          //   strokeOpacity: 0.5, //线透明度
          //   strokeWeight: 2,    //线宽
          //   fillColor: "#32dd6e", //填充色
          //   fillOpacity: 0.5//填充透明度
          // });
          // this.map.add(polygon1);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
};
</script>

<style lang="less" scoped>
@media screen and (max-height: 845px) {
  .homePage {
    font-size: 12px !important;
    margin-top: -5px;
  }

  .left {
    // border: 5px solid rgb(213, 8, 245) !important;
    width: 330px !important;
    height: 530px !important;
    .leftSousuo {
      background-size: 100% 100% !important;
      .sousuoIcon {
        width: 30px !important;
        height: 30px !important;
      }
      .sousuoInput {
        width: 220px !important;
      }
    }
    .leftIntroduce {
      height: 410px !important;
      background-size: 100% 100% !important;
    }
  }
  .right {
    // border: 5px solid rgb(213, 8, 245) !important;
    width: 330px !important;
    height: 530px !important;
    .moduleListTop {
      margin-top: 20px !important;
      .icon {
        width: 35px !important;
        height: 35px !important;
      }
    }
    .moduleListBottom {
      .text {
        padding: 0 5px !important;
      }
    }
    .information {
      // border: 1px solid red;
      background-size: 100% 100% !important;
    }
  }
  .bottom {
    .optionsBox {
      width: 600px !important;
      height: 100px !important;
      .option {
        p {
          bottom: -22px !important;
        }
      }
      .option:nth-child(1) {
        left: 89px !important;
      }
      .option:nth-child(2) {
        left: 198px !important;
      }
      .option:nth-child(3) {
        left: 306px !important;
      }
      .option:nth-child(4) {
        left: 415px !important;
      }
    }
  }
  .top {
    font-size: 25px !important;
    p {
      margin-top: -10px !important;
    }
  }
  .middle {
    top: 12% !important;
    width: 38% !important;
    .amount {
      width: 110px !important;
      height: 110px !important;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}
.oneMapShell {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // border: 1px solid red;
  // background: url("../../public/images/dtbg.jpg") no-repeat;
  // background-size: 100% 100%;
  @keyframes minIcon {
    // from表示动画的开始位置
    0% {
      transform: translateY(-4px);
    }
    // to表示动画的结束位置
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(-4px);
    }
  }
  .middle {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    // right: 0;
    width: 40%;
    height: 120px;
    // border: 1px solid red;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    z-index: 9;
    .amount {
      width: 120px;
      height: 120px;
      // border: 1px solid red;
      border-radius: 50%;
      background: url("../../public/images/yuan1.png") no-repeat;
      background-size: 100%;
      user-select: none;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: 550;
        color: transparent;
        background: linear-gradient(to bottom, #ffe813, #ffc600);
        background-clip: text;
        -o-background-clip: text;
        -ms-background-clip: text;
        -moz-background-clip: text;
        -webkit-background-clip: text;
      }
      p:nth-child(2) {
        color: rgba(255, 255, 255, 0.731);
        line-height: 25px;
      }
    }
    .amountBlue {
      background: url("../../public/images/yuan2.png") no-repeat;
      background-size: 100%;
      box-sizing: border-box;
      p:nth-child(1) {
        color: transparent;
        background: linear-gradient(to bottom, #00ffc6, #2c98ff);
        background-clip: text;
        -o-background-clip: text;
        -ms-background-clip: text;
        -moz-background-clip: text;
        -webkit-background-clip: text;
      }
    }
  }

  .pop {
    position: absolute;
    top: 500px;
    left: 600px;
    width: 300px;
    height: 400px;
    background: rgba(16, 24, 64, 0.9);
    padding: 10px;
    display: flex;
    flex-direction: column;
    .sensingTitle {
      height: 25px;
      width: 100%;
      // border: 1px solid red;
      display: flex;
      align-items: center;
      .sensingText {
        height: 100%;
        // border: 1px solid blue;
        display: flex;
        .block1 {
          width: 1px;
          height: 100%;
          background: #1effff;
        }
        .text1 {
          height: 100%;
          color: #fff;
          font-size: 15px;
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
      }
      i {
        // border: 1px solid red;
        // position: absolute;
        // right: 0;
        color: rgba(255, 255, 255, 0.62);
        font-size: 22px;
        user-select: none;
        cursor: pointer;
      }
      i:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .sensingTitle {
      height: 25px;
      width: 100%;
      // border: 1px solid red;
      display: flex;
      align-items: center;
      .sensingText {
        height: 100%;
        // border: 1px solid blue;
        display: flex;
        .block1 {
          width: 1px;
          height: 100%;
          background: #1effff;
        }
        .text1 {
          height: 100%;
          color: #fff;
          font-size: 15px;
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
      }
      i {
        // border: 1px solid red;
        // position: absolute;
        // right: 0;
        color: rgba(255, 255, 255, 0.62);
        font-size: 22px;
        user-select: none;
        cursor: pointer;
      }
      i:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .line {
      height: 2.5px;
      width: 100%;
      background: url("../../public/images/line11.png") no-repeat;
      background-size: 100%;
      margin: 10px 0;
    }
    .sensingContent {
      flex: 1;
      width: 100%;
      // border: 1px solid red;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      overflow: auto;
      .list {
        width: 50%;
        // border: 1px solid red;
        height: 80px;
        display: flex;
        .listLeft {
          // border: 1px solid red;
          height: 100%;
          display: flex;
          align-items: center;
          img {
            // display:inline-block;
            width: 50px;
            height: 50px;
          }
        }
        .listRight {
          // border: 1px solid red;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            margin: 2px 0;
          }
          p:nth-child(1) {
            font-size: 20px;
            font-weight: 650;
            color: #1effff;
            span {
              font-size: 12px;
            }
          }
          p:nth-child(2) {
            color: rgb(146, 220, 255);
          }
        }
      }
    }
    .sensingContent::-webkit-scrollbar {
      display: none;
    }
  }

  .top {
    // border: 1px solid red;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: 11%;
    background: url("../../public/images/title3.png") no-repeat;
    background-size: 100%;
    color: #fff;
    font-size: 30px;
    font-weight: 550;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-top: -35px;
      text-shadow: 5px 5px 3px dark red;
    }
    .homePage {
      position: absolute;
      top: 10px;
      right: 2%;
      font-weight: 500;
      font-size: 15px;
      color: #5ecefa8c;
      user-select: none;
      cursor: pointer;
    }
    .homePage:hover {
      color: #5ecffa;
    }
    // .ceshi{
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
  }
  .bottom {
    // border: 1px solid red;
    z-index: 9;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    .bottomDecorate {
      // border: 1px solid rgb(38, 255, 0);
      height: 50px;
      width: 100%;
      position: fixed;
      bottom: 0;
      background: url("../../public/images/dibu1-2.png") no-repeat;
      background-size: 100% 100%;
      z-index: 2;
    }
    .optionsBox {
      width: 725px;
      height: 120px;
      z-index: 1;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      // border: 1px solid red;
      margin: auto;
      background: url("../../public/images/g5.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      .option {
        width: 92px;
        height: 99.5px;
        position: fixed;
        top: -56px;
        right: 100px;
        // border: 1px solid red;
        color: #6efcfa;
        user-select: none;
        cursor: pointer;
        p {
          position: absolute;
          bottom: -30px;
          left: 50%;
          transform: translateX(-50%);
          width: 100px;
          font-size: 14px;
          color: transparent;
          background: linear-gradient(to bottom, #70fffa, #32aaf9);
          background-clip: text;
          -o-background-clip: text;
          -ms-background-clip: text;
          -moz-background-clip: text;
          -webkit-background-clip: text;
        }
      }
      .option:nth-child(1) {
        left: 168px;
        background: url("../../public/images/yztIcon3-1.png") no-repeat;
        background-size: 100% 100%;
      }
      .option-select:nth-child(1) {
        background: url("../../public/images/yztIcon2-1.png") no-repeat;
        background-size: 100% 100%;
        p {
          color: #fbd334;
        }
      }
      .option:nth-child(2) {
        left: 315px;
        background: url("../../public/images/yztIcon3-2.png") no-repeat;
        background-size: 100% 100%;
      }
      .option-select:nth-child(2) {
        background: url("../../public/images/yztIcon2-2.png") no-repeat;
        background-size: 100% 100%;
        p {
          color: #fbd334;
        }
      }
      .option:nth-child(3) {
        left: 462px;
        background: url("../../public/images/yztIcon3-3.png") no-repeat;
        background-size: 100% 100%;
      }
      .option-select:nth-child(3) {
        background: url("../../public/images/yztIcon2-3.png") no-repeat;
        background-size: 100% 100%;
        p {
          color: #fbd334;
        }
      }
      .option:nth-child(4) {
        left: 511.5px;
        background: url("../../public/images/yztIcon3-4.png") no-repeat;
        background-size: 100% 100%;
      }
      .option:nth-child(4):hover {
        background: url("../../public/images/yztIcon2-4.png") no-repeat;
        background-size: 100% 100%;
        p {
          color: #fbd334;
        }
      }
    }
  }
  .left {
    // border: 1px solid red;
    z-index: 9;
    width: 400px;
    height: 730px;
    // border: 1px solid rgb(233, 173, 173);
    position: fixed;
    left: 20px;
    top: 10%;
    // transform:translateY(-50%);
    .leftSousuo {
      width: 100%;
      height: 30%; //gai
      // border: 1px solid red;
      background: url("../../public/images/g6.png") no-repeat;
      background-size: 100% 120%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4%;
      position: relative;
      .co {
        font-size: 18px;
        font-weight: 550;
        color: transparent;
        background: linear-gradient(to bottom, #70fffa, #32aaf9);
        background-clip: text;
        color: linear-gradient(to bottom, #70fffa, #32aaf9);
        position: relative;
        top: -28%;
      }
      .inputBox {
        position: absolute;
        top: 30%;
        width: 90%;
        max-height: 13vh;
        overflow: auto;
        // border: 1px solid rgb(0, 255, 246);
        border-radius: 5px;
        z-index: 99;
        // background: rgba(6, 25, 121, 0.7);
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        .inputList {
          color: #fff;
          width: 46%;
          height: 5vh;
          // border: 1px solid rgba(0, 255, 246, 0.2);
          border: 1px solid #32aaf9;
          // background:rgba(0, 255, 246, 0.5) ;
          border-radius: 4% 4% 4% 4%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          user-select: none;
          cursor: pointer;
          margin: 2%;
        }
        .select {
          background: linear-gradient(to bottom, #70fffa, #32aaf9);
        }
        .inputList:hover {
          // border: 1px solid rgba(50, 170, 249, 0.6);
          background: linear-gradient(
            to bottom,
            rgba(112, 255, 250, 0.6),
            rgba(50, 170, 249, 0.6)
          );
        }
      }
      .sousuoIcon {
        width: 45px;
        height: 45px;
        background: url("../../public/images/sousuo2.png") no-repeat;
        background-size: 100%;
        //  border: 1px solid red;
        margin-right: 10px;
      }
      .sousuoInput {
        height: 40px;
        width: 250px;
        // border: 1px solid red;
        position: relative;
        input {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
          color: #fff;
          border: 1px solid #09a1b8;
          border-radius: 10px;
          padding: 0 20px;
        }
        /*修改提示文字的颜色*/
        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: rgba(255, 255, 255, 0.602);
        }
        .sousuoText {
          position: absolute;
          right: 15px;
          color: #2df4f2;
          font-size: 15px;
          top: 50%;
          transform: translateY(-50%);
          user-select: none;
          cursor: pointer;
        }
      }
    }
    .leftIntroduce {
      width: 100%;
      height: 506px;
      // border: 1px solid rgb(30, 255, 0);
      background: url("../../public/images/jieshao.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 30px 20px;
      box-sizing: border-box;
      .introduceTitle {
        // border: 1px solid red;
        font-size: 22px;
        font-weight: 550;
        color: transparent;
        background: linear-gradient(to bottom, #70fffa, #32aaf9);
        background-clip: text;
        -o-background-clip: text;
        -ms-background-clip: text;
        -moz-background-clip: text;
        -webkit-background-clip: text;
        margin-bottom: 5%;
      }
      .introduceContent {
        height: 90%;
        overflow: auto;
        /deep/p {
          img {
            width: 92%;
            margin: auto;
          }
        }

        .slideshow {
          /deep/.el-carousel {
            .el-carousel__item {
              display: flex;
              align-items: center;
              img {
                width: 100%;
                height: 100%;
                // border: 1px solid red;
              }
            }
          }
        }
        .introduceDetails {
          color: rgba(255, 255, 255, 0.8);
          text-align: left;
          line-height: 22px;
          text-indent: 20px;
          p {
            width: 100%;
            margin: 10px 0;
            // text-align-last:justify;
            text-align: justify;
            text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
            // border: 1px solid red;
          }
        }
      }
    }
  }
  .right {
    // border: 1px solid red;
    z-index: 9;
    width: 400px;
    height: 730px;
    // border: 1px solid red;
    position: fixed;
    right: 20px;
    top: 10%;
    // 返回按钮
    .skipContainer {
      display: flex;
      .skipShell {
        position: absolute;
        right: 2px;
        top: -65px;
        width: 200px;
        height: 55px;
        // border: 1px solid red;
        background: url("../../public/images/skipBtn.png") no-repeat center
          right;
        background-size: auto 100%;
        user-select: none;
        cursor: pointer;
        .skipShellText {
          position: absolute;
          top: 50%;
          right: 10%;
          font-size: 15px;
          transform: translateY(-50%);
          color: #70fffa;
        }
      }
      .skipShell2 {
        right: 170px;
      }
      .skipShell:hover {
        opacity: 0.8;
      }
    }

    // 返回按钮
    .smallModule {
      // background-color: #00f8f4;
      height: 22%; //gai 20%
      // border: 1px solid red;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .moduleList {
        width: 30%;
        height: 100%;
        cursor: pointer;
        background: url("../../public/images/g4.png") no-repeat;
        background-size: 100% 100%;
        .moduleListTop {
          height: 50%;
          width: 100%;
          margin-top: 8%;
          padding: 0 11%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          // justify-content: center;
          .icon {
            width: 46px;
            height: 46px;
            // border: 1px solid red;
            // background: url("../../public/images/yztIcon1.png") no-repeat;
            // background-size: 100%;
            margin-right: 10%;
          }
          .data {
            width: auto;
            height: 50px;
            // border: 1px solid red;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            p:nth-child(1) {
              color: #fff;
            }
            p:nth-child(2) {
              // color: #fff;
              color: transparent;
              background: linear-gradient(to bottom, #70fffa, #32aaf9);
              background-clip: text;
              -o-background-clip: text;
              -ms-background-clip: text;
              -moz-background-clip: text;
              font-size: 25px;
              font-weight: 550;
            }
          }
        }

        .moduleListBottom {
          margin-top: 5%;
          height: 28%;
          width: 100%;
          // border: 1px solid red;
          display: flex;
          padding: 0 13%;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .onLine {
            //  border: 1px solid red;
            color: #fff;
            .dot {
              display: inline-block;
              width: 10px;
              height: 10px;
              background: #34d160;
              border-radius: 50%;
            }
            .variable {
              color: #34d160;
            }
            .text {
              padding: 0 10px;
            }
          }
          .offline {
            //  border: 1px solid red;
            color: #fff;
            .dot {
              display: inline-block;
              width: 10px;
              height: 10px;
              background: #eb2a2a;
              border-radius: 50%;
            }
            .text {
              padding: 0 13px;
            }
          }
        }
      }
      .moduleList:nth-child(1) {
        .icon {
          background: url("../../public/images/yztIcon1.png") no-repeat;
          background-size: 100%;
        }
      }
      .moduleList:nth-child(2) {
        .icon {
          background: url("../../public/images/yztIcon2.png") no-repeat;
          background-size: 100%;
        }
      }
      .moduleList:nth-child(3) {
        .icon {
          background: url("../../public/images/yztIcon3.png") no-repeat;
          background-size: 100%;
        }
      }
      .moduleList:nth-child(4) {
        .icon {
          background: url("../../public/images/yztIcon4.png") no-repeat;
          background-size: 100%;
        }
      }
    }
    .information {
      margin-top: 3%;

      height: 570px;
      // border: 1px solid red;
      background: url("../../public/images/jieshao1.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      box-sizing: border-box;
      .title {
        width: 100%;
        color: transparent;
        background: linear-gradient(to bottom, #70fffa, #2892e7);
        background-clip: text;
        -o-background-clip: text;
        -ms-background-clip: text;
        -moz-background-clip: text;
        font-size: 20px;
        font-weight: 550;
        // border: 1px solid red;
        margin-bottom: 5%;
      }
      .content {
        height: 80%;
        // border: 1px solid red;
        .table_th {
          width: 100%;
          height: 12%;
          // border: 1px solid red;
          box-sizing: border-box;
          color: #00f8f4;
          display: flex;
          background: #0e8cb62d;
          .th_style {
            width: 20%;
            // border: 1px solid red;
            box-sizing: border-box;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .th_style:nth-child(1) {
            width: 40%;
          }
        }
        .tr_content {
          // border: 1px solid blue;
          overflow: hidden;
          height: 90%;
          box-sizing: border-box;
          li {
            // border: 1px solid #fff;
            height: 40px;
            color: rgba(255, 255, 255, 0.739);
            display: flex;
            user-select: none;
            cursor: pointer;
            box-sizing: border-box;
            .tr_style {
              width: 20%;
              height: 100%;
              // border: 1px solid #fff;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .tr_style:nth-child(1) {
              width: 40%;
            }
          }
          li:hover {
            border: 1px solid #04d2d98d;
          }
          .evenNumber {
            background: #0e8cb62d;
          }
        }
      }
    }
  }
}
/deep/.nby2 {
  width: 50px;
  height: 88px;
  background: url("../../public/images/dtIcon3-2.png") no-repeat;
  background-size: 100%;
  // border: 1px solid red;
}
// /deep/.nby3{
//   width: 50px;
//   height:88px;
//   background: url("../../public/images/jiankonIcon1.png") no-repeat;
//   background-size: 100%;

// }
/deep/.bg {
  width: 50px;
  height: 88px;
  background: url("../../public/images/jiankonIcon1.png") no-repeat center;
  background-size: 70%;
  animation: minIcon 2s linear infinite;
  // border: 1px solid red;
  position: relative;
  bottom: -1px;
}

/deep/.bg2 {
  width: 50px;
  height: 60px;
  background: url("../../public/images/fanweiIcon1.png") no-repeat center;
  background-size: 70%;
  // border: 1px solid red;
  position: relative;
  top: -30px;
  left: -2px;
  // animation:minIcon 2s linear infinite ;
}

/deep/.nbyText2 {
  width: 50px;
  margin: auto;
  background: rgba(0, 0, 0, 0.6);
  color: #42cc7c;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 20px;
  //  animation:minIcon 2s linear infinite ;
}
/deep/.nbyText2 {
  width: 70px;
}

/deep/.nby1 {
  width: 50px;
  height: 88px;
  background: url("../../public/images/dtIcon3-4a.png") no-repeat;
  background-size: 100%;
  // border: 1px solid red;
  position: relative;
}
/deep/.qbj {
  background: url("../../public/images/qiujia.png") no-repeat;
}
/deep/.nby11 {
  background-size: 80%;
}
/deep/.tubj {
  background: url("../../public/images/dtIcon3-2a.png") no-repeat;
  background-size: 80%;
}
/deep/.nbyText1 {
  width: 100px;
  margin: auto;
  background: rgba(0, 0, 0, 0.6);
  color: #42cc7c;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 20px;
}
/deep/.nbyText11 {
  width: 50px;
  position: absolute;
  bottom: -2px;
  left: 50%;
}
/deep/.nbyText12 {
  width: 70px;
}
// 监控的弹出框
.popUpBox {
  background: rgba(0, 0, 0, 0.63);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    width: 1000px;
    height: 80%;
    background: #fff;
    position: relative;
    .cancel {
      position: absolute;
      top: -30px;
      right: -30px;
      width: 30px;
      height: 30px;
      // border: 1px solid red;
      font-size: 30px;
      color: rgba(255, 255, 255, 0.555);
      user-select: none;
      cursor: pointer;
    }
    .cancel:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.layoutTop {
  box-shadow: 0 0 7.5rem 6.125rem rgb(0 0 0 / 80%);
  z-index: 9;
  position: fixed;
  top: 0;
  width: 100%;
}
.layoutleft {
  position: fixed;
  left: 0;
  height: 100vh;
  box-shadow: 0 0 7.5rem 6.125rem rgb(0 0 0 / 80%);
  z-index: 9;
}
.layoutbottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0 7.5rem 6.125rem rgb(0 0 0 / 80%);
  z-index: 9;
}
.layoutright {
  position: fixed;
  right: 0;
  height: 100%;
  width: 0.0625rem;
  opacity: 0.8;
  background-color: #000;
  // box-shadow: -6.25rem 0 10rem 16.125rem #000;
  box-shadow: 0 0 7.5rem 6.125rem rgb(0 0 0 / 80%);
  z-index: 9;
}
.liveShell {
  width: 90vw;
  height: 90vh;
  // border: 5px solid #082455c8;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 9;
  .liveBox {
    width: 97vw;
    height: 96vh;
    position: fixed;
    top: 2vh;
    left: 1.5vw;
    background: url("../../public/images/popupImg.png") no-repeat;
    background-size: 100% 100%;
    .cancel {
      position: absolute;
      top: -15px;
      right: -15px;
      font-size: 30px;
      color: #fff;
      width: 30px;
      height: 30px;
      user-select: none;
      cursor: pointer;
    }
    #app {
      height: 95%;
      .video {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start !important;
        // width: 75%;
        width: 97%;
        height: 93%;
        margin: 0 auto;
        margin-top: 1.5%;
        video {
          object-fit: fill;
        }
        .activeItem {
          width: 100%;
          height: 100%;
        }
        .newVideo {
          width: 33.33%;
          height: 33.33%;
        }
        .newVideo1 {
          width: 33.33%;
          height: 50.33%;
        }
        .newVideo2 {
          width: 100%;
          height: 100%;
        }
      }

      .video2 {
        width: 97%;
        height: 100%;
      }
      .btn-groups {
        margin-top: 10px;
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        .selectBox {
          margin-right: 50px;
        }
      }
      .return {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
        text-align: center;
      }
    }
  }
  .liveBox2 {
    width: 60vw;
    height: 70vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.liveShell2 {
  width: 100vw;
  height: 100vh;
}
</style>
